<template>
  <div>
    <img v-if="school" class="contencion" :src="school.logo_url" alt="Escudo del colegio" />
  </div>
</template>
  <script>
import { mapState } from "vuex";

export default {
  name: "SchoolShield",
  data() {
    return {
      imgShield: require("@/assets/img/shield.jpeg"),
      school:null
    };
  },
  props: {
    srcImage: String,
  },
  mounted() {
    this.school = JSON.parse(localStorage.getItem("school"));
  },
};
</script>
  <style>
.contencion {
  max-width: 200px; /* Ancho máximo de 300 píxeles */
  max-height: 100px; /* Altura máxima de 200 píxeles */
  width: auto; /* Ancho automático para mantener la proporción */
  height: auto; /* Altura automática para mantener la proporción */
  border-radius: 10%;
}
</style>
  