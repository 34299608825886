<template>
    <div class="container-fluid py-4">
    
        <div class="row">
    
            <div class="position-sticky fixed-top z-index-sticky">
    
                <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">{{ textAlert }}</vsud-alert>
    
            </div>
    
            <div>
    
                <MessageCreateForm @saveMessage="saveMessage" :types-message="typesMessage" :groups="groups" :users-send="users"></MessageCreateForm>
    
            </div>
    
        </div>
    
    </div>
</template>

<script>
import MessageCreateForm from "./components/MessageCreateForm.vue";
import axios from "axios";
import VsudAlert from "@/components/VsudAlert.vue";
import router from "@/router/index.js";

export default {
    name: "NewMessage",
    components: {
        MessageCreateForm,
        VsudAlert,
    },
    data() {
        return {
            typesMessage: [],
            groups: [],
            users: [],
            alertShow: false,
            textAlert: null,
            colorAlert: null,
        };
    },
    async created() {
        await this.getTypesMessage();
    },
    methods: {        
        async getTypesMessage() {            
            this.$store.commit('setLoading',true);
            try {
                const { status, data } = await axios.get(`${process.env.VUE_APP_API_BOOKAPP}type-messages`, {
                    headers: {
                        Authorization: localStorage.getItem("token_bookapp"),
                        conexion: localStorage.getItem("conexion"),
                    },
                }
                );

                if (status == 200) {
                    this.typesMessage = data;
                } else {
                    console.log("Hubo un error coonsultando los tipos de mensaje");
                }
            } catch (error) {
                console.log("tipo mensajes: ",error);
            }finally{
                this.$store.commit('setLoading',false);
            }



        },
        async saveMessage(request) {
            this.$store.commit('setLoading',true);
            axios
                .post(`${process.env.VUE_APP_API_BOOKAPP}messages/save`, request, {
                    headers: {
                        Authorization: localStorage.getItem("token_bookapp"),
                        conexion: localStorage.getItem("conexion"),
                    },
                })
                .then((response) => {
                    this.$store.commit('setLoading',false);
                    const messageNew = response.data;
                    if (request.files.length > 0) {
                        this.saveFiles(request.files, messageNew);
                    }
                    this.alertShow = true;
                    this.textAlert = "El mensaje se creo correctamente.";
                    this.colorAlert = "success";
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        router.push({
                            path: "/mensajes/listar/enviados",
                            replace: true,
                        });
                        this.alertShow = false;
                        this.textAlert = null;
                        this.colorAlert = null;
                    }, 3000);
                    request = {
                        type_message_id: null,
                        school_id: 1,
                        sender_id: null,
                        status: "active",
                        receiver: [],
                        message: null,
                        message_text: null,
                        privacity: "2",
                        subject: null,
                        stundent_id: null,
                    };
                })
                .catch((e) => {
                    this.$store.commit('setLoading',false);
                    if (e.response.status == 422) {
                        let errors = e.response.data.errors;
                        errors = Object.values(errors);
                        errors.forEach((error) => {
                            error.forEach((e) => {
                                this.errors.push(e);
                            });
                        });
                    } else {
                        this.alertShow = true;
                        this.textAlert =
                            "Hubo un error al crear el mesaje, intente nuevamente.";
                        this.colorAlert = "danger";
                        setTimeout(() => {
                            this.alertShow = false;
                            this.textAlert = null;
                            this.colorAlert = null;
                        }, 3000);
                    }
                });
        },
        async getGruops() {
            const { status, data } = await axios.get(
                `${process.env.VUE_APP_API_BOOKAPP}groups`, {
                    headers: {
                        Authorization: localStorage.getItem("token_bookapp"),
                        conexion: localStorage.getItem("conexion"),
                    },
                }
            );

            if (status == 200) {
                this.groups = data;
                // this.groups.shift();
            } else {
                console.log("Hubo un error coonsultando los tipos de mensaje");
            }
        },
        async getUsers(searchValue) {
            if (searchValue == null || !searchValue) {
                return 0;
            }
            const { status, data } = await axios.get(
                `${process.env.VUE_APP_API_BOOKAPP}users/filter-student/${searchValue}`, {
                    headers: {
                        Authorization: localStorage.getItem("token_bookapp"),
                        conexion: localStorage.getItem("conexion"),
                    },
                }
            );

            if (status == 200) {
                this.users = data;
                this.users.map((result) => {
                    let attendants_name = "";
                    result.kinship_student.forEach((att) => {
                        attendants_name += att.attendant.name + " " + att.attendant.lastname + "- ";
                    });

                    result.fullName = `${result.inscription.course.name} / ${result.name} ${result.lastname} / ${attendants_name} `;
                });
            } else {
                console.log("error", data);
            }
        },
        async saveFiles(files, messageNew) {
            const formDataSend = new FormData();
            for (let i = 0; i < files.length; i++) {
                formDataSend.append("files[]", files[i]);
            }

            formDataSend.append("message", JSON.stringify(messageNew));
            const { status, data } = await axios.post(
                `${process.env.VUE_APP_API_BOOKAPP}attachment`,
                formDataSend, {
                    headers: {
                        Authorization: localStorage.getItem("token_bookapp"),
                        conexion: localStorage.getItem("conexion"),
                    },
                }
            );

            if (status == 200) {
                console.log("Archivos del mensaje creado correctamente");
            } else {
                console.log("error", data);
            }
        },
    }
};
</script>
