<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Cantidad maxima de
              estudiantes
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >Opciones</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="courses">
            <tr style="cursor: pointer" v-for="course in courses" :key="course.id">
              <td @click="viewCourse(course.id)">
                <div class="d-flex px-2 py-1">
                  <vsud-avatar
                    :img="imgDefault"
                    class="me-3"
                    alt="avatar image"
                    border-radius="lg"
                  />
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ course.name }}</h6>
                  </div>
                </div>
              </td>
              <td @click="viewCourse(course.id)" class="text-sm">
                <p class="text-secondary mb-0 text-sm">{{ course.quota }}</p>
              </td>
              <td class="text-sm">
                <div class="text-secondary mb-0 text-sm d-flex">
                  <div
                    style="cursor: pointer"
                    variant="gradient"
                    class="cursos mx-0 d-m-inline-block align-items"
                    color="info"
                    @click="viewSchedule(course.id)"
                  >
                    <font-awesome-icon
                      class="text-center bg-white shadow cursos icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center me-2"
                      style="color: #f06C30;"
                      title="horario"
                      :icon="['fas', 'chalkboard-user']"
                    />

                    <a class="cursos"></a>
                  </div>
                  <div
                    style="cursor: pointer"
                    variant="gradient"
                    class="cursos mx-2 d-m-inline-block align-items"
                    @click="viewCalendar(course.id)"
                  >
                    <font-awesome-icon
                      class="text-center bg-white shadow cursos icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center me-2"
                      style="color: #f06C30;"
                      title="calendario"
                      :icon="['fass', 'calendar-days']"
                    />
                    <a class="cursos"></a>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/courses.png";
import VsudButton from "@/components/VsudButton.vue";

import router from "@/router/index.js";

export default {
  name: "Table",
  components: {
    VsudAvatar,
    VsudButton,
  },
  props: {
    courses: { type: Object, required: true },
  },
  methods: {
    viewCourse(id) {
      router.push({
        path: `/cursos/ver/${id}`,
        replace: true,
      });
    },
    viewSchedule(id) {
      console.log(id);
      router.push({
        path: `/cursos/horario/${id}`,
        replace: true,
      });
    },
    viewCalendar(id) {
      router.push({
        path: `/cursos/calendario/${id}`,
        replace: true,
      });
    },
  },
  data() {
    return {
      imgDefault,
    };
  },
  mounted() {
    // alert(router.params.id);
  },
};
</script>
