<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
                {{ textAlert
                }}
              </vsud-alert>
            </div>
            <div class="col-12 col-lg-8 mx-auto my-5">
              <!-- <h3>Editar rol.</h3> -->
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <edit-role-info @sendForm="editRole" :role="role" :permissions="permissions"></edit-role-info>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditRoleInfo from "./components/EditRoleInfo.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";

export default {
  name: "EditUser",
  components: {
    EditRoleInfo,
    VsudAlert,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      typeDocument: null,
      genders: null,
      bloodTypes: null,
      newUser: null,
      permissions: [],
      errors: [],
      role: {},
    };
  },
  methods: {
    async getRole(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}roles/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.role = data;
      }
    },
    async getPermissions() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}permissions/null`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.permissions = data;
        this.permissions.map((permission) => {
          permission.fullName =
            permission.name + " - " + permission.description;
        });
      } else {
        console.log("error", data);
      }
    },
    async editRole(role) {
      const { name, guard_name, id, newPermissions, ...resto } = role;
      const newRole = {
        name,
        guard_name: "api",
        id,
        permissions: newPermissions,
      };
      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}roles/${newRole.id}`,
        newRole,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        // this.assingPermissions(newRole, newPermissions);
        this.alertShow = true;
        this.textAlert = "El rol se edito correctamente.";
        this.colorAlert = "success";
        window.scrollTo(0, 0);
        this.removeAlert();
      } else {
        console.log("error", data);
      }
    },
    async assingPermissions(role, permissions) {
      const sendData = {
        permissions,
        role: role.id,
      };
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}roles/assign-permissions/`,
        sendData,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.assingPermissions(newRole, newPermissions);
        this.alertShow = true;
        this.textAlert = "Al rol se le asignaron correctamente los permisos.";
        this.colorAlert = "success";
        window.scrollTo(0, 0);
        this.removeAlert();
      } else {
        console.log("error", data);
      }
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  mounted() {
    this.getRole(this.$route.params.id);
    this.getPermissions();
  },
};
</script>
