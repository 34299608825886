<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert
                v-show="alertShow"
                class="mt-1 mx-6 text-center"
                :color="colorAlert"
              >{{ textAlert }}</vsud-alert>
            </div>
          </div>
          <div class="row">
            <div class="col-12 m-auto">
              <form v-on:submit="sendForm($event)" class="multisteps-form__form mb-5">
                <edit-attendat-info
                  @sendForm="saveUser"
                  :typeDocument="typeDocument"
                  :genders="genders"
                  :roles="roles"
                  :multiRole="multipleRole"
                  :errors="errors"
                  :user="user"
                  :phone="phone"
                  :isFinalTab="true"
                  :class="activeStep === 0 ? activeClass : ''"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Address from "./components/Address.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";
import EditAttendatInfo from "./components/EditAttendatInfo.vue";

export default {
  name: "EditUserAttendant",
  components: {
    Address,
    VsudAlert,
    EditAttendatInfo,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      typeDocument: null,
      genders: null,
      bloodTypes: null,
      newUser: null,
      roles: null,
      eps: null,
      errors: [],
      user: {},
      multipleRole: false,
    };
  },
  methods: {
    async saveUser(user) {
      this.errors = [];
      axios
        .put(`${process.env.VUE_APP_API_BOOKAPP}users/${user.id}`, user, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          this.newUser = user;
          if (user.image != null) {
            this.saveUserImage(user.image);
          }
          this.alertShow = true;
          this.textAlert = "El usuario se edito correctamente.";
          this.colorAlert = "success";
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.alertShow = false;
            this.textAlert = null;
            this.colorAlert = null;
          }, 3000);
        })
        .catch((e) => {
          if (e.response.status == 422) {
            let errors = e.response.data.errors;
            errors = Object.values(errors);
            errors.forEach((error) => {
              error.forEach((e) => {
                this.errors.push(e);
              });
            });
          } else {
            this.alertShow = true;
            this.textAlert =
              "Hubo un error al crear el usuario, intente nuevamente.";
            this.colorAlert = "danger";
            setTimeout(() => {
              this.alertShow = false;
              this.textAlert = null;
              this.colorAlert = null;
            }, 3000);
          }
        });
    },
    async saveUserImage(image) {
      let data = new FormData();
      data.append("image", image);
      data.append("_method", "PUT");
      axios
        .post(
          `${process.env.VUE_APP_API_BOOKAPP}users/save-image/${this.newUser.id}`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("token_bookapp"),
              conexion: localStorage.getItem("conexion"),
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {})
        .catch();
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    async getTypeDocument() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}type-documents`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.typeDocument = data;
      } else {
        console.log("error", data);
      }
    },
    async getRoles() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}roles`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.roles = data.filter((role) => {
          return role.id != 3;
        });
        // this.roles = data;
        console.log("desde el get", this.roles);
      } else {
        console.log("error", data);
      }
    },
    async getGenders() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}genders`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.genders = data;
      } else {
        console.log("error", data);
      }
    },
    async getBloodTypes() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}blood-types`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.bloodTypes = data;
      } else {
        console.log("error", data);
      }
    },
    async getEps() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}eps`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.eps = data;
      } else {
        console.log("error", data);
      }
    },
    async getUser(id_usuario) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/${id_usuario}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.user = data.user;

        if (this.user.roles[0].id != 3) {
          this.multipleRole = true;
        }
      } else {
        console.log("error", data);
      }
    },
  },
  mounted() {
    this.getUser(this.$route.params.id_usuario);
    this.getTypeDocument();
    this.getBloodTypes();
    this.getGenders();
    this.getRoles();
    this.getEps();
  },
};
</script>
