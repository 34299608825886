<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Inscripción al curso</h5>
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col">
          <label>Curso*</label>
          <multiselect
            v-if="courses"
            track-by="name"
            label="name"
            placeholder="Seleccione el curso"
            v-model="inscription.course_id"
            :options="courses"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :hide-selected="true"
            :showNoOptions="false"
          ></multiselect>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Jornada*</label>
          <multiselect
            placeholder="Seleccione la jornada"
            v-model="inscription.study_day"
            :options="statusDays"
            :hide-selected="true"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :showNoOptions="false"
          ></multiselect>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="sendForm('B')"
          >Anterior</vsud-button
        >
        <vsud-button
          v-if="!isFinalTab"
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="sendForm('N')"
          >Siguiente</vsud-button
        >
        <vsud-button
          v-if="isFinalTab"
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          @click="sendForm('F')"
          >Guardar</vsud-button
        >
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "Inscription",
  components: {
    VsudInput,
    VsudButton,
    Multiselect,
  },
  data() {
    return {
      arrayErrors: [],
      inscription: {
        course_id: [],
        study_day: null,
      },
      statusDays: ["mañana", "tarde"],
    };
  },
  props: {
    isFinalTab: {
      type: Boolean,
      default: false,
    },
    courses: {
      type: Object,
      required: true,
    },
  },
  methods: {
    validateForm() {
      this.arrayErrors = [];
      if (this.inscription.course_id.length == 0) {
        this.arrayErrors.push("Debe seleccionar un curso.");
      }
      if (this.inscription.study_day == null) {
        this.arrayErrors.push("Debe seleccionar una jornada para el curso.");
      }
    },
    sendForm(flag) {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      if (flag == "F") {
        this.$emit("saveDataInscription", this.inscription);
      }
      if (this.isFinalTab && flag == "B") {
        this.$parent.prevStep();
      }

      if (flag == "N") {
        this.$parent.nextStep();
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
