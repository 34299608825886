<template>
  <div class="mt-4 mb-3 card mt-lg-0">
    <div class="pb-0 card-body">
      <div class="mb-3 row align-items-center">
        <div class="col-9">
          <h5 class="mb-1 text-gradient text-success">
            <a href="javascript:;">{{ title }}</a>
          </h5>
        </div>
      </div>
      <ul class="mx-auto list-unstyled">
        <li v-for="a in sortedSupervisions" :key="a.id" class="d-flex mb-3">
          <div class="d-flex px-2 py-1">
            <vsud-avatar :img="getPhoto(a.supervisor?a.supervisor:a.supervised)"  class="me-2"
                      alt="avatar image"
                      border-radius="lg" />
          </div>
          <p
            style="cursor: pointer;color:#1155cc"
            @click="viewProfile(a.supervisor?a.supervisor.id:a.supervised.id)"
            class="mb-0"
          >{{ getFullName(a) }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
import imgDefault from "../../../../assets/img/user-default.png";
import VsudAvatar from "@/components/VsudAvatar.vue";
export default {
  name: "SupervisionsCard",
  components: { VsudAvatar },
  props: {
    title: { type: String, default: "" },
    type: { type: String },
    supervisions: { type: Object, require: true },
  },
  data() {
    return {
      showMenu: false,
      imgDefault
    };
  },
  computed: {
    sortedSupervisions() {
      // Utiliza Array.sort() para ordenar las asignaturas alfabéticamente
      return this.supervisions.slice().sort((a, b) => {
        const nameA = this.getFullName(a).toUpperCase(); // Utilizar la función getFullName
      const nameB = this.getFullName(b).toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0; // Son iguales
      });
    }
  },
  methods: {
    viewProfile(id) {
      router.push({
        path: `/ver/usuario/${id}`,
      });
    },
    getPhoto(user){
      if(user.photo){
        return user.photo
      }
      else{
        return imgDefault
      }
    },
    getFullName(user) {
    return user.supervisor
      ? `${user.supervisor.name} ${user.supervisor.lastname}`
      : `${user.supervised.name} ${user.supervised.lastname}`;
  },
  },
};
</script>
