<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">Codigo</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center"
            >Nombre</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center"
            >Curso</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center"
            >Tarifa</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center"
            >Acciones</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="specialsRates">
            <tr style="cursor: pointer" v-for="sr in specialsRates" :key="sr.id">
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm text-center">{{ sr.user.code }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm text-center">{{sr.user.name +" "+sr.user.lastname }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm text-center">{{ sr.course.name }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm text-center">{{ sr.rate }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm text-center">
                  <a @click="openCloseModel(sr)" v-show="hasPermission('school.special_restaurant_rate.update')"  class="btn btn-primary mt-2 mr-2">Editar</a> &nbsp;
                  <a
                  v-show="hasPermission('school.special_restaurant_rate.delete')" 
                    @click="changeStatusSpecialRate(sr)"
                    class="btn btn-danger ml-2 mt-2"
                  >Eliminar</a>
                </p>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <EditSpecialRateModal
      :specialRate="specialRateEdit"
      @closeModal="openCloseModel"
      @updateSpecialRate="updateSpecialRate"
      :visible="modalVisible"
    ></EditSpecialRateModal>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudButton from "@/components/VsudButton.vue";
import axios from "axios";
import EditSpecialRateModal from "./EditSpecialRateModal.vue";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "Table",
  components: {
    VsudAvatar,
    VsudButton,
    EditSpecialRateModal,
  },
  mixins: [permissionsMixin],

  methods: {
    ...mapMutations(["displayAlertMessage", "closeAlertMessage"]),
    async changeStatusSpecialRate(specialRate) {
      let dataSend = {
        status: specialRate.status == "active" ? "inactive" : "active",
      };

      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}special-rates/${specialRate.id}`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.$emit("getSpecialsRates");
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "La tarifa especial fue cambiada de estado con exito.",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Error al cambiar el estado de la tarifa especial.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
    },
    async updateSpecialRate(specialRate) {     
      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}special-rates/${specialRate.id}`,
        specialRate,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.modalVisible = false;
        this.$emit("getSpecialsRates");
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "La tarifa especial fue actualizada de estado con exito.",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Error al actualizar la tarifa especial.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
    },    
    openCloseModel(sr) {
      this.modalVisible = !this.modalVisible;
      this.specialRateEdit = sr;
    },
  },
  data() {
    return {
      modalVisible: false,
      specialRateEdit: null,
    };
  },
  computed: {
    ...mapState(["specialsRates"]),
  },
};
</script>
