<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="col-sm-4">
            <input type="text" class="mr-4 form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Buscar por nombre, correo o documento" v-model="valueSearch"
              @keyup.enter="getUserAdministratives" />
          </div>
          <div class="col-sm-3">
            <button type="button" class="ml-3 btn mb-2 bg-gradient-dark btn-md null null ms-auto"
              @click="getUserAdministratives()">Buscar</button>
          </div>
          <div class="col-sm-4 ms-auto mx-5">
            <div class="row justify-content-end">
              <div class="col-sm-5">
                <div class="row">
                  <button @click="active = !active" class="btn btn-primary">
                    {{ active ? 'Ver Inactivos' : 'Ver Activos' }}
                  </button>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="row">
                  <div v-show="hasPermission('users.administratives.store')">
                    <router-link to="/usuarios/administrativos/nuevo">
                      <button type="button" class="btn bg-gradient-success text-end ms-auto">Crear</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <Table @getData="getUserAdministratives" :userList="administrativesList" :pagination="pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import ComplexStatisticsCard from "@/examples/Cards/ComplexStatisticsCard.vue";
import Table from "./components/Table.vue";
import permissionsMixin from "@/mixins/permissionsMixin";

import axios from "axios";

export default {
  name: "AdministrativeList",
  components: {
    ComplexStatisticsCard,
    Table,
    VsudButton,
  },
  mixins: [permissionsMixin],
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    miniIcon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      administrativesList: [],

      pagination: null,
      valueSearch: null,
      title: "Vite Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      userRoles: [],
      userPermissions: [],
    };
  },
  watch: {
    valueSearch(newVal) {
      if (newVal == "") {
        this.valueSearch = null;
        this.getUserAdministratives();
      }
    },
  },
  methods: {
    async getUserAdministratives(page = 1) {
      const request = {
        rolesIn: [],
        rolesNotIn: [1, 2, 3, 4],
        active: this.active,

      };
      const { status, data: dataA } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/user-filtered-paginate/${this.valueSearch}?page=${page}`,
        request,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        const { data, ...resto } = dataA;
        this.administrativesList = dataA.data;
        this.pagination = resto;
      }
    },
    async changePage(page) {
      this.getUserAdministratives(page);
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  mounted() {
    this.getUserAdministratives();
  },
};
</script>
