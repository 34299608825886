<template>
  <div class="card p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <!-- <h5 class="font-weight-bolder mb-0">Editar rol</h5> -->
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div>
      <div class="row mt-3">
        <div class="col-12 col-sm-12">
          <label>Nombre</label>
          <input v-model="role.name" class="form-control" type="text" />
        </div>
        <div class="col-12 col-sm-12">
          <label>Permisos*</label>
          <multiselect
            v-if="permissions"
            v-model="permissionsAsing"
            track-by="fullName"
            :close-on-select="false"
            label="fullName"
            :hide-selected="true"
            placeholder="Seleccione un permiso"
            :multiple="true"
            :options="permissions"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :showNoOptions="false"
            :showNoResults="false"
          ></multiselect>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="sendForm()"
        >Guardar</vsud-button>
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  name: "EditRoleInfo",
  components: {
    VsudButton,
    Multiselect,
  },
  props: {
    role: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
  },
  watch: {
    permissions(newValue, oldValue) {
      if (this.role.permissions) {
        this.role.permissions.forEach((p) => {
          let result = this.permissions.find((per) => p.id == per.id);
          this.permissionsAsing.push(result);
        });
      }
    },
  },
  data() {
    return {
      arrayErrors: [],
      permissionsAsing: [],
    };
  },
  methods: {
    validateForm() {
      this.arrayErrors = [];
      if (!this.role.name) {
        this.arrayErrors.push("El rol debe tener un nombre.");
      }
      if (!this.role.guard_name) {
        this.arrayErrors.push("El rol debe tener un guard.");
      }
      if (this.permissionsAsing.length == 0) {
        this.arrayErrors.push("El rol debe tener asignado permisos.");
      }
    },
    sendForm() {
      this.validateForm();
      if (this.arrayErrors > 0) {
        return 0;
      }
      this.role.newPermissions = [];
      this.permissionsAsing.forEach((p) => {
        this.role.newPermissions.push(p.id);
      });
      this.$emit("sendForm", this.role);
    },
  },
};
</script>
