<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert
                v-show="alertShow"
                class="mt-1 mx-6 text-center"
                :color="colorAlert"
                >{{ textAlert }}</vsud-alert
              >
            </div>
            <!-- <div class="col-12 col-lg-8 mx-auto my-5">
              <h3>Formulario de registro un permiso.</h3>
            </div> -->
          </div>
          <!--form panels-->
          <div class="text-end">
                <div class="Listar Permisos">
                  <router-link to="/roles-permisos/permisos"><button type="button" class="btn bg-gradient-dark">Listado</button></router-link>
            </div>
            <router-view/>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form
                v-on:submit="sendForm($event)"
                class="multisteps-form__form mb-5"
              >
                <form-permission
                  @sendForm="savePermission"
                  :errors="errors"
                  :class="activeStep === 0 ? activeClass : ''"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormPermission from "./components/FormPermission.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";


export default {
  name: "NewUser",
  components: {
    FormPermission,
    VsudAlert,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      typeDocument: null,
      errors: [],
    };
  },
  methods: {
    async savePermission(permission) {
      this.errors = [];
      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}permissions`, permission, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          permission.name = null;
          permission.module = null;
          permission.description = null;
          this.alertShow = true;
          this.textAlert = "El permiso se creo correctamente.";
          this.colorAlert = "success";
          window.scrollTo(0, 0);
          this.removeAlert();
        })
        .catch((e) => {
          if (e.response.status == 422) {
            let errors = e.response.data.errors;
            errors = Object.values(errors);
            errors.forEach((error) => {
              error.forEach((e) => {
                this.errors.push(e);
              });
            });
          } else {
            this.alertShow = true;
            this.textAlert =
              "Hubo un error al crear el permiso, intente nuevamente.";
            this.colorAlert = "danger";
            this.removeAlert();
          }
        });
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
};
</script>
