<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="col-sm-5">
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Buscar por nombre de permiso, modulo o descripción"
              v-model="valueSearch"
              @keyup.enter="getPermissions"
            />
          </div>
          <div class="col-sm-3">
            <button
              type="button"
              class="btn mb-2 bg-gradient-dark btn-md null null ms-auto"
              @click="getPermissions()"
            >Buscar</button>
          </div>
          <div class="col-4 text-end ms-auto">
            <router-link to="/roles-permisos/permisos/crear">
              <button type="button" class="btn bg-gradient-success">Crear</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <Table :permissions="permissions" />
      </div>
    </div>
  </div>
</template>

<script>
import ComplexStatisticsCard from "@/examples/Cards/ComplexStatisticsCard.vue";
import ReviewCard from "./components/ReviewCard.vue";
import Table from "./components/TablePermissions.vue";

import axios from "axios";

export default {
  name: "AttendantsList",
  components: {
    ComplexStatisticsCard,
    ReviewCard,
    Table,
  },
  data() {
    return {
      permissions: [],
      valueSearch: null,
    };
  },
  watch: {
    valueSearch(newVal) {
      if (newVal == "") {
        this.valueSearch = null;
        this.getPermissions();
      }
    },
  },
  methods: {
    async getPermissions() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}permissions/${this.valueSearch}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.permissions = data;
      }
    },
  },
  mounted() {
    this.getPermissions();
  },
};
</script>
