<template>
  <div class="container-fluid py-4">
    <div class="d-flex flex-row">
      <div class="col-sm-5">
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Buscar por mensaje, fecha, estudiante"
          v-model="valueSearch"
          @keyup.enter="getCallAttentions"
        />
      </div>
      <div class="col-sm-3">
        <button
          type="button"
          class="btn bg-gradient-dark null null"
          @click="getCallAttentions()"
          style="margin-left: 10px"
        >Buscar</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <!-- <h1>LLamados de atencion</h1> -->
        <Table :call-attentions="callAttentions" type-list="SEND"></Table>
      </div>
    </div>
  </div>
</template>
  
<script>
import Table from "./components/Table.vue";
import axios from "axios";
export default {
  name: "CallAttention",
  components: {
    // CalendarForm,
    // VsudAlert,
    Table,
  },
  data() {
    return {
      showMenu: false,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      permissions: null,
      errors: [],
      empty: false,
      subjects: [],
      callAttentions: [],
      valueSearch:null
    };
  },
   watch: {
    valueSearch(newVal) {
      if (newVal == "") {
        this.valueSearch = null;
        this.getCallAttentions();
      }
    },
  },
  methods: {
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
    async getCallAttentions() {
      const user = JSON.parse(localStorage.getItem("user"));
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}calls-attentions/sender/${user.id}/${this.valueSearch}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.callAttentions = data;
        this.callAttentions.map((cA) => {
          const div = document.createElement("div");
          div.innerHTML = cA.message;
          cA.message_no_html = div.textContent || div.innerText || "";
          cA.message = cA.message_no_html.slice(0, 50);
          if (cA.status == "published") {
            cA.status_message = "Publicado";
          }
          if (cA.status == "pending") {
            cA.status_message = "Pendiente";
          }
          if (cA.status == "rejected") {
            cA.status_message = "Rechazado";
          }
        });
        console.log(data);
      } else {
        console.log("Hubo un error coonsultando los tipos de mensaje");
      }
    },
  },
  mounted() {
    this.getCallAttentions();
  },
};
</script>
  