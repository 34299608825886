<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="
                                  text-uppercase text-secondary text-xxs
                                  font-weight-bolder
                                  opacity-7
                                " >
              Nombre
            </th>
            <th class="
                                  text-uppercase text-secondary text-xxs
                                  font-weight-bolder
                                  opacity-7
                                  ps-2
                                ">
              Estado
            </th>
            <th class="
                                  text-uppercase text-secondary text-xxs
                                  font-weight-bolder
                                  opacity-7
                                  ps-2
                                ">
              Tipo de asignatura
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="subjects">
            <tr style="cursor: pointer" v-for="subject in sortedSubjects" :key="subject.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <vsud-avatar :img="[subject.image ? subject.image : imgDefault]" class="me-3" alt="avatar image"
                    border-radius="lg" />
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" @click="viewSubject(subject.id)" v-show="hasPermission('users.subjects.show')" >
                      {{ subject.name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td class=" text-sm">
                <p class="  text-secondary mb-0 text-sm">
                  {{ subject.status == 'active' ? 'activo' : 'inactivo' }}
                </p>
              </td>
              <td class=" text-sm">
                <p class="text-secondary mb-0 text-sm">
                  {{ subject.subject_type == 'regular_class' ? 'clase regular' : 'extra clase' }}
                </p>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/book.png";
import permissionsMixin from "@/mixins/permissionsMixin";
import router from "@/router/index.js";

export default {
  name: "Table",
  components: {
    VsudAvatar,
  },
  mixins: [permissionsMixin],

  props: {
    subjects: { type: Object, required: true },
  },
  methods: {
    viewSubject(id) {
      router.push({
        path: `/materias/ver/${id}`,
        replace: true,
      });
    },
  },
  data() {
    return {
      imgDefault,
      
    };
  },
  computed: {
    sortedSubjects() {
      // Utiliza Array.sort() para ordenar las asignaturas alfabéticamente
      return this.subjects.slice().sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convertir a mayúsculas para una comparación sin distinción entre mayúsculas y minúsculas
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0; // Son iguales
      });
    }
  },
  mounted() {
    
  },
};
</script>
