<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <h1>Listado de mensajes grupales</h1>
        <Table :groups="groups"></Table>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./components/TableMessagesGroups.vue";
import axios from "axios";

export default {
  name: "ListMessages",
  components: {
    Table,
  },
  data() {
    return {
      groups: [],            
    };
  },
  methods: {
    async getMessagesGroups() {      
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}messages/groups`,        
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.groups = data;
        
      } else {
        console.log("Hubo un error coonsultando los tipos de mensaje");
      }
      this.isDisabled = false;
    },
  },
  mounted() {
    this.getMessagesGroups();
  },
};
</script>
