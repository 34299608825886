<template>
  <div>
    <div
      v-if="OpenClose"
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display:block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Asignar curso</h5>
          </div>
          <div class="modal-body">
            <div class="col-12">
              <label>Curso*</label>
              <multiselect
                v-model="inscription.course"
                :hide-selected="true"
                track-by="name"
                label="name"
                placeholder="Seleccione el curso"
                :options="courses"
                selectLabel="Seleccione una opción"
                deselectLabel="Presione para remover"
                selectedLabel="Seleccionado"
               :showNoOptions="false"
              ></multiselect>
            </div>
            <div class="col-12">
              <label>Jornada*</label>
              <multiselect
                v-model="inscription.study_day"
                :hide-selected="true"
                track-by="name"
                label="name"
                placeholder="Seleccione el curso"
                :options="studyDays"
                selectLabel="Seleccione una opción"
                deselectLabel="Presione para remover"
                selectedLabel="Seleccionado"
               :showNoOptions="false"
              ></multiselect>
            </div>
            <div>
              <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
                <div class="alert alert-danger col-md-8">
                  <div v-for="error in arrayErrors" :key="error">
                    <b>{{ error }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer custom-flex">
            <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="mr-auto"
              @click="closeModal()"
            >Cancelar</vsud-button>
            <vsud-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto"
              @click="saveInscription()"
            >Guardar</vsud-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";

export default {
  components: {
    VsudButton,
  },
  name: "KinshipModal",
  props: {
    visible: Boolean,
    actualCourse: Object,
    courses: Array,
    studyDay: String,    
  },
  data() {
    return {
      inscription: {
        study_day: { name: this.studyDay },
        course: [this.actualCourse],
      },
      OpenClose: this.visible,
      arrayErrors: [],
      studyDays: [
        {
          name: "Mañana",
          value: "mañana",
        },
        {
          name: "Tarde",
          value: "tarde",
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    validateForm() {
      this.arrayErrors = [];
      if (this.inscription.course.length == 0) {
        this.arrayErrors.push("Debe seleccionar un curso.");
      }
      if (!this.inscription.study_day) {
        this.arrayErrors.push("Debe seleccionar una jornada.");
      }
    },
    saveInscription() {
      this.arrayErrors = [];
      this.validateForm();
      if (this.arrayErrors.length > 0) {        
        return 0;
      }
      let dataSend = {
        user_id:this.$route.params.id_usuario,
        course_id:this.inscription.course.id,
        study_day: this.inscription.study_day.value
      };      
      this.$emit("saveDataInscription", dataSend);      
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>
<style scoped>
.custom-flex {
  display: flex;
  justify-content: space-between;
}
</style>