<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">Usuarios por Roles</h6>
        <!-- <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="See the consumption per room"
        >
          <i class="fas fa-info"></i>
        </button> -->
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row">
        <div class="text-center col-5">
          <div class="chart">
            <canvas id="chart-consumption" class="chart-canvas" height="197"></canvas>
          </div>
          <h4 class="font-weight-bold mt-n8">
            <span>{{data.total}}</span>
            <span class="text-sm d-block text-body"></span>
          </h4>
        </div>
        <div class="col-7">
          <div class="table-responsive">
            <table class="table mb-0 align-items-center">
              <tbody>
                <tr v-for="(item , index) in rolesLabels" :key="item">
                  <td>
                    <div class="px-2 py-0 d-flex">
                      <span :style="{ 'background-color': colors[index] }" class="badge me-2">&nbsp;</span>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ item }}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <span class="text-xs font-weight-bold">{{data.percentage[index]}}%</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "ReportsDoughnutChart",
  mounted() {
    var ctx1 = document.getElementById("chart-consumption").getContext("2d");
    var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);
    gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
    gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
    gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");
    let chartStatus = Chart.getChart(this.id);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    new Chart(ctx1, {
      type: "doughnut",
      data: {
        labels: Object.keys(this.data.count),
        datasets: [
          {
            label: "Consumption",
            weight: 9,
            cutout: 90,
            tension: 0.9,
            pointRadius: 2,
            borderWidth: 2,
            backgroundColor: this.colors,
            data: Object.values(this.data.count), //va el count
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    });
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    rolesLabels() {
      return Object.keys(this.data.count);
    },
  },
  data() {
    return {
      colors: [
        "#FF0080",
        "#A8B8D8",
        "#21D4FD",
        "#98EC2D",
        "#FF667C",
        "#7F00FF",
        "#FFA500",
        "#32CD32",
        "#FFD700",
        "#00BFFF",
        "#8A2BE2",
        "#FF4500",
        "#00FF7F",
        "#FF1493",
        "#7FFF00",
        "#00FFFF",
        "#9932CC",
        "#FF00FF",
        "#800000",
        "#008080",
      ],
      usersPercentage: null,
    };
  },
};
</script>
