<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="col-sm-4">
            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Buscar por nombre, correo o documento" v-model="valueSearch"
              @keyup.enter="getUsersByRoleTeacher" />
          </div>
          <div class="col-sm-3">
            <button type="button" class="btn bg-gradient-dark btn-md null null ms-auto"
              @click="getUsersByRoleTeacher()">Buscar</button>
          </div>
          <div class="col-sm-4 ms-auto mx-5">
            <div class="row justify-content-end">
              <div class="col-sm-5">
                <div class="row">
                  <button @click="active = !active" class="btn btn-primary">
                    {{ active ? 'Ver Inactivos' : 'Ver Activos' }}
                  </button>
                </div>
              </div>
              <div class="col-sm-2">
                <div v-show="hasPermission('users.teachers.store')" class="row">
                  <router-link to="/usuarios/profesores/nuevo">
                    <button type="button" class="btn bg-gradient-success text-end">Crear</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <Table @getData="getUsersByRoleTeacher" :userList="teachersList" :pagination="pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import ComplexStatisticsCard from "@/examples/Cards/ComplexStatisticsCard.vue";
import Table from "./components/Table.vue";
import permissionsMixin from "@/mixins/permissionsMixin";
import axios from "axios";

export default {
  name: "TeachersList",
  components: {
    ComplexStatisticsCard,
    Table,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      teachersList: [],
      pagination: null,
      valueSearch: null,
    };
  },
  watch: {
    valueSearch(newVal) {
      if (newVal == "") {
        this.valueSearch = null;
        this.getUsersByRoleTeacher()
      }
    },
  },
  methods: {
    async getUsersByRoleTeacher(page = 1) {
      const request = {
        rolesIn: [2],
        rolesNotIn: [1],
        active: this.active,

      };
      const { status, data: dataA } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/user-filtered-paginate/${this.valueSearch}?page=${page}`,
        request,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.teachersList = dataA.data;
        const { data, ...resto } = dataA;
        this.pagination = resto;
      }
    },
    async changePage(page) {
      this.getUsersByRoleTeacher(page);
    },
  },
  mounted() {
    this.getUsersByRoleTeacher();
  },
};
</script>
