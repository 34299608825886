<template>
  <div v-if="rateUser" class="card" style="width: 18rem;">
    <div class="d-flex justify-content-center mt-4">
      <vsud-avatar
        :img="[rateUser.user.photo?rateUser.user.photo:imgDefault]"
        alt="avatar image"
        class="text-center"
      />
    </div>
    <div class="card-body">
      <h5 class="card-title text-center">{{ rateUser.user.name}} {{ rateUser.user.lastname }}</h5>
      <p class="card-text">
        <b>Identificación:</b>
        {{ rateUser.user.document }}
        <br />
        <b>Curso:</b>
        {{ rateUser.course }}
        <br />
        <b>Saldo:</b>
        $ {{totalBalance }}
      </p>
      <div class="text-center">
        <a class="btn btn-primary" @click="saveOrder">Descontar</a>
      </div>
    </div>
  </div>
</template>
  
<script>
import imgDefault from "../../../../assets/img/user-default.png";
import VsudAvatar from "@/components/VsudAvatar.vue";
import { mapState } from "vuex";

export default {
  name: "StudentCard",
  props: {
    typeSearch: String,
  },
  components: { VsudAvatar },
  data() {
    return {
      searchParam: null,
      imgDefault,
    };
  },
  methods: {
    saveOrder() {
      const user = JSON.parse(localStorage.getItem("user"));
      let dataSend = {
        description: `Descuento de almuerzo realizado por ${user.name} ${user.lastname} para ${this.rateUser.name} ${this.rateUser.lastname}`,
        price: this.rateUser.user_rate,
        seller_id: user.id,
        user_id: this.rateUser.user.id,
        refillsTotal: this.rateUser.refillsTotal,
        ordersTotal: this.rateUser.ordersTotal,
        user_rate: this.rateUser.user_rate,
      };
      this.$emit("saveOrder",dataSend);
    },
  },
  mounted() {
    // this.getUsersByRoleAttendant();
  },
  computed: {
    ...mapState(["rateUser"]),
    totalBalance() {
      return this.rateUser.refillsTotal - this.rateUser.ordersTotal;
    },
  },
};
</script>
  <style scoped>
.option-search {
  border-style: solid;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.option-search:hover {
  background-color: #f36c17;
  cursor: pointer;
}
</style>
  