<template>
  <div>
    <div
      v-if="OpenClose"
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display:block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Editar horario</h5>
          </div>
          <div class="modal-body">
            <div class="row mt-3">
              <div class="col-12">
                <label for>Asignatura:</label>
                <multiselect
                  v-model="schedule.subjectsAsing"
                  track-by="name"
                  label="name"
                  placeholder="Seleccione las asignaturas del curso"
                  :multiple="false"
                  :options="subjects"
                  selectLabel="Seleccione una opción"
                  deselectLabel="Presione para remover"
                  selectedLabel="Seleccionado"
                  :showNoOptions="false"
                ></multiselect>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <label for>Profesor que dicta:</label>
                <multiselect
                  v-model="schedule.teacher"
                  track-by="fullName"
                  label="fullName"
                  placeholder="Seleccione el docente para la asignatura"
                  :multiple="false"
                  :options="teachers"
                  selectLabel="Seleccione una opción"
                  deselectLabel="Presione para remover"
                  selectedLabel="Seleccionado"
                  :showNoOptions="false"
                ></multiselect>
              </div>
            </div>
            <div>
              <div class="row mt-3">
                <div class="col-6">
                  <label for>Hora inicio</label>
                </div>
                <div class="col-6">
                  <label for>Hora fin</label>
                </div>
              </div>
              <div class="row mt-3">
                <!-- <div class="col-4">
                  <multiselect
                    v-model="schedule.days"
                    track-by="name"
                    label="name"
                    placeholder="-----"
                    selectLabel="Seleccione un día"
                    deselectLabel="Presione para remover"
                    selectedLabel="Seleccionado"
                    :multiple="false"
                    :options="days"
                  ></multiselect>
                </div>-->
                <div class="col-6">
                  <input
                    v-model="schedule.startTime"
                    class="multisteps-form__input form-control"
                    type="time"
                  />
                </div>
                <div class="col-6">
                  <input
                    v-model="schedule.endTime"
                    class="multisteps-form__input form-control"
                    type="time"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
            <div class="alert alert-danger col-md-8">
              <div v-for="error in arrayErrors" :key="error">
                <b>{{ error }}</b>
              </div>
            </div>
          </div>
          <div v-if="validationsErrors.length > 0" class="row justify-content-center text-center">
            <div class="alert alert-danger col-md-8">
              <div v-for="error in validationsErrors" :key="error">
                <b>{{ error }}</b>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="mr-auto"
              @click="closeModal()"
            >Cancelar</vsud-button>
            <vsud-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto"
              @click="saveSchedule()"
            >Guardar</vsud-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    VsudButton,
  },
  name: "ScheduleModal",
  props: {
    visible: Boolean,
    subjects: Array,
    teachers: Array,
    errors: Array,
    eventEdit: Object,
  },
  data() {
    return {
      OpenClose: this.visible,
      schedule: {
        days: [],
        startTime: [],
        endTime: [],
        subjectsAsing: [],
        teacher: [],
      },
      weeklyHours: 1,
      closeFormDays: false,
      days: [
        {
          id: 1,
          name: "Lunes",
        },
        {
          id: 2,
          name: "Martes",
        },
        {
          id: 3,
          name: "Miercoles",
        },
        {
          id: 4,
          name: "Jueves",
        },
        {
          id: 5,
          name: "Viernes",
        },
      ],
      arrayErrors: this.errors,
    };
  },
  methods: {
    ...mapMutations(["emptyValidationErrors"]),
    closeModal() {
      this.$emit("cancelEditSubject");
    },
    aceptedDaysNumber() {
      if (this.weeklyHour <= 0) {
        alert("La asignatura debe tener al menos una hora semanal");
      } else {
        this.closeFormDays = true;
      }
    },
    saveSchedule() {
      const itemSchedule = this.formatSchedule();
      this.validateForm(itemSchedule);
      if (this.arrayErrors.length > 0) {
        return 0;
      }

      this.$emit("editItemSchedule", itemSchedule);
    },
    formatSchedule() {
      let subj;
      let prof;
      if (this.schedule.subjectsAsing) {
        if (this.schedule.subjectsAsing.id) {
          subj = this.schedule.subjectsAsing;
        } else {
          subj = this.schedule.subjectsAsing[0];
        }
      } else {
        subj = false;
      }

      if (this.schedule.teacher) {
        if (this.schedule.teacher.id) {
          prof = this.schedule.teacher;
        } else {
          prof = this.schedule.teacher[0];
        }
      } else {
        prof = false;
      }

      const startTimeRaw = this.schedule.startTime;
      const endTimeRaw = this.schedule.endTime;
      let hourStart =
        startTimeRaw.split(":")[0] + ":" + startTimeRaw.split(":")[1];
      let hourEnd = endTimeRaw.split(":")[0] + ":" + endTimeRaw.split(":")[1];

      let scheduleFormat = {
        subject: subj,
        id: this.schedule.id,
        teacher: prof ? prof : null,
        day: { id: parseInt(this.eventEdit.day) },
        start: hourStart,
        end: hourEnd,
      };

      console.log("despues del formateo", scheduleFormat);
      return scheduleFormat;
    },
    validateForm(itemSchedule) {
      console.log("desde el validate", itemSchedule);
      this.arrayErrors = [];
      if (!itemSchedule.subject) {
        this.arrayErrors.push("Debe asignar una asignatura");
      } else {
        if (itemSchedule.subject.subject_type == "regular_class") {
          if (!itemSchedule.teacher) {
            this.arrayErrors.push("Debe asignar un profesor");
          }
        }
      }

      if (!itemSchedule.start) {
        this.arrayErrors.push(`Por favor, indique una hora de inicio`);
        return 0;
      }
      if (!itemSchedule.end) {
        this.arrayErrors.push(`Por favor, indique una hora de fin`);
        return 0;
      }
      if (!this.validarHoras(itemSchedule.start, itemSchedule.end)) {
        this.arrayErrors.push(
          `Hay un error de congruencia en las siguientes horas asigandas ${itemSchedule.start} - ${itemSchedule.end}`
        );
        return 0;
      }
    },
    validarHoras(hourStart, hourEnd) {
      hourStart = hourStart.split(":")[0] + ":" + hourStart.split(":")[1];
      hourEnd = hourEnd.split(":")[0] + ":" + hourEnd.split(":")[1];
      const [hourStartNum, minStartNum] = hourStart.split(":").map(Number);
      const [hourEndNum, minEndNum] = hourEnd.split(":").map(Number);

      const minutosInicio = hourStartNum * 60 + minStartNum;
      const minutosFin = hourEndNum * 60 + minEndNum;

      console.log(minutosInicio);
      console.log(minutosFin);

      if (minutosInicio >= minutosFin) {
        return false;
      }

      return true;
    },
    resetForm() {
      this.schedule = {
        days: [],
        startTime: [],
        endTime: [],
        subjectsAsing: [],
        teacher: [],
      };
      this.weeklyHours = 0;
      this.closeFormDays = false;
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
    },
    eventEdit(newVal) {
      console.log("desde el watch:", newVal);
      newVal.teacher.fullName =
        newVal.teacher.name + " " + newVal.teacher.lastname;
      this.schedule.subjectsAsing = [newVal.subject];
      this.schedule.teacher = [newVal.teacher];
      this.schedule.days = [{ id: parseInt(newVal.day) }];
      this.schedule.startTime = newVal.start;
      this.schedule.endTime = newVal.end;
      this.schedule.id = newVal.id;
    },
    errors(newVal) {
      this.arrayErrors = newVal;
    },
  },
  computed: {
    ...mapState(["validationsErrors"]),
  },
  mounted() {
    this.emptyValidationErrors();
  },
};
</script>