<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert
                v-show="alertShow"
                class="mt-1 mx-6 text-center"
                :color="colorAlert"
                >{{ textAlert }}</vsud-alert
              >
            </div>
            <!-- <div class="col-12 col-lg-8 mx-auto my-5">
              <h3>Editar materia</h3>
            </div> -->
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <edit-subject @sendForm="editSubject" :subject="subject"></edit-subject>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditSubject from "./components/EditSubjectForm.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";

export default {
  name: "EditRole",
  components: {
    EditSubject,
    VsudAlert,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      typeDocument: null,
      genders: null,
      bloodTypes: null,
      newUser: null,
      permissions: [],
      errors: [],
      subject: null,
      image: false,
    };
  },
  methods: {
    async getSubject(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}subjects/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.subject = data;
      }
    },
    async editSubject(subject) {
      if (typeof subject.image == "object") {
        this.image = subject.image;
      }
      delete subject.image;

      axios
        .put(`${process.env.VUE_APP_API_BOOKAPP}subjects/${subject.id}`, subject, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          if (this.image) {
            this.saveSubjectImage(this.image, subject.id);
          }
          this.alertShow = true;
          this.textAlert = "La materia se edito correctamente.";
          this.colorAlert = "success";
          window.scrollTo(0, 0);
          this.removeAlert();
        })
        .catch((err) => {
          
        });
    },
    async saveSubjectImage(image, id) {
      let data = new FormData();
      data.append("image", image);
      data.append("_method", "PUT");
      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}subjects/update-image/${id}`, data, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.alertShow = true;
          this.textAlert = "La imagen se edito correctamente.";
          this.colorAlert = "success";
          window.scrollTo(0, 0);
          this.removeAlert();
          
        })
        .catch((err) => {
         
        });
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  mounted() {
    this.getSubject(this.$route.params.id);
  },
};
</script>
