<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="row justify-content-center align-items-center">
        <div v-if="typeSearch=='CODE'" class="col-6 text-center">
          <input
            type="text"
            v-model="searchParam"
            class="form-control"
            placeholder="Codigo de estudiante"
            @keyup.enter="getStudent"
          />
          <vsud-button
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto mb-0 js-btn-next mt-2"
            @click="getStudent"
          >Buscar</vsud-button>
        </div>
        <div v-if="typeSearch=='QR'" class="col-6 text-center">
          <h1>Buscar por QR</h1>
        </div>
        <div v-if="typeSearch=='ID'" class="col-6 text-center">
          <multiselect
            v-model="searchParam"
            :hide-selected="true"
            :internal-search="false"
            :limit="10"
            :options-limit="300"
            track-by="fullName"
            label="fullName"
            placeholder="Escriba el nombre del estudiante a buscar"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="false"
            :options="arrayResult"
            @search-change="getUserFiltered"
            :loading="isLoading"
            :showNoOptions="false"
          ></multiselect>
        </div>

        
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-6 d-flex justify-content-center">
          <student-card @saveOrder="confirmOrder" class="mt-4"></student-card>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import VsudButton from "@/components/VsudButton.vue";
import StudentCard from "@/views/pages/lunch/components/StudentCard.vue";
import Multiselect from "vue-multiselect";
import { mapMutations } from "vuex";

export default {
  name: "SearchStudentForm",
  components: { VsudButton, StudentCard, Multiselect },
  props: {
    typeSearch: String,
  },
  data() {
    return {
      searchParam: null,
      arrayResult: [],
    };
  },
  methods: {
    ...mapMutations(["displayAlertMessage", "closeAlertMessage" ,"setRateUser"]),
    getStudent() {
      this.$emit("searchStudent", this.searchParam);
    },
    async getUserFiltered(searchValue) {
      if (searchValue == null || !searchValue) {
        return 0;
      }
      this.isLoading = true;
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/filter-student/${searchValue}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.arrayResult = data;
        this.arrayResult.map((result) => {
          result.fullName = `${result.name} ${result.lastname} -- n° documento ${result.document}`;
        });
        this.isLoading = false;
      } else {
        console.log("error", data);
      }
    },
    async saveOrder(order) {
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}orders`,
        order,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );      
      if (status == 200) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El almuerzo fue descontado con exito.",
          colorAlert: "success",
        });
        this.setRateUser(null);
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El almuerzo no pudo ser descontado.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
    },
    confirmOrder(order){     
      this.$swal({
        title: "¿Esta seguro de descontar este almuerzo?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, descontar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveOrder(order);
          return true;
        } else {
          this.$swal.dismiss;
          return false;
        }
      });
    }
  },
  computed: {},
  watch: {
    searchParam(newVal, oldValue) {
      if (this.typeSearch == "ID") {
        if (newVal) {
          this.$emit("searchStudent", newVal.id);
        }
      }
    },
    typeSearch(newVal, oldValue) {
      if (newVal) {
        this.searchParam = null;
      }
    },
  },
  mounted() {
    // this.getUsersByRoleAttendant();
  },
};
</script>
  <style scoped>
.option-search {
  border-style: solid;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.option-search:hover {
  background-color: #f36c17;
  cursor: pointer;
}
</style>
  