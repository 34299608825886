<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Envia</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >Enviado a</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Estado</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Asunto</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mensaje</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >Privacidad</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >Tipo de mensaje</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Fecha</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="messages">
            <tr             
              style="cursor: pointer"
              v-for="message in messages"
              :key="message.id"
            >
              <td @click="viewMessage(message)">{{ message.sender_name}}</td>
              <td @click="viewMessage(message)">{{ message.participants}}</td>
              <td :class="message.statuscolor" @click="viewMessage(message)">{{message.status_message}}</td>
              <td @click="viewMessage(message)">{{message.subject}}</td>
              <td @click="viewMessage(message)">{{message.message_no_html}}</td>
              <td @click="viewMessage(message)">{{message.privacity}}</td>
              <td @click="viewMessage(message)">{{ message.type_message.name}}</td>
              <td @click="viewMessage(message)">{{ message.created_at}}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="form-group mt-2" v-if="flag">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" v-if="pagination.current_page > 1">
            <a
              class="page-link"
              @click="$parent.changePage(pagination.current_page - 1)"
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            class="page-item"
            :class="[page == pagination.current_page ? 'active' : '']"
            v-for="page in pagesNumber"
            :key="page"
          >
            <a class="page-link" @click="$parent.changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" v-if="pagination.current_page < pagination.last_page">
            <a
              class="page-link"
              @click="$parent.changePage(pagination.current_page + 1)"
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>   
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";

import router from "@/router/index.js";

export default {
  name: "Table",
  props: {
    messages: { type: Object, required: true },    
    pagination: { type: Object, required: true },
  },
  watch: {
    pagination(newValue, oldValue) {
      this.flag = true;
    },
    messages(newValue, oldValue) {
      this.flag = true;
      this.messages.forEach((m) => {
        this.statusChange[m.id_message] = this.statusOptions.find(
          (st) => st.value == m.status
        );
      });
    },
  },
  computed: {
    pagesNumber() {
      if (!this.pagination.to) {
        return [];
      }

      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }

      var to = from + this.offset * 2;
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }

      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
  },
  methods: {
    viewMessage(m) {
      this.path = `/mensajes/general/${m.id_message}`;
      router.push({
        path: this.path,
        replace: true,
      });
    },getStatusClass(status) {
      switch (status) {
        case 'Publicado':
          return 'green-text';
        case 'Rechazado':
          return 'orange-text';
        case 'Pendiente':
          return 'blue-text';
        case 'Eliminado':
          return 'red-text';
        case 'removed':
          return 'red-text';
        default:
          return '';
      }
    },
   
    changeStatus(selectedOption, id) {
      console.log(selectedOption, id);
    },    
  },
  data() {
    return {
      flag: false,
      imgDefault,
      path: "",
      offset: 3,
      statusChange: [],
      statusOptions: [
        {
          value: "published",
          name: "Publicado",
        },
        {
          value: "pending",
          name: "Pendiente",
        },
        {
          value: "rejected",
          name: "Rechazado",
        },
        {
          value: "removed",
          name: "Eliminado",
        },
      ],
    };
  },
  mounted() {},
};
</script>
<style scoped>
.pagination > li > a {
  background-color: white;
  color: #f06c30;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #f06c30 !important;
  border: solid 1px #f06c30 !important;
}

.pagination > .active > a:hover {
  background-color: #f06c30 !important;
  border: solid 1px #f06c30;
}

.seen {
  background-color: #f2f6fc;
}
.received {
  color: black;
  font-weight: bold;
}
</style>