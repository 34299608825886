<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <!-- <h5 class="font-weight-bolder mb-0">Crear rol</h5> -->
    <p class="mb-0 text-sm">
      Los campos marcados con un asterisco (*) son obligatorios.
    </p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-12">
          <label>Nombre*</label>
          <input
            v-model="role.name"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="ej. Administrativo"
          />
        </div>      
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-12 mt-3 mt-sm-0">
          <label>Permisos*</label>
          <multiselect
            v-if="permissions"
            v-model="permissionsAsing"
            track-by="fullName"
            label="fullName"
            placeholder="Seleccione los permisos a asignar"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado" 
            :multiple="true"
            :close-on-select="false"             
            :options="permissions"
            :hide-selected="true"
            :showNoOptions="false"
          ></multiselect>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          @click="sendForm"
          >Guardar</vsud-button
        >
      </div>
      <div>
        <div
          v-if="arrayErrors.length > 0"
          class="row justify-content-center text-center"
        >
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import { isProxy, toRaw } from "vue";

export default {
  name: "UserInfo",
  props: {
    permissions: {
      type: Object,
      required: true,
    },
    empty: {
      type: String,
      required: true,
    },
  },
  components: {
    VsudInput,
    Multiselect,
    VsudButton,
  },
  data() {
    return {
      arrayErrors: [],
      role: {
        name: null,
        guard_name: 'api',
      },
      guards: ["api", "web"],
      permissionsAsing: [],
    };
  },
  watch: {
    errors(newValue, oldValue) {
      this.arrayErrors = toRaw(newValue);
    },
    empty(newValue, oldValue) {
      if (newValue) {
        this.permissionsAsing = [];
      }
    },
  },
  methods: {
    sendForm() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      this.role.permissions = this.permissionsAsing;
      this.$emit("sendForm", this.role);
    },
    validateForm() {
      this.arrayErrors = [];
      if (!this.role.name) {
        this.arrayErrors.push("El nombre del rol es requerido.");
      }      
      if (this.permissionsAsing.length == 0) {
        this.arrayErrors.push("Debe asignar permisos al nuevo rol.");
      }
    },
  },
};
</script>
<style scoped>
.textArea-fixed {
  resize: none;
}
</style>
