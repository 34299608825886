<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Asignar acudientes</h5>
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div>
      <div class="row mt-3">
        <div v-if="typeKinship" class="col-sm-6">
          <label>Parentesco*</label>
          <select v-model="kinship.type" class="form-control">
            <option v-for="k in typeKinship" :key="k.id" :value="k" class="form-control">
              {{ k.name }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6">
          <label>Acudiente*</label>
          <multiselect
            v-model="kinship.attendants"
            :hide-selected="true"
            :internal-search="false"
            :limit="10"
            :options-limit="300"
            track-by="fullName"
            label="fullName"
            placeholder="Seleccione el acudiente"
            :multiple="true"
            :options="arrResultAttendants"
            @search-change="getUserFiltered"
            :loading="isLoading"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :showNoOptions="false"
          ></multiselect>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
          <br />
          <vsud-button
            class="align-middle"
            type="button"
            color="success"
            variant="gradient"
            @click="addKinship()"
          >
            Agregar
          </vsud-button>
        </div>
      </div>
      <div class="row mt-3">
        <div v-if="arrKinships" class="col">
          <label>Acudientes asignados</label>
          <ul v-for="attendant in arrKinships.kinships" :key="attendant">
            <li>
              {{ attendant.type.name }}
              <ul v-for="att in attendant.attendants" :key="att">
                <li>{{ att.name + " " + att.lastname }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="saveData('B')"
          >Anterior</vsud-button
        >
        <vsud-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="saveData('N')"
          >Siguiente</vsud-button
        >
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";


export default {
  name: "Kinship",
  components: {
    VsudInput,
    VsudButton,
    Multiselect,
  },
  props: {
    isFinalTab: {
      type: Boolean,
      default: false,
    },
    typeKinship: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      arrayErrors: [],
      arrResultAttendants: [],
      searchValue: null,
      showSelectResult: false,
      kinship: {
        type: null,
        attendants: [],
      },
      arrKinships: {
        student_id: null,
        kinships: [],
      },
      isLoading: false,
    };
  },
  methods: {
    async getUserFiltered(searchValue) {
      if (searchValue == null || !searchValue) {
        return 0;
      }
      this.isLoading = true;
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/filter-attendant/${searchValue}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.arrResultAttendants = data;
        this.arrResultAttendants.map((result) => {
          result.fullName = `${result.name} ${result.lastname} -- n° documento ${result.document}`;
        });
        this.isLoading = false;
      } else {
        console.log("error", data);
      }
    },
    validateForm() {
      this.arrayErrors = [];
      if (this.kinship.type == null || this.kinship.attendants.length == 0) {
        this.arrayErrors.push("Debe seleccionar un tipo de acudiente.");
      }
    },
    saveData(flag) {
      this.arrayErrors = [];
      if (this.arrKinships.length == 0) {
        this.arrayErrors.push("Debe seleccionar al menos un acudiente.");
        return 0;
      }
      this.$emit("saveDataKinship", this.arrKinships);
      if (flag == "N") {
        this.$parent.nextStep();
      } else {
        this.$parent.prevStep();
      }
    },
    addKinship() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      this.arrKinships.kinships.push(this.kinship);
      this.kinship = {
        type: null,
        attendants: [],
      };
    },
  },
  mounted() {},
};
</script>
