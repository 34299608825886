<template >
  <div>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
        backgroundImage: `url(${bgImg})`,
        backgroundPositionY: '50%',
      }"
      >
        <span class="mask bg-gradient-info opacity-8"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar image-container avatar-xl position-relative">
              <img
                :src="[user.photo ? user.photo : imgDefault]"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ user.name + " " + user.lastname }}</h5>
              <p
                v-if="user.roles"
                v-for="role in user.roles"
                :key="role.id"
                class="mb-0 text-sm font-weight-bold"
              >{{ role.name }}</p>
            </div>
          </div>
          <div class="mt-3 col-md-4 my-sm-auto me-sm-0">
            <div
              style="cursor: pointer"
              v-show="hasPermission('users.students.update')"
              class="nav-wrapper position-relative end-0"
            >
              <font-awesome-icon :icon="['fass', 'pen-to-square']" />
              <a @click="editUser(user.id)">editar</a>
            </div>
            <div
                    v-if="user.status"
                    v-show="hasPermission('users.students.delete')"
                    @click="preguntarCambiarEstado(user.id)"
                    class="text-secondary text-sm"
                  >
                    <font-awesome-icon
                      class="text-center bg-white cursos icon icon-shape icon-sm border-radius-md align-items-center justify-content-center me-2"
                      :icon="user.status == 'active' ? ['fas', 'toggle-on'] : ['fas', 'toggle-off']"
                      :style="{ color: user.status == 'active' ? 'green' : 'dimgray' }"
                    />
                  </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div v-show="alertShow" class="row">
        <div class="mt-3">
          <div class="position-sticky fixed-top z-index-sticky">
            <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="'succes'">
              {{ textAlert
              }}
            </vsud-alert>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-12 col-lg-8">
          <user-detail-attendant :user="user" v-if="roleId ==4"></user-detail-attendant>
          <user-detail-student :user="user" v-if="roleId ==3"></user-detail-student>
          <user-detail-teacher :user="user" v-if="roleId ==2"></user-detail-teacher>
          <user-detail-administrative :user="user" v-if="roleId ==6"></user-detail-administrative>
        </div>
        <div class="col-12 col-lg-4">
          <div v-if="user.approved && user.approved.length > 0">
            <approvals-card title="Aprueba a" :approvals="user.approved"></approvals-card>
          </div>
          <div v-if="user.approvers && user.approvers.length > 0">
            <approvals-card title="Aprobado por" :approvals="user.approvers"></approvals-card>
          </div>
          <div v-if="user.supervised && user.supervised.length > 0">
            <supervisions-card title="Supervisa a" :supervisions="user.supervised"></supervisions-card>
          </div>
          <div v-if="user.supervisors && user.supervisors.length > 0">
            <supervisions-card title="Supervisado por" :supervisions="user.supervisors"></supervisions-card>
          </div>
          <div v-if="user.roles && user.roles[0].name == 'Estudiante'">
            <inscription-card
              v-if="user.inscription.length > 0"
              title="Información de la matrícula"
              :school="user.inscription[0].school"
              :course="user.inscription[0].course"
              :study_day="user.inscription[0].study_day"
              :idInscription="user.inscription[0].id"
            >
              <i class="fas fa-star-half-alt"></i>
            </inscription-card>
            <kinship-card
              title="Acudientes"
              description="Listado de acudientes."
              :kinship="user.attendants"
              :role="user.roles[0].name"
            >
              <i class="fas fa-star-half-alt"></i>
            </kinship-card>
          </div>
          <div v-if="user.roles && user.roles[0].name == 'Acudiente'">
            <kinship-card
              title="Lista de estudiantes"
              description="Listado de estudiantes a cargo."
              :kinship="user.students"
              :role="user.roles[0].name"
            >
              <i class="fas fa-star-half-alt"></i>
            </kinship-card>
          </div>
          <div v-if="user.roles && user.roles[0].name == 'Profesor'">
            <CoursesCard title="Listado de cursos" :subjects="user.subjects">
              <i class="fas fa-star-half-alt"></i>
            </CoursesCard>
          </div>
          <div v-if="user.roles && user.roles[0].name == 'Profesor'">
            <subject-card title="Listado de asignaturas" :subjects="user.subjects">
              <i class="fas fa-star-half-alt"></i>
            </subject-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgImg from "@/assets/img/curved-images/curved14.jpg";
import UserDetailStudent from "./components/UserDetailStudent.vue";
import UserDetailTeacher from "./components/UserDetailTeacher.vue";
import UserDetailAttendant from "./components/UserDetailAttendant.vue";
import UserDetailAdministrative from "./components/UserDetailAdministrative.vue";
import StoryAvatar from "./components/StoryAvatar.vue";
import KinshipCard from "./components/KinshipCard.vue";
import InscriptionCard from "./components/InscriptionCard.vue";
import SubjectCard from "./components/SubjectCard.vue";
import ApprovalsCard from "./components/ApproversCard.vue";
import SupervisionsCard from "./components/SupervisionsCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";
import imgDefault from "@/assets/img/user-default.png";
import router from "@/router/index.js";
import { mapState, mapMutations } from "vuex";
import CoursesCard from "./components/CoursesCard.vue";

export default {
  name: "ViewUser",
  components: {
    KinshipCard,
    InscriptionCard,
    SubjectCard,
    StoryAvatar,
    UserDetailStudent,
    UserDetailTeacher,
    UserDetailAttendant,
    UserDetailAdministrative,
    ApprovalsCard,
    SupervisionsCard,
    CoursesCard
  },
  mixins: [permissionsMixin],
  data() {
    return {
      imgDefault,
      showMenu: false,
      bgImg,
      imgURL:
        "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/team-9.jpg",
      user: {},
      roleId: 6,
    };
  },
  watch: {
    "$route.params.id_usuario"() {
      this.getUser(this.$route.params.id_usuario);
    },
  },
  methods: {
    async getUser(id_usuario) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users-web/${id_usuario}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.user = data.user;
        if (this.user.roles.find((r) => r.id == 4)) {
          this.roleId = 4;
          console.log("Acudiente");
        }
        if (this.user.roles.find((r) => r.id == 2)) {
          this.roleId = 2;
          console.log("Profesor");
        }
        if (this.user.roles.find((r) => r.id == 3)) {
          this.roleId = 3;
          console.log("Estudiante");
        }
      } else {
        console.log("error", data);
      }
    },
    editUser(id_usuario) {
      const mainRole = this.user.roles[0];
      switch (mainRole.id) {
        case 2:
          router.push({
            path: `/usuarios/editar-profesor/${id_usuario}`,
            replace: true,
          });
          break;
        case 3:
          router.push({
            path: `/usuarios/editar-estudiante/${id_usuario}`,
            replace: true,
          });
          break;
        case 4:
          router.push({
            path: `/usuarios/editar-acudiente/${id_usuario}`,
            replace: true,
          });
          break;
        default:
          router.push({
            path: `/usuarios/editar-administrativo/${id_usuario}`,
            replace: true,
          });
          break;
      }
    },
    preguntarCambiarEstado(userId) {
      this.$swal({
        title: "¿Esta seguro de cambiar el estado de este usuario?",
        text: "Al cambiar el estado del usuario ya no se vera en este listado",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cambiar estado!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cambiarEstado(userId);
        } else {
          this.$swal.dismiss;
        }
      });
    },
    async cambiarEstado(userId) {
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/change-status/${userId}`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.$swal({
          title: "Exito!",
          text: "El estado del usuario se cambio.",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        setTimeout(() => {
      window.location.reload();
    }, 2000);
        // let user = this.userList.filter(u => userId == u.id );
        // console.log(user);
        // user.status = user.status == 'active' ? 'inactive' : 'active';
        this.$emit("getData");
      } else {
        this.$swal({
          title: "Algo salió mal!",
          text:
            "Hubo un error al cambiar el estado del usuario, por favor intente de nuevo.",
          icon: "danger",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      }
    },
  },
  computed: {
    ...mapState(["alertShow", "textAlert", "colorAlert"]),
  },
  mounted() {
    this.getUser(this.$route.params.id_usuario);
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
<style>
.form-check {
  display: inline-block;
  cursor: pointer;
}

.form-check-input {
  margin-right: 8px;
  position: relative;
  flex-shrink: 0;
  width: 1.5em;
  height: 0.75em;
  border-radius: 0.75em;
  transition: 0.2s;
  background-color: #fdffff;
  border: 1px solid #000;
}

.form-check-input:checked {
  background-color: #00a878;
}

.form-check-input:checked:before {
  transform: translateX(0.75em);
}
</style>
