<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="row justify-content-center align-items-center">
        <div class="col-6 text-center">
          <input
            type="text"
            v-model="searchParam"
            class="form-control"
            placeholder="Codigo de estudiante"
            @keyup.enter="getStudent"
          />
          <vsud-button
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto mb-0 js-btn-next mt-2"
            @click="getStudent"
          >Buscar</vsud-button>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-6 d-flex justify-content-center">
          <student-card v-if="origin=='SPECIALS_RATES'" class="mt-4"></student-card>
          <student-card-refill v-if="origin=='REFILLS'" class="mt-4"></student-card-refill>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import VsudButton from "@/components/VsudButton.vue";
import StudentCard from "./StudentCard.vue";
import StudentCardRefill from "./StudentCardRefill.vue";

export default {
  name: "SearchStudentForm",
  components: { VsudButton, StudentCard, StudentCardRefill },
  props: {
    origin: String,
  },
  data() {
    return {
      searchParam: null,
      arrayResult: [],
    };
  },
  methods: {
    getStudent() {
      this.$emit("searchStudent", this.searchParam);
    }, 
  },
};
</script>
  <style scoped>
.option-search {
  border-style: solid;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.option-search:hover {
  background-color: #f36c17;
  cursor: pointer;
}
</style>
  