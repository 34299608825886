<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
                {{ textAlert}}
              </vsud-alert>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 m-auto">
              <form class="multisteps-form__form mb-5">
                <calendar-form></calendar-form>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarForm from "./components/CalendarForm.vue";
import VsudAlert from "@/components/VsudAlert.vue";

export default {
  name: "CalendarCourse",
  components: {
    CalendarForm,
    VsudAlert,
  },
  data() {
    return {
      showMenu: false,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      permissions: null,
      errors: [],
      empty: false,
      subjects: [],
    };
  },
  methods: {
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  mounted() {
    // this.getSubjects();
  },
};
</script>
