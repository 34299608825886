<template>
  <div class="mt-4 mb-3 card mt-lg-0">
    <div class="pb-0 card-body">
      <div class="mb-3 row align-items-center">
        <div class="col-6">
          <h5 class="mb-1 text-gradient text-success">
            <a href="javascript:;">{{ title }}</a>
          </h5>
        </div>
        <div class="col-6 text-end">
          <div v-if="role == 'Estudiante'" v-show="hasPermission('users.kinship.store')" @click="openModal()"
            style="cursor: pointer" class="nav-wrapper position-relative end-0">
            <!-- <font-awesome-icon :icon="['fass', 'pen-to-square']" /> -->
            <b>+</b>
            <a>Agregar</a>
          </div>
        </div>
      </div>
      <ul v-if="role == 'Estudiante'" class="mx-auto list-unstyled">
        <p>{{ description }}</p>
        <li v-for="k in sortedKinship" :key="k.id" class="d-flex mb-3">
          <div class="d-flex px-2 py-1">
            <vsud-avatar :img="[k.attendant.photo ? k.attendant.photo : imgDefault]" class="me-2"
                      alt="avatar image"
                      border-radius="lg"/>
          </div>
          <p class="mb-0" @click="viewProfile(k.attendant_id)" style="cursor: pointer; color:#1155cc">
            {{ getFullName(k.attendant) }}
            <font-awesome-icon @click="deleteQuestion(k)" v-show="hasPermission('users.kinship.delete')"
              :icon="['fass', 'trash']" />
          </p>
          <span class="badge badge-secondary ms-auto">
            {{
              k.type_kinship.name
            }}
          </span>
        </li>
      </ul>
      <ul v-if="role == 'Acudiente'" class="mx-auto list-unstyled">
        <li v-for="k in sortedKinship" :key="k.id" class="d-flex mb-3">
          <div class="d-flex px-2 py-1">
            <vsud-avatar :img="[k.student.photo ? k.student.photo : imgDefault]" class="me-2"
                      alt="avatar image"
                      border-radius="lg"/>
          </div>
          <p class="mb-0" @click="viewProfile(k.student_id)" style="cursor: pointer; color:#1155cc"> {{ getFullName(k.student) }}</p>
          <span class="badge badge-secondary ms-auto">
            {{
              k.type_kinship.name
            }}
          </span>
        </li>
      </ul>
    </div>
    <KinshipModal @closeModal="cancelModal" @saveDataKinship="createKinship" :visible="modalVisible"
      :typeKinship="typeKinship"></KinshipModal>
  </div>
</template>

<script>
import KinshipModal from "./KinshipModal";
import axios from "axios";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissionsMixin";
import router from "@/router/index.js";
import imgDefault from "../../../../assets/img/user-default.png";
import VsudAvatar from "@/components/VsudAvatar.vue";

export default {
  name: "KinshipCard",
  components: { KinshipModal, VsudAvatar },
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    kinship: { type: Object, require: true },
    role: { type: String, require: true },
  },
  mixins: [permissionsMixin],

  data() {
    return {
      showMenu: false,
      modalVisible: false,
      typeKinship: [],
      userKinship: null,
      imgDefault
    };
  },
  methods: {
    getFullName(person) {
    return `${person.name} ${person.lastname}`;
  },
    ...mapMutations(["displayAlertMessage", "closeAlertMessage"]),
    cancelModal() {
      this.modalVisible = false;
    }, viewProfile(id) {
      router.push({
        path: `/ver/usuario/${id}`,
      });
    },
    openModal() {
      this.modalVisible = true;
    },
    async getTypeKinship() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}type-kinships`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.typeKinship = data;
      } else {
        console.log("error", data);
      }
    },
    async createKinship(userKinship) {
      this.userKinship = userKinship;
      this.userKinship.student_id = this.$route.params.id_usuario;
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}kinships/attendant`,
        this.userKinship,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        // console.log('todo bien');
        this.modalVisible = false;
        this.$parent.getUser(this.$route.params.id_usuario);
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El acudiente se asigno correctamente.",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Error al asignar el acudiente.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
    },
    async deleteQuestion(kinship) {
      this.$swal({
        title: "¿Esta seguro de eliminar este acudiente al estudiente?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, eliminar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAttendant(kinship);
          this.closeAlertMessage();
        } else {
          this.$swal.dismiss;
        }
      });

    },
    async deleteAttendant(kinship) {
      const { status, data } = await axios.delete(
        `${process.env.VUE_APP_API_BOOKAPP}kinships/${kinship.id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.modalVisible = false;
        this.$parent.getUser(this.$route.params.id_usuario);
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Se elimino el acudiente correctamente correctamente.",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Error al eliminar el acudiente.",
          colorAlert: "danger",
        });
      }
    },
  },
  computed: {
  sortedKinship() {
    return this.kinship.slice().sort((a, b) => {
      const nameA = this.getFullName(a.attendant || a.student).toUpperCase();
      const nameB = this.getFullName(b.attendant || b.student).toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0; // Son iguales
    });
  },
},
  mounted() {
    this.getTypeKinship();
  },
};
</script>
