<template>
  <div class="lista-container">
    <ul class="list-group">
      <li
        class="list-group-item text-dark rounded-top rounded-bottom my-2"
        v-for="(item, index) in order"
        :key="item"
      >
        <div class="row">
          <div style="font-size: 12px;" class="col-12">
            <label>{{ index +1 }}</label>
            <br />
            <b>{{ item.subject.name }}</b>
            <br />
            <hr />
            <div class="row">
              <div style="font-size: 12px;" class="col-8">
                <div v-if="item.teacher">{{ item.teacher.name }} {{ item.teacher.lastname }}</div>
                <div v-else>Sin profesor</div>
              </div>
              <div style="font-size: 12px;" class="col-4">
                <div v-if="item.teacher">
                  <vsud-avatar
                    :img="[item.teacher.photo ? item.teacher.photo : imgDefault]"
                    class="me-3"
                    style="width:40px;heigth:40px"
                    alt="avatar image"
                    border-radius="lg"
                  />
                </div>
              </div>
            </div>
            <br />
            {{ item.start }} - {{ item.end }}
            <br />
          </div>
        </div>

        <span class="row mt-2">
          <div class="col-6">
            <label
              style="cursor: pointer"
              @click="editItem(item,index)"
              v-show="hasPermission('users.courses.subjects.update')"
            >
              Editar
              <i class="fas fa-user-edit text-secondary"></i>
            </label>
          </div>
          <div v-show="hasPermission('users.courses.subjects.delete')" class="col-6">
            <label style="cursor: pointer" @click="deleteItem(item, index)">
              Borrar
              <i class="fas fa-trash text-secondary"></i>
            </label>
          </div>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>

import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudButton from "@/components/VsudButton.vue";
import permissionsMixin from "@/mixins/permissionsMixin";
import imgDefault from "@/assets/img/user-default.png";
export default {
  components: {
    VsudButton,
    VsudAvatar
  },
  name: "ScheduleList",
  props: {
    schedule: Array,
  },
  data() {
    return {
      imgDefault
    };
  },
  mixins: [permissionsMixin],
  computed: {
    order() {
      this.schedule.sort((objA, objB) => {
        const timeObjA =
          parseInt(objA.start.split(":")[0], 10) * 60 +
          parseInt(objA.start.split(":")[1], 10);
        const timeObjB =
          parseInt(objB.start.split(":")[0], 10) * 60 +
          parseInt(objB.start.split(":")[1], 10);
        return timeObjA - timeObjB;
      });
      return this.schedule;
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit("removeItem", item);
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
  },
};
</script>
<style scoped>
.lista-container {
  max-width: 500px; /* Puedes ajustar este valor según tus necesidades */
  margin: 0 auto; /* Esto centrará el contenedor si es necesario */
}
.list-group-item {
  word-wrap: break-word;
  white-space: normal;
}
</style>