<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-6">
        <div class="mt-4">
          <b>Tipo</b>
          <multiselect
            v-model="filters.type"
            track-by="name"
            label="name"
            placeholder="Tipo de transacciones"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="false"
            :options="typeReport"
            :showNoOptions="false"
          ></multiselect>
        </div>
        <div class="mt-4">
          <b>Fecha inicio</b>
          <input v-model="filters.start" type="date" class="form-control" />
        </div>
      </div>
      <div class="col-6">
        <div class="mt-4">
          <b>Estudiante</b>
          <multiselect
            v-model="filters.student"
            :hide-selected="true"
            :internal-search="false"
            :limit="10"
            :options-limit="300"
            track-by="fullName"
            label="fullName"
            placeholder="Escriba el nombre del estudiante a buscar"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="false"
            :options="arrayResult"
            @search-change="getUserFiltered"
            :loading="isLoading"
            :showNoOptions="false"
          ></multiselect>
        </div>
        <div class="mt-4">
          <b>Fecha fin</b>
          <input v-model="filters.end" type="date" class="form-control" />
        </div>
      </div>
      <div class="col-12 text-center">
      <vsud-button
        type="button"
        color="dark"
        variant="gradient"
        size="sm"
        class="ms-auto mb-0 js-btn-next mt-2"
        @click="getReport()"
      >Filtrar</vsud-button></div>
    </div>
    <div v-if="validationsErrors.length > 0" class="row mt-4 justify-content-center text-center">
      <div class="alert alert-danger col-md-8">
        <div v-for="error in validationsErrors" :key="error">
          <b>{{ error }}</b>
        </div>
      </div>
    </div>
    <div v-if="refills.length > 0 || orders.length > 0" class="row mt-4">
      <div class="col-6 mx-auto justify-content-center text-center">
        <div class="row">
          <div class="col-4">
            <font-awesome-icon icon="fa-solid fa-money-bill" size="2xl" style="color: #222742;" />
            ${{ totalProfits }}
          </div>
          <div class="col-4">
            <font-awesome-icon icon="fa-solid fa-utensils" style="color: #f06C30;" />
            ${{ totalSales }}
          </div>
          <div class="col-4">
            <font-awesome-icon icon="fa-solid fa-chart-line" size="2xl" style="color: #2dd749;" />
            ${{ totalIncome }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >Recargas</button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >Descuentos</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <vue-excel-xlsx
            class="btn btn-excel mt-4 mb-4"
            :data="refills"
            :columns="refillsColumns"
            :file-name="'Recargas'"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
          >
            Descargar
            <font-awesome-icon icon="fa-solid fa-file-excel" size="2xl" class="ml-2" style="color: #ffffff;" />
          </vue-excel-xlsx>
          <br />
          <Table class="mt-4" :columns="refillsColumns" :items="refills"></Table>
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <vue-excel-xlsx
            class="btn btn-excel mt-4 mb-4"
            :data="orders"
            :columns="ordersColumns"
            :file-name="'Ordenes'"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
          >
            Descargar
            <font-awesome-icon icon="fa-solid fa-file-excel" size="2xl" class="ml-2" style="color: #ffffff;" />
          </vue-excel-xlsx>
          <br />
          <Table class="mt-4" :columns="ordersColumns" :items="orders"></Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import VsudButton from "@/components/VsudButton.vue";
import { mapMutations } from "vuex";
import Table from "./components/Table.vue";

export default {
  name: "SchoolRate",
  components: {
    Multiselect,
    VsudButton,
    Table,
  },
  data() {
    return {
      filters: {
        type: null,
        start: null,
        end: null,
        student: null,
      },
      typeReport: [
        {
          id: 0,
          name: "Todas",
        },
        {
          id: 1,
          name: "Recargas",
        },
        {
          id: 2,
          name: "Descuentos (almuerzos)",
        },
      ],
      isLoading: false,
      arrayResult: [],
      validationsErrors: [],
      refillsColumns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Descripcion",
          field: "description",
        },
        {
          label: "Precio",
          field: "price",
        },
        {
          label: "ID transacción",
          field: "id_transaction",
        },
        {
          label: "fecha transacción",
          field: "transaction_date",
        },
        {
          label: "Tipo",
          field: "type",
        },
        {
          label: "Estudiante",
          field: "student_name",
        },
        {
          label: "Vendedor",
          field: "seller_name",
        },
      ],
      ordersColumns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Descripcion",
          field: "description",
        },
        {
          label: "Precio",
          field: "price",
        },
        {
          label: "Estudiante",
          field: "student_name",
        },
        {
          label: "Vendedor",
          field: "seller_name",
        },
      ],
      orders: [],
      refills: [],
    };
  },
  methods: {
    ...mapMutations(["displayAlertMessage", "closeAlertMessage"]),
    async getUserFiltered(searchValue) {
      if (searchValue == null || !searchValue) {
        return 0;
      }
      this.isLoading = true;
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/filter-student/${searchValue}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.arrayResult = data;
        this.arrayResult.map((result) => {
          result.fullName = `${result.name} ${result.lastname} -- n° documento ${result.document}`;
        });
        this.isLoading = false;
      } else {
        console.log("error", data);
      }
    },
    async getReport() {
      this.validateFilter();
      if (this.validationsErrors.length > 0) {
        return 0;
      }
      switch (this.filters.type.id) {
        case 1:
          this.getRefills();
          break;
        case 2:
          this.getOrders();
          break;
        default:
          this.getOrders();
          this.getRefills();
          break;
      }
    },
    async getOrders() {
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}orders/filtered`,
        this.filters,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.orders = data;
        this.orders = this.orders.map((ord) => {
          return {
            id: ord.id,
            description: ord.description,
            price: ord.price,
            student_name: ord.user.name + " " + ord.user.lastname,
            seller_name: ord.seller.name + " " + ord.seller.lastname,
          };
        });
      } else {
        console.log("error");
      }
    },
    async getRefills() {
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}refills/filtered`,
        this.filters,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.refills = data;
        console.log(this.refills);
        this.refills = this.refills.map((ref) => {
          return {
            id: ref.id,
            description: ref.description,
            price: ref.price,
            type: ref.type,
            id_transaction: ref.id_transaction,
            transaction_date: ref.transaction_date,
            transaction_date: ref.transaction_date,
            student_name: ref.user.name + " " + ref.user.lastname,
            seller_name: ref.seller.name + " " + ref.seller.lastname,
          };
        });
      } else {
        console.log("error");
      }
    },
    validateFilter() {
      this.validationsErrors = [];
      if (this.filters.start) {
        if (!this.filters.end) {
          this.validationsErrors.push(
            "Si tiene fecha de inicio debe tener fecha de fin."
          );
        }
      }
      if (this.filters.end) {
        if (!this.filters.start) {
          this.validationsErrors.push(
            "Si tiene fecha de fin debe tener fecha de inicio."
          );
        }
      }
      if (this.filters.start && this.filters.end) {
        const date1 = new Date(this.filters.start);
        const date2 = new Date(this.filters.end);
        if (date1 > date2) {
          this.validationsErrors.push(
            "La fecha de inicio debe ser menor a la fecha de fin."
          );
        }
      }
      if (!this.filters.type) {
        this.validationsErrors.push("Seleccione un tipo de transacción.");
      }
    },
  },
  computed: {
    totalProfits() {
      let result = 0;
      this.refills.forEach((ref) => {
        result += ref.price;
      });
      return result;
    },
    totalSales() {
      let result = 0;
      this.orders.forEach((ref) => {
        result += ref.price;
      });
      return result;
    },
    totalIncome() {
      return this.totalProfits - this.totalSales;
    },
  },
  mounted() {
    // this.getUsersByRoleAttendant();
  },
};
</script>
