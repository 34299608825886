<template>
  <div class="container-fluid py-4">
    <div class="d-flex flex-row-reverse">
    <!-- <div class="text-end">
                <div class="Mensajes Recibidos">
                  <router-link to="/mensajes/listar/recibidos"><button type="button" class="btn bg-gradient-primary">Recibidos</button></router-link>
            </div>
            <router-view/>
          </div>
          <div class="text-end">
                <div class="Llamados Aprobar">
                  <router-link to="/llamados-atencion/aprobar"><button type="button" class="btn bg-gradient-primary" style="margin-right: 10px">Por Aprobar</button></router-link>
            </div>
            <router-view/>
          </div> -->
        </div>
    <div class="row">
      <div class="position-sticky fixed-top z-index-sticky">
        <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
          {{ textAlert
          }}
        </vsud-alert>
      </div>
      <div class="col-12">
        <CallAttentionForm></CallAttentionForm>
      </div>
    </div>
  </div>
</template>
  
<script>
import Table from "./components/Table.vue";
import CallAttentionForm from "./components/CallAttentionForm.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import { mapState } from "vuex";
export default {
  name: "CallAttention",
  components: {
    // CalendarForm,
    VsudAlert,
    Table,
    CallAttentionForm,
  },
  data() {
    return {
      showMenu: false,
      permissions: null,
      errors: [],
      empty: false,
      subjects: [],
    };
  },
  methods: {
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  mounted() {
    // this.getSubjects();
  },
  computed: {
    ...mapState(["alertShow", "textAlert", "colorAlert"]),
  },
};
</script>
  