<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <!-- <h5 class="font-weight-bolder mb-0">Crear curso</h5> -->
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Nombre*</label>
          <input
            v-model="course.name"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="11° B"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Cantidad</label>
          <input
            v-model="course.quota"
            class="multisteps-form__input form-control"
            type="number"
            placeholder="40"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label>Asignaturas</label>
          <multiselect
            v-model="course.subjects"
            :close-on-select="false"
            track-by="name"
            label="name"
            placeholder="Seleccione las asignaturas del curso"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="true"
            :options="subjects"
            :hide-selected="true"
            :showNoOptions="false"
          ></multiselect>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          @click="sendForm"
        >Guardar</vsud-button>
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import { isProxy, toRaw } from "vue";

export default {
  name: "FormCourse",
  components: {
    VsudInput,
    Multiselect,
    VsudButton,
  },
  props: {
    errors: {
      type: Array,
      required: false,
    },
    subjects: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      arrayErrors: [],
      course: {
        name: null,
        quota: 0,
        subjects: [],
      },
      subject_type: ["clase regular", "extra clase"],
      status: ["activo", "inactivo"],
      permissionsAsing: [],
    };
  },
  watch: {
    errors(newValue, oldValue) {
      this.arrayErrors = toRaw(newValue);
    },
  },
  methods: {
    sendForm() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      this.$emit("sendForm", this.course);
    },
    validateForm() {
      this.arrayErrors = [];
      if (!this.course.name) {
        this.arrayErrors.push("El curso debe tener un nombre.");
      }
      // if (this.course.subjects.length === 0) {
      //   this.arrayErrors.push("El curso debe tener asignaturas asignadas.");
      // }
    },
  },
};
</script>
<style scoped>
.textArea-fixed {
  resize: none;
}
</style>
