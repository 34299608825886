<template>
  <div>
    <div
      v-if="OpenClose"
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display:block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Agregar comentario al cambio de estado</h5>
          </div>
          <div class="modal-body">
            <div class="row mt-3">
              <div class="col-12">
                <label for>Comentario:</label>                
                <textarea
                  v-model="comment"
                  class="multisteps-form__input form-control textArea-fixed"
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="mr-auto"
              @click="closeModal()"
            >Cancelar</vsud-button>
            <vsud-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto"
              @click="saveComment()"
            >Guardar</vsud-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <style src="vue-multiselect/dist/vue-multiselect.css"></style> -->

<script>
import "vue-multiselect/dist/vue-multiselect.css";
import VsudButton from "@/components/VsudButton.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
export default {
  components: {
    VsudButton,
    Multiselect,
  },
  name: "CalendarModal",
  props: {
    visible: Boolean,
    typeActivities: Array,
    icons: Array,
    course: String,
  },
  data() {
    return {
      OpenClose: this.visible,
      arrayErrors: this.errors,
      comment: null,
    };
  },
  methods: {
    ...mapActions(["setValidationError", "setEvent"]),
    ...mapMutations(["emptyValidationErrors", "setCommentMessage"]),
    closeModal() {
      this.$emit("cancelSave");
    },
    saveComment() {
      this.$emit("saveComment", this.comment);
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
      // this.date_start = this.dateCalendar.dateStr;
    },
  },
  mounted() {
    // this.user = JSON.parse(localStorage.getItem("user"));
    // this.event.created_by = this.user.id;
    // this.event.course_id = this.$route.params.id_curso;
  },
  beforeUnmounted() {
    // this.emptyValidationErrors();
  },
  created() {
    //
  },
};
</script>
<style>
.scrollable-menu {
  max-height: 200px;
  max-width: auto;
  overflow-y: auto;
}
</style>