<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <!-- <h5 class="font-weight-bolder mb-0">Editar Asignatura</h5> -->
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Nombre*</label>
          <input
            v-model="subject.name"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="ej. Historia"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Estado*</label>
          <multiselect
            v-model="subject.status"
            placeholder="Seleccione los permisos a asignar"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="false"
            :options="status"
            :showNoOptions="false"
          ></multiselect>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Tipo de asignatura*</label>
          <multiselect
            v-model="subject.subject_type"
            placeholder="Seleccione los permisos a asignar"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="false"
            :options="subject_type"
            :showNoOptions="false"
          ></multiselect>
        </div>
        <div class="col-12 col-sm-6">
          <label>Imagen</label>
          <input
            @change="selectImagen()"
            accept=".png, .jpg, .jpeg, .gif"
            ref="file"
            class="multisteps-form__input form-control"
            type="file"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          @click="sendForm"
        >Guardar</vsud-button>
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";

import { isProxy, toRaw } from "vue";

export default {
  name: "FormSubject",
  components: {
    VsudInput,
    Multiselect,
    VsudButton,
  },
  props: {
    errors: {
      type: Array,
      required: false,
    },
    empty: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      arrayErrors: [],
      subject: {
        name: null,
        status: null,
        subject_type: null,
        image: null,
      },
      subject_type: ["clase regular", "extra clase"],
      status: ["activo", "inactivo"],
      permissionsAsing: [],
    };
  },
  watch: {
    errors(newValue, oldValue) {
      this.arrayErrors = toRaw(newValue);
    },
    empty(newValue, oldValue) {
      if (newValue) {
        this.$refs.file.value = null;
        this.subject = {
          name: null,
          status: null,
          subject_type: null,
          image: null,
        };
      }
    },
  },
  methods: {
    clearForm() {
      this.$refs.file.value = null;
      this.subject = {
        name: null,
        status: null,
        subject_type: null,
        image: null,
      };
    },
    sendForm() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      this.subject.status =
        this.subject.status == "activo" ? "active" : "inactive";
      this.subject.subject_type =
        this.subject.subject_type == "clase regular"
          ? "regular_class"
          : "extra_class";

      const subjectSend = new FormData();
      subjectSend.append("name", this.subject.name);
      subjectSend.append("status", this.subject.status);
      subjectSend.append("subject_type", this.subject.subject_type);
      if (this.subject.image) {
        subjectSend.append("image", this.subject.image);
      }

      this.$emit("sendForm", subjectSend);
    },
    validateForm() {
      this.arrayErrors = [];
      if (!this.subject.name) {
        this.arrayErrors.push("La materia debe tener un nombre.");
      }
      if (!this.subject.subject_type) {
        this.arrayErrors.push("La materia debe tener un tipo.");
      }
      if (!this.subject.status) {
        this.arrayErrors.push("La materia debe tener un estado");
      }
    },
    selectImagen() {
      if (!this.$refs.file.files[0]) {
        return 0;
      }
      let fileSize = this.$refs.file.files[0].size / 1024 / 1024;
      if (fileSize > 2) {
        alert("El archivo debe pesar menos de 2 MB");
        return;
      }
      this.subject.image = this.$refs.file.files[0];
    },
  },
};
</script>
<style scoped>
.textArea-fixed {
  resize: none;
}
</style>
