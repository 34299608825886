<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <!-- <template v-if="columns"> -->
          <tr>
            <th
              v-for="column in columns"
              :key="column.label"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >{{column.label}}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items">
            <tr v-for="item in items" :key="item.id">
              <td v-for="column in columns" :key="column.id">{{ item[column.field] }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";

import router from "@/router/index.js";

export default {
  name: "Table",
  props: {
    items: { type: Object, required: true },
    columns: { type: Object, required: true },
    listType: { type: String, required: true },
  },
  data() {
    return {
      flag: false,
      imgDefault,
      path: "",
      offset: 3,
    };
  },
  methods: {
    // viewMessage(m) {
    //   this.makeRoute(m);
    //   router.push({
    //     path: this.path,
    //     replace: true,
    //   });
    // },
    // makeRoute(m) {
    //   switch (this.listType) {
    //     case "send":
    //       this.path = `/mensajes/mensajes/${m.id_message}`;
    //       break;
    //     case "received":
    //       this.path = `/mensajes/mensajes/${m.id_message}`;
    //       break;
    //     case "approve":
    //       this.path = `/mensajes/mensajes-aprobar/${m.id_message}`;
    //       break;
    //   }
    // },
  },
  mounted() {},
};
</script>
<style scoped>
.pagination > li > a {
  background-color: white;
  color: #f06C30;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #f06C30 !important;
  border: solid 1px #f06C30!important;
}

.pagination > .active > a:hover {
  background-color: #f06C30 !important;
  border:solid 1px #f06C30;
}
</style>
