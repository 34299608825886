<template>
  <div class="container-fluid py-4">
    <div v-if="school" class="d-flex justify-content-center">
      <div class="d-flex justify-content-center">
        <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
          {{ textAlert
          }}
        </vsud-alert>
      </div>
      <div class="">
        <!-- <h1>Tarifas generales</h1> -->
        <div class="card text-center" style="width: 18rem;">
          <!-- <div class="d-flex justify-content-center mt-4">            
          </div>-->
          <div class="card-body text-center">
            <h5 class="card-title"></h5>
            <p class="card-text">
              <b>
                <h3 class="text-center">Tarifa general de almuerzos</h3>
              </b>
              <br />
              <b>Saldo:</b>
              $ {{ school.restaurant_rate? school.restaurant_rate: '0' }}
              <br />
              <div class="row">
                <input v-if="changeRate" v-model="rate" class="form-control" type="number" min="0" max="50000" name id />
                <a  v-if="changeRate" @click="updateSchool()" class="btn btn-secondary mt-2">Guardar</a>
              </div>
            </p>
            <div v-show="hasPermission('school.restaurant_rate.update')"  class="text-center">
              <a class="btn btn-primary" @click="changeRate = !changeRate">Cambiar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VsudAlert from "@/components/VsudAlert.vue";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "SchoolRate",
  components:{
    VsudAlert
  },
  mixins: [permissionsMixin],

  data() {
    return {
      school: null,
      rate: null,
      changeRate: null,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
    };
  },
  methods: {
    async getSchool() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}schools`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.school = data;
        this.rate = this.school.restaurant_rate;
      } else {
        console.log("Error");
      }
      console.log(data);
    },
    async updateSchool() {
      if(this.rate < 0){
        alert('La tarifa debe ser mayor a 0');
        return 0;
      }
      let dataSend = {
        restaurant_rate: this.rate,
      };
      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}schools/${this.school.id}`,dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {        
        this.changeRate = false;
        this.school.restaurant_rate = this.rate;
        this.alertShow = true;
        this.textAlert = "La tarifa fue cambiada correctamente.";
        this.colorAlert = "success";
      } else {
        console.log("Error");
        this.alertShow = true;
        this.textAlert = "Hubo un error al cambiar la tarifa.";
        this.colorAlert = "danger";
      }
      // console.log(data);
      this.closeAlert();
    },
    closeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  mounted() {
    // this.getUsersByRoleAttendant();
    this.getSchool();
  },
};
</script>
