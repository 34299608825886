<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <!-- <h5 class="font-weight-bolder mb-0">Crear permiso</h5> -->
    <p class="mb-0 text-sm">
      Los campos marcados con un asterisco (*) son obligatorios.
    </p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Nombre*</label>
          <input
            v-model="permission.name"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="ej. create.user.admin"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Modulo*</label>
          <input
            v-model="permission.module"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="ej. usuarios"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-12">
          <label>Descripción*</label>
          <input
            v-model="permission.description"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="ej. se crean usuarios admins"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          @click="sendForm"
          >Guardar</vsud-button
        >
      </div>
      <div>
        <div
          v-if="arrayErrors.length > 0"
          class="row justify-content-center text-center"
        >
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import { isProxy, toRaw } from "vue";

export default {
  name: "UserInfo",
  props: {
    errors: {
      type: Array,
      required: false,
    },
  },
  components: {
    VsudInput,
    VsudButton,
  },
  data() {
    return {
      arrayErrors: [],
      permission: { name: null, module: null, description: null },
    };
  },
  watch: {
    errors(newValue, oldValue) {
      this.arrayErrors = toRaw(newValue);
    },
  },
  methods: {
    sendForm() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      this.$emit("sendForm", this.permission);
    },
    validateForm() {
      this.arrayErrors = [];
      if (!this.permission.name) {
        this.arrayErrors.push("El nombre del permiso es requerido.");
      }
      if (!this.permission.module) {
        this.arrayErrors.push("El modulo del permiso es requerido.");
      }
      if (!this.permission.description) {
        this.arrayErrors.push("La descripción del permiso es requerida.");
      }
    },
  },
};
</script>
<style scoped>
.textArea-fixed {
  resize: none;
}
</style>
