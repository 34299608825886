<template>
  <div>
    <div
      v-if="OpenClose"
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display:block; z-index: 10000;"
    >
      <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Participantes</h5>
          </div>
          <div class="modal-body">
            <div class="row mt-3">
              <div class="col-12">
                <ul class="list-group">
                  <div v-if="participants.length > 0">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-3">Participante</div>
                        <div class="col-3">Estudiante</div>
                        <div class="col-3">Curso</div>
                        <div class="col-3">Leido</div>
                      </div>
                    </li>
                    <li
                      class="list-group-item"                      
                      v-for="participant in participants"
                      :key="participant.id"
                    >
                      <div class="row">
                        <div class="col-3">{{participant.user.name +" "+ participant.user.lastname}}</div>
                        <div
                          class="col-3"
                        >{{ participant.student? participant.student.name +" "+ participant.student.lastname : ''}}</div>
                        <div
                          class="col-3"
                        >{{participant.student? participant.student.inscription.course.name:'' }}</div>
                        <div
                          class="col-3"
                        >{{participant.status == 'seen'? participant.updated_at:'' }}</div>
                      </div>
                    </li>
                  </div>
                  <div v-if="participants.length == 0">
                    <li>Mensaje institucional</li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="mr-auto"
              @click="closeModal()"
            >Cerrar</vsud-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <style src="vue-multiselect/dist/vue-multiselect.css"></style> -->

<script>
import "vue-multiselect/dist/vue-multiselect.css";
import VsudButton from "@/components/VsudButton.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    VsudButton,
    Multiselect,
  },
  props: {
    visible: Boolean,
    participants: Array,
  },
  data() {
    return {
      OpenClose: this.visible,
      arrayErrors: this.errors,
      comment: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("cancelSave");
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
      // this.date_start = this.dateCalendar.dateStr;
    },
  },
  mounted() {
    // this.user = JSON.parse(localStorage.getItem("user"));
    // this.event.created_by = this.user.id;
    // this.event.course_id = this.$route.params.id_curso;
  },
  beforeUnmounted() {
    // this.emptyValidationErrors();
  },
  created() {
    //
  },
};
</script>
<style>
.scrollable-menu {
  max-height: 200px;
  max-width: auto;
  overflow-y: auto;
}
</style>