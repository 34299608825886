<template>
  <div>
    <div
      v-if="OpenClose"
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display:block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Tarifa de estudiante {{ specialRate.user.name +" "+specialRate.user.lastname }}</h5>
          </div>
          <div class="modal-body">
            <!-- <p> -->
            <h5>Ingrese la tarifa preferencial</h5>
            <input
              type="number"
              max="50000"
              min="0"
              v-model="specialRate.rate"
              class="form-control"
            />
            <!-- </p> -->
          </div>
          <div class="modal-footer custom-flex">
            <vsud-button
              type="button"
              color="success"
              variant="contained"
              class="mr-auto"
              @click="updateSpecialRate"
            >Ok</vsud-button>
            <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="ml-auto"
              @click="closeModal"
            >Cancelar</vsud-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    VsudButton,
  },
  name: "EditSpecialRateModal",
  props: {
    visible: Boolean,
    specialRate: Object,
  },
  data() {
    return {
      OpenClose: this.visible,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    updateSpecialRate() {
      if (this.specialRate.rate < 0) {
        alert("La tarifa debe ser mayor a 0");
        return 0;
      }
      let dataSend = {
        rate: this.specialRate.rate,
        id: this.specialRate.id,
      };
      this.$emit("updateSpecialRate", dataSend);
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
    },
  },
  computed: {},
  mounted() {},
};
</script>
<style scoped>
.custom-flex {
  display: flex;
  justify-content: space-between;
}
</style>