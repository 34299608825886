<template>
  <div v-if="rateUser" class="card" style="width: 18rem;">
    <div class="d-flex justify-content-center mt-4 borde">
      <vsud-avatar
        :img="[rateUser.user.photo?rateUser.user.photo:imgDefault]"
        alt="avatar image"
        class="text-center borde avatar image-container avatar-xl position-relative"
      />
    </div>
    <div class="card-body">
      <h5 class="card-title text-center">{{ rateUser.user.name}} {{ rateUser.user.lastname}} </h5>
      <p class="card-text">
        <b>Identificación:</b>
        {{ rateUser.user.document }}
        <br />
        <b>Curso:</b>
        {{ rateUser.course }}
        <br />
        <b>Saldo:</b>
        $ {{ totalBalance }}
      </p>
      <div class="text-center">
        <input
          v-model="specialRate"
          placeholder="Tarifa"
          type="number"
          max="50000"
          min="0"
          class="form-control"
        />
        <a @click="saveSpecialRate" class="btn btn-primary mt-2">Guardar</a>
      </div>
    </div>
  </div>
</template>
  
<script>
import imgDefault from "../../../../assets/img/user-default.png";
import VsudAvatar from "@/components/VsudAvatar.vue";
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "StudentCard",
  components: { VsudAvatar },
  data() {
    return {
      searchParam: null,
      imgDefault,
      specialRate: null,
    };
  },
  methods: {
    ...mapMutations(["setSpecialsRates", "displayAlertMessage", "setRateUser"]),
    async saveSpecialRate() {
      if (this.specialRate < 0) {
        alert("La tarifa debe ser mayor a 0");
        return 0;
      }
      const user = JSON.parse(localStorage.getItem("user"));
      const dataSend = {
        rate: this.specialRate,
        status: "active",
        approver_id: user.id,
        user_id: this.rateUser.user.id,
      };
      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}special-rates`, dataSend, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            let specialRateNew = {
              id: response.data.id,
              user: response.data.user,
              rate: response.data.rate,
              course: response.data.user.inscription[0].course,
              status: response.data.status,
            };
            this.setSpecialsRates(specialRateNew);
            this.displayAlertMessage({
              alertShow: true,
              textAlert: "Tarifa agregada con exito.",
              colorAlert: "success",
            });
            this.setRateUser(null);
          }
        })
        .catch((response) => {
          if (response.response.status == 400) {
            this.displayAlertMessage({
              alertShow: true,
              textAlert:
                "No se pudo agregar la tarifa ya que el estudiante tiene una tarifa ya activa.",
              colorAlert: "danger",
            });
          } else {
            console.log("Error");
            this.displayAlertMessage({
              alertShow: true,
              textAlert: "La tarifa no pudo ser agregada.",
              colorAlert: "danger",
            });
          }
        });

      this.closeAlert();
    },
    closeAlert() {
      setTimeout(() => {
        this.displayAlertMessage({
          alertShow: false,
          textAlert: null,
          colorAlert: null,
        });
      }, 3000);
    },
  },
  computed: {
    ...mapState(["rateUser"]),
    totalBalance() {
      return this.rateUser.refillsTotal - this.rateUser.ordersTotal;
    },
  },
};
</script>
  <style scoped>
.option-search {
  border-style: solid;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.option-search:hover {
  background-color: #f36c17;
  cursor: pointer;
}
.borde{
  border-radius: 10%;
};
</style>
  