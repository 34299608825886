<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <div class="row">
          <div class="position-sticky fixed-top z-index-sticky">
            <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
              {{ textAlert
              }}
            </vsud-alert>
          </div>
          <div class="col-3 d-flex justify-content-end">
            <h1 class="option-search p-2" @click="typeSearch='CODE'">
              <font-awesome-icon icon="fa-solid fa-keyboard" />
            </h1>
          </div>
          <div class="col-3 d-flex justify-content-center">
            <h1 class="option-search p-2" @click="typeSearch='QR'">
              <font-awesome-icon icon="fa-solid fa-qrcode" />
            </h1>
          </div>
          <div class="col-3 d-flex justify-content-center">
            <h1 class="option-search p-2" @click="typeSearch='ID'">
              <font-awesome-icon icon="fa-solid fa-list" />
            </h1>
          </div>
          <div class="col-3 d-flex justify-content-start">
            <h1 class="option-search p-2" @click="typeSearch='COURSE'">
              <!-- <font-awesome-icon icon="fa-solid fa-list" /> -->
              CL
            </h1>
          </div>
        </div>
        <SearchStudentForm @searchStudent="getStudent" :typeSearch="typeSearch"  v-if="typeSearch != 'COURSE'"></SearchStudentForm>
        <StudentListCourse v-if="typeSearch== 'COURSE'" :typeSearch="typeSearch"></StudentListCourse>
      </div>
    </div>
  </div>
</template>

<script>
import SearchStudentForm from "./components/SearchStudentForm.vue";
import StudentListCourse from "./components/StudentListCourse.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  name: "Refills",
  components: {
    SearchStudentForm,
    StudentListCourse,
    VsudAlert,
  },
  watch: {
    typeSearch(newVal, oldValue) {
      if (newVal) {
        this.setRateUser(null);
      }
    },
  },
  data() {
    return {
      typeSearch: "CODE",
      courseList: [],
      valueSearch: null,
    };
  },
  methods: {
    ...mapMutations(["setRateUser"]),
    async getStudent(searchParam) {
      this.setRateUser(null);
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/orders-refills?key=${this.keyParse}&value=${searchParam}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (data.status == 404) {
        this.alertShow = true;
        this.textAlert = "El estudiante no fue encontrado.";
        this.colorAlert = "danger";
      } else if (data.status == 200) {
        this.setRateUser(data);
        this.alertShow = true;
        this.textAlert = "El estudiante no fue encontrado";
        this.colorAlert = "success";
      } else {
        this.alertShow = true;
        this.textAlert =
          "Hubo un error al buscar el usuario, intente nuevamente.";
        this.colorAlert = "danger";
      }
      this.closeAlert();
    },
    closeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },  
  },
  computed: {
    keyParse() {
      return this.typeSearch.toLowerCase();
    },
    ...mapState(["alertShow", "textAlert", "colorAlert"]),
  },
  mounted() {
    // this.getUsersByRoleAttendant();
  },
  watch: {
    typeSearch(valNew) {
      // console.log(valNew);
      // if (valNew == "COURSE") {
      //   this.getCoursesList()
      // }
    },
  },
};
</script>
<style scoped>
.option-search {
  border-style: solid;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.option-search:hover {
  background-color: #f36c17;
  cursor: pointer;
}
</style>
