<template>
  <div class="card">
    <div class="py-3 card-header d-flex align-items-center border-bottom col-auto my-auto">
      <div class="h-100">
        <h6 class="mb-1">Información detallada del usuario</h6>
      </div>
    </div>
    <div class="py-3 ml-4 card-body">
      <div class="mb-1">
        <div v-if="user" class="row">
          <div class="col-sm-12 col-md-6">
            <label>Ultimo inicio de sesion</label>
            <p>{{ user.last_login || "---" }}</p>
            <label>Ultimo cierre de sesion</label>
            <p >{{ user.last_logout || "---" }}</p>
            <label>Tipo de documento</label>
            <p v-if=" user.type_document">{{ user.type_document.name || '---' }}</p>
            <label>Código</label>
            <p>{{ user.code }}</p>
            <label>Perfil</label>
            <p>{{ user.profile || "---" }}</p>
            <label>Correo</label>
            <p>{{ user.email }}</p>
          </div>
          <div class="col-sm-12 col-md-6">
            <label>Documento</label>
            <p>{{ user.document }}</p>
            <label>Fecha de nacimiento</label>
            <p>{{ user.birth_date }}</p>
            <label>Teléfono</label>
            <p>{{ user.phone || "---" }}</p>
            <label>Género</label>
            <p v-if=" user.gender">{{ user.gender.name }}</p>
            <label>Estado</label>
            <p>{{ user.status=="active"?"Activo":"Inactivo"}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bruce from "@/assets/img/bruce-mars.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team4 from "@/assets/img/team-4.jpg";
import team5 from "@/assets/img/team-5.jpg";

export default {
  name: "UserDetailTeacher",
  props: {
    user: { type: Object, require: true },
  },
  data() {
    return {
      bruce,
      team1,
      team2,
      team4,
      team5,
    };
  },
};
</script>
