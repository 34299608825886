<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="position-sticky fixed-top z-index-sticky">
        <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
          {{ textAlert
          }}
        </vsud-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-12 ms-auto">
        <div class="col-xl-12">
          <Calendar
            v-if="flag"
            @openModal="openModal"
            @openModalDetail="openModalDetail"
            :initialDate="initialDate"
          />
        </div>
      </div>
    </div>
    <CalendarModal
    v-show="hasPermission('users.courses.event.create')"
      :visible="modalVisible"
      @cancelSave="cancelSaveCalendar()"
      @saveItem="saveItemCalendar"
      :typeActivities="typeActivities"
      :icons="icons"
    ></CalendarModal>
    <EventModal
    v-show="hasPermission('users.courses.event.delete')"
      @deleteEvent="deleteCalendar"
      @closeModal="closeModalEvent"
      :visible="modalVisibleDetail"
    ></EventModal>
  </div>
</template>

<script>
import Calendar from "@/examples/Calendar.vue";
import CalendarModal from "./CalendarModal.vue";
import EventModal from "./EventModal.vue";
import axios from "axios";
import VsudAlert from "@/components/VsudAlert.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "CalendarForm",
  components: { Calendar, CalendarModal, VsudAlert, EventModal },
  mixins: [permissionsMixin],

  data() {
    return {
      modalVisible: false,
      modalVisibleDetail: false,
      events: [],
      initialDate: null,
      typeActivities: [],
      icons: [],
      course_id: null,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      flag: false,
    };
  },
  mounted() {
    let fechaActual = new Date();
    let año = fechaActual.getFullYear();
    let mes = String(fechaActual.getMonth() + 1).padStart(2, "0");
    let dia = String(fechaActual.getDate()).padStart(2, "0");
    let fechaEnFormato = año + "-" + mes + "-" + dia;
    this.initialDate = fechaEnFormato;
    this.getTypeActivities();
    this.course_id = this.$route.params.id_curso;
    this.getEventsByCourse();
    this.resetEvents();
    this.getIcons();
  },
  computed: {
    ...mapState(["calendar", "eventDetail"]),
  },
  methods: {
    ...mapActions(["setEvent"]),
    ...mapMutations(["resetEvents"]),
    openModal() {
      this.modalVisible = true;
    },
    openModalDetail() {
      this.modalVisibleDetail = true;
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
    cancelSaveCalendar() {
      this.modalVisible = false;
    },
    closeModalEvent() {
      this.modalVisibleDetail = false;
    },
    async getTypeActivities() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}type-activities`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.typeActivities = data;
      }
    },
    async getIcons() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}icons/type/1`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.icons = data.data;
      }
    },
    async getEventsByCourse() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}calendars/course/${this.course_id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.events = data;
        this.events = this.events.map((e) => {
          return {
            created_by: e.created_by,
            image: e.image,
            link: e.link,
            type_activity: e.type_activity,
            id: e.id,
            title: e.title,
            start: e.date_start,
            end: e.date_end,
            backgroundColor: e.type_activity.color,
          };
        });
        this.flag = true;
        this.events.forEach((e) => {
          this.setEvent(e);
        });
        // console.log(this.events);
      }
    },
    async deleteCalendar(idEvent) {
      const { status, data } = await axios.delete(
        `${process.env.VUE_APP_API_BOOKAPP}calendars/${idEvent}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        // this.events = data;
        this.eventDetail.remove();
        this.flag = true;
        this.closeModalEvent();
        this.alertShow = true;
        this.textAlert = "El evento se borro correctamente.";
        this.colorAlert = "danger";
        window.scrollTo(0, 0);
        this.removeAlert();
        // console.log(this.events);
      }
    },
    async saveItemCalendar(send) {
      send.type_calendar_id = 2;
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}calendars`,
        send,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.cancelSaveCalendar();
        this.alertShow = true;
        this.textAlert = "El evento se creo correctamente.";
        this.colorAlert = "success";
        window.scrollTo(0, 0);
        let typeActivity = this.typeActivities.find(
          (tA) => tA.id == data.type_activity_id
        );
        this.removeAlert();
        this.setEvent({
          // ...data,
          created_by: data.created_by,
          image: data.image,
          link: data.link,
          type_activity: data.type_activity,
          id: data.id,
          title: data.title,
          start: data.date_start,
          end: data.date_end,
          backgroundColor: typeActivity.color,
        });
        this.calendar.addEvent({
          created_by: data.created_by,
          image: data.image,
          link: data.link,
          type_activity: data.type_activity,
          id: data.id,
          title: data.title,
          start: data.date_start,
          end: data.date_end,
          backgroundColor: typeActivity.color,
        });
      } else {
        this.alertShow = true;
        this.textAlert =
          "Hubo un error al crear el evento, intente nuevamente.";
        this.colorAlert = "danger";
        window.scrollTo(0, 0);
      }
      this.removeAlert();
    },
  },
};
</script>
