<template>
  <div class="card">
    <div
      class="
        py-3
        card-header
        d-flex
        align-items-center
        border-bottom
        col-auto
        my-auto
      "
    >
      <div class="h-100">
        <h6 class="mb-1">Información detallada del usuario</h6>
      </div>
    </div>
    <div class="py-3 ml-4 card-body">
      <div class="mb-1">
        <div v-if="user" class="row">
          <div class="col-sm-12 col-md-6">
            <label>Tipo de documento</label>
            <p v-if=" user.type_document">{{ user.type_document.name || '---' }}</p>
            <label>Código</label>
            <p>{{ user.code }}</p>
            <label>Eps</label>
            <p v-if="user.eps">{{ user.eps.name }}</p>
            <template v-else><p>---</p></template>            
            <label>Correo</label>
            <p>{{ user.email }}</p>
            <label>Dirección</label>
            <p>{{ user.address || "---" }}</p>            
            <label>Tipo de Sangre</label>
            <p v-if="user.blood">{{ user.blood.name}}</p>
            <template v-else><p>---</p></template>   
            <label>Saldo de Restaurante</label>
            <p>{{"$" + user.refillsTotal || 'No aplica'}}</p>        
          </div>
          <div class="col-sm-12 col-md-6">
            <label>Documento</label>
            <p>{{ user.document }}</p>
            <label>Fecha de nacimiento</label>
            <p>{{ user.birth_date }}</p>
            <label>Télefono</label>
            <p>{{ user.phone || "---" }}</p>
            <label>Género</label>
            <p v-if=" user.gender">{{ user.gender.name }}</p>
            <label>Enfermedades</label>
            <p>{{ user.diseases_interest || "---" }}</p>
            <label>Estado</label>
            <p>{{ user.status=="active"?"Activo":"Inactivo"}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bruce from "@/assets/img/bruce-mars.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team4 from "@/assets/img/team-4.jpg";
import team5 from "@/assets/img/team-5.jpg";

export default {
  name: "UserDetailStudent",
  props: {
    user: { type: Object, require: true },
  },
  data() {
    return {
      bruce,
      team1,
      team2,
      team4,
      team5,
    };
  },
  methods:{
    preguntarCambiarEstado(userId) {
      this.$swal({
        title: "¿Esta seguro de cambiar el estado de este usuario?",
        text: "Al cambiar el estado del usuario ya no se vera en este listado",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cambiar estado!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cambiarEstado(userId);
        } else {
          this.$swal.dismiss;
        }
      });
    },
    async cambiarEstado(userId) {
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/change-status/${userId}`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.$swal({
          title: "Exito!",
          text: "El estado del usuario se cambio.",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        // let user = this.userList.filter(u => userId == u.id );
        // console.log(user);
        // user.status = user.status == 'active' ? 'inactive' : 'active';
        this.$emit("getData");
      } else {
        this.$swal({
          title: "Algo salió mal!",
          text:
            "Hubo un error al cambiar el estado del usuario, por favor intente de nuevo.",
          icon: "danger",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
