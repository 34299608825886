import { mapState } from "vuex";
export default {
  data: () => ({
    listPermissions: localStorage.getItem("permissions") != null ? localStorage.getItem("permissions").split(',') : [],
  }),
  methods: {
    hasPermission(permission) {
      let result = this.listPermissions.indexOf(permission);
      return result != -1 ? true : false;
    },
  },
};
