<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="
                text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
                align-middle
                text-center text-sm
              "
            >
              Rol
            </th>
            <th
              class="
                align-middle
                text-center text-sm text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
                ps-2
              "
            >
              Guard
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="roles">
            <tr
              @click="viewRole(rol.id)"
              style="cursor: pointer"
              v-for="rol in roles"
              :key="rol.id"
            >
              <td>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm align-middle text-center text-sm">
                    {{ rol.name }}
                  </h6>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-secondary mb-0 text-sm">{{ rol.guard_name }}</p>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";

import router from "@/router/index.js";

export default {
  name: "Table",
  components: {
    VsudAvatar,
  },
  props: {
    roles: { type: Object, required: true },
  },
  methods: {
    viewRole(id) {
      router.push({
        path: `/roles-permisos/roles/detalle/${id}`,
        replace: true,
      });
    },
  },
  data() {
    return {
      imgDefault,
      
    };
  },
  mounted() {
    
  },
};
</script>
