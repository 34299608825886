<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >Nombre completo</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >Documento de identidad</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tarifa</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Saldo</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="listItems">
            <tr style="cursor: pointer" v-for="item in listItems" :key="item.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <vsud-avatar
                    :img="[item.user.photo ? item.user.photo : imgDefault]"
                    class="me-3"
                    alt="avatar image"
                    border-radius="lg"
                  />
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.user.name +' '+item.user.lastname }}</h6>
                  </div>
                </div>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm">{{ item.user.document }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm">{{ item.user_rate }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm">{{ item.total }}</p>
              </td>
              <td class="text-sm">
                <div v-if="!item.user.isOrdered">
                  <button
                    type="button"
                    class="btn bg-gradient-success"
                    @click="confirmOrder(item)"
                  >Descargar</button>
                </div>
                <div v-else>
                  <span style="color:red" class="text-sm">Ya descargo</span>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script>
import imgDefault from "@/assets/img/user-default.png";
import VsudAvatar from "@/components/VsudAvatar.vue";
import { mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  name: "StudentCard",
  props: {
    listItems: Array,
    date: String,
  },
  components: { VsudAvatar },
  data() {
    return {
      imgDefault,
    };
  },
  methods: {
      ...mapMutations([
      "displayAlertMessage",
      "closeAlertMessage",
      "setRateUser",
    ]),
    confirmOrder(order) {
      this.$swal({
        title: "¿Esta seguro de descontar este almuerzo?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, descontar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const user = JSON.parse(localStorage.getItem("user"));
          let dataSend = {
            description: `Descuento de almuerzo realizado por ${user.name} ${user.lastname} para ${order.user.name} ${order.user.lastname}`,
            price: order.user_rate,
            seller_id: user.id,
            user_id: order.user.id,
            refillsTotal: order.refillsTotal,
            ordersTotal: order.ordersTotal,
            user_rate: order.user_rate,
            transaction_date: this.date
          };
          console.log(dataSend);
          this.saveOrder(dataSend);
          return true;
        } else {
          this.$swal.dismiss;
          return false;
        }
      });
    },
    async saveOrder(order) {
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}orders`,
        order,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El almuerzo fue descontado con exito.",
          colorAlert: "success",
        });
        let userIem = this.listItems.find(lI => lI.user.id == order.user_id);
        userIem.user.isOrdered = true;
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El almuerzo no pudo ser descontado.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
    },
  },
  mounted() {
    // this.getUsersByRoleAttendant();
  },
  computed: {},
};
</script>
  <style scoped>
.option-search {
  border-style: solid;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.option-search:hover {
  background-color: #f36c17;
  cursor: pointer;
}
</style>
  