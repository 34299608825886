<template>
  <div>
    <div v-if="OpenClose" class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true"
      role="dialog" style="display:block">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Ajustar horario</h5>
          </div>
          <div class="modal-body">
            <div class="row mt-3">
              <div class="col-12">
                <label for>Asignatura:</label>
                <multiselect v-model="schedule.subjectsAsing" track-by="name" label="name"
                  placeholder="Seleccione las asignaturas del curso" :multiple="false" :options="subjects"
                  selectLabel="Seleccione una opción" deselectLabel="Presione para remover" selectedLabel="Seleccionado"
                  :showNoOptions="false"></multiselect>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <label for>Profesor que dicta:</label>
                <multiselect v-model="schedule.teacher" track-by="fullName" label="fullName"
                  placeholder="Seleccione el docente para la asignatura" :multiple="false" :options="teachers"
                  selectLabel="Seleccione una opción" deselectLabel="Presione para remover" selectedLabel="Seleccionado"
                  :showNoOptions="false"></multiselect>
              </div>
            </div>
            <div v-if="!closeFormDays" class="row mt-3">
              <div class="col-12 d-flex">
                <label class="my-auto" for>Número de horas a la semana:</label>
                <input class="form-control mx-2" type="number" min="1" max="10" style="width: 35%;"
                  v-model="weeklyHours" />
                <vsud-button type="button" color="success" variant="gradient" @click="aceptedDaysNumber()"
                  class="ms-auto">Aceptar</vsud-button>
              </div>
            </div>
            <div v-if="closeFormDays">
              <div class="row mt-3">
                <div class="col-4">
                  <label for>Dias</label>
                </div>
                <div class="col-4">
                  <label for>Hora inicio</label>
                </div>
                <div class="col-4">
                  <label for>Hora fin</label>
                </div>
              </div>
              <div v-for="n in weeklyHours" v-bind:key="n" class="row mt-3">
                <div class="col-4">
                  <multiselect v-model="schedule.days[n]" track-by="name" label="name" placeholder="-----"
                    selectLabel="Seleccione un día" deselectLabel="Presione para remover" selectedLabel="Seleccionado"
                    :multiple="false" :options="days"></multiselect>
                </div>
                <div class="col-4">
                  <input v-model="schedule.startTime[n]" class="multisteps-form__input form-control" type="time" />
                </div>
                <div class="col-4">
                  <input v-model="schedule.endTime[n]" class="multisteps-form__input form-control" type="time" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
            <div class="alert alert-danger col-md-8">
              <div v-for="error in arrayErrors" :key="error">
                <b>{{ error }}</b>
              </div>
            </div>
          </div>
          <div v-if="validationsErrors.length > 0" class="row justify-content-center text-center">
            <div class="alert alert-danger col-md-8">
              <div v-for="error in validationsErrors" :key="error">
                <b>{{ error }}</b>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <vsud-button type="button" color="danger" variant="contained" class="mr-auto"
              @click="closeModal()">Cancelar</vsud-button>
            <vsud-button type="button" color="dark" variant="gradient" class="ms-auto"
              @click="saveSchedule()">Guardar</vsud-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    VsudButton,
  },
  name: "ScheduleModal",
  props: {
    visible: Boolean,
    subjects: Array,
    teachers: Array,
    errors: Array,
  },
  data() {
    return {
      OpenClose: this.visible,
      schedule: {
        days: [],
        startTime: [],
        endTime: [],
        subjectsAsing: [],
        teacher: [],
      },
      weeklyHours: 1,
      closeFormDays: false,
      days: [
        {
          id: 1,
          name: "Lunes",
        },
        {
          id: 2,
          name: "Martes",
        },
        {
          id: 3,
          name: "Miercoles",
        },
        {
          id: 4,
          name: "Jueves",
        },
        {
          id: 5,
          name: "Viernes",
        },
      ],
      arrayErrors: this.errors,
    };
  },
  methods: {
    ...mapMutations(["emptyValidationErrors"]),
    closeModal() {
      this.$emit("cancelSaveSubject");
    },
    aceptedDaysNumber() {
      if (this.weeklyHour <= 0) {
        alert("La asignatura debe tener al menos una hora semanal");
      } else {
        this.closeFormDays = true;
      }
    },
    saveSchedule() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      const itemSchedule = this.formatSchedule();
      console.log(itemSchedule);
      this.$emit("saveItemSchedule", itemSchedule);
    },
    formatSchedule() {
      let scheduleFormat = {
        subject: this.schedule.subjectsAsing,
        teacher: this.schedule.teacher ? this.schedule.teacher : null,
        days: [],
      };
      const daysRaw = this.schedule.days.slice(1);
      const startTimeRaw = this.schedule.startTime.slice(1);
      const endTimeRaw = this.schedule.endTime.slice(1);
      const count = daysRaw.length;

      for (let index = 0; index < count; index++) {
        let objSchedule = {
          day: daysRaw[index],
          start: startTimeRaw[index] + ":00",
          end: endTimeRaw[index] + ":00",
          subject: this.schedule.subjectsAsing,
          teacher: this.schedule.teacher ? this.schedule.teacher : null,
        };
        scheduleFormat.days.push(objSchedule);
      }
      return scheduleFormat;
    },
    validateForm() {
      this.arrayErrors = [];
      if (this.schedule.subjectsAsing.length === 0) {
        this.arrayErrors.push("Debe asignar una asignatura");
      } else {
        if (this.schedule.subjectsAsing.subject_type == "regular_class") {
          if (this.schedule.teacher.length === 0) {
            this.arrayErrors.push("Debe asignar un profesor");
          }
        }
      }

      if (this.weeklyHours <= 0) {
        this.arrayErrors.push("Debe tener al menos una hora semanal");
      } else {
        for (let index = 0; index < this.weeklyHours; index++) {
          if (!this.schedule.days[index + 1]) {
            this.arrayErrors.push(
              `Por favor, verifique que todas las horas tenga dias asignados`
            );
            return 0;
          }
          if (!this.schedule.startTime[index + 1]) {
            this.arrayErrors.push(
              `Por favor, verifique que todas los días tengan una hora de inicio`
            );
            return 0;
          }
          if (!this.schedule.endTime[index + 1]) {
            this.arrayErrors.push(
              `Por favor, verifique que todas los días tengan una hora de fin`
            );
            return 0;
          }
          if (
            !this.validarHoras(
              this.schedule.startTime[index + 1],
              this.schedule.endTime[index + 1]
            )
          ) {
            this.arrayErrors.push(
              `Hay un error de congruencia en las siguientes horas asigandas ${this.schedule.startTime[index + 1]
              } - ${this.schedule.endTime[index + 1]}`
            );
            return 0;
          }
        }
      }
    },
    validarHoras(hourStart, hourEnd) {
      const [hourStartNum, minStartNum] = hourStart.split(":").map(Number);
      const [hourEndNum, minEndNum] = hourEnd.split(":").map(Number);

      const minutosInicio = hourStartNum * 60 + minStartNum;
      const minutosFin = hourEndNum * 60 + minEndNum;

      if (minutosInicio >= minutosFin) {
        return false;
      }

      return true;
    },
    resetForm() {
      this.schedule = {
        days: [],
        startTime: [],
        endTime: [],
        subjectsAsing: [],
        teacher: [],
      };
      this.weeklyHours = 0;
      this.closeFormDays = false;
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
    },
  },
  computed: {
    ...mapState(["validationsErrors"]),
  },
  mounted() {
    this.emptyValidationErrors();
  },
};
</script>