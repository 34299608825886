<template>
  <div>
    
    <div class="card">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
             <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >ID</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Nombre completo</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Documento de identificación</th>
              <th
                v-if="student"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Curso</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Rol</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Email</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Teléfono</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Fecha nacimiento</th>
              <th
                v-if="typeList!= 'Estudiante'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Ultimo inicio de sesion</th>
              <th
                v-if="typeList!= 'Estudiante'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Ultimo cierre de sesion</th>
              <th
                v-show="hasPermission('users.students.delete')"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Estado</th>
            </tr>
          </thead>
          <!-- <tbody>
            <template v-if="userList">
              <tr style="cursor: pointer" v-for="user in userList" :key="user.id"> -->
                <tbody>
            <template v-if="userList && userList.length > 0">
              <tr style="cursor: pointer" v-for="user in userList" :key="user.id">
               <td class="align-middle text-center" @click="viewProfile(user.id)">
                  <span class="text-secondary text-sm">{{ user.id }}</span>
                </td>
                <td @click="viewProfile(user.id)">
                  <div class="d-flex px-2 py-1">
                    <vsud-avatar
                      :img="[user.photo ? user.photo : imgDefault]"
                      class="me-3"
                      alt="avatar image"
                      border-radius="lg"
                    />
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ user.name + " " + user.lastname }}</h6>
                    </div>
                  </div>
                </td>
                <td @click="viewProfile(user.id)">
                  <p class="text-sm text-secondary mb-0">{{ user.document }}</p>
                </td>
                <td v-if="student" @click="viewProfile(user.id)">
                  <p class="text-sm text-secondary mb-0">{{user.inscription.course.name}}</p>
                </td>
                <td @click="viewProfile(user.id)">
                  <span class="badge badge-dot me-4">
                    <i class="bg-info"></i>
                    <span class="text-dark text-xs">{{ user.roles[0].name }}</span>
                  </span>
                </td>
                <td class="align-middle text-center text-sm" @click="viewProfile(user.id)">
                  <p class="text-secondary mb-0 text-sm">{{ user.email }}</p>
                </td>
                <td class="align-middle text-center" @click="viewProfile(user.id)">
                  <span class="text-secondary text-sm">{{ user.phone }}</span>
                </td>
                <td class="align-middle text-center" @click="viewProfile(user.id)">
                  <span class="text-secondary text-sm">{{ user.birth_date }}</span>
                </td>
                <td
                  v-if="typeList!= 'Estudiante'"
                  class="align-middle text-center"
                  @click="viewProfile(user.id)"
                >
                  <span class="text-secondary text-sm">{{ user.last_login }}</span>
                </td>
                <td
                  v-if="typeList!= 'Estudiante'"
                  class="align-middle text-center"
                  @click="viewProfile(user.id)"
                >
                  <span class="text-secondary text-sm">{{ user.last_logout }}</span>
                </td>
                <td class="align-middle text-center">
                  <div
                    v-if="user.status"
                    v-show="hasPermission('users.students.delete')"
                    @click="preguntarCambiarEstado(user.id)"
                    class="text-secondary text-sm"
                  >
                    <font-awesome-icon
                      class="text-center bg-white cursos icon icon-shape icon-sm border-radius-md align-items-center justify-content-center me-2"
                      :icon="user.status == 'active' ? ['fas', 'toggle-on'] : ['fas', 'toggle-off']"
                      :style="{ color: user.status == 'active' ? 'green' : 'dimgray' }"
                    />
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="6">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">No hay registros</h6>
                    </div>
              <!-- </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="6">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">No hay registros</h6>
                    </div> -->
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-group mt-2" v-if="flag">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" v-if="pagination.current_page > 1">
            <a
              class="page-link"
              @click="$parent.changePage(pagination.current_page - 1)"
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            class="page-item"
            :class="[page == pagination.current_page ? 'active' : '']"
            v-for="page in pagesNumber"
            :key="page"
          >
            <a class="page-link" @click="$parent.changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" v-if="pagination.current_page < pagination.last_page">
            <a
              class="page-link"
              @click="$parent.changePage(pagination.current_page + 1)"
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";
import axios from "axios";
import router from "@/router/index.js";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "Table",
  components: {
    VsudAvatar,
  },
  mixins: [permissionsMixin],

  props: {
    userList: { type: Object, required: true },
    pagination: { type: Object, required: true },
    student: { type: Boolean, required: true, default: false },
    typeList: { type: String, required: false, default: "Otro" },
    active: { type: Boolean, required: true, default: true },
  },
  watch: {
    pagination(newValue, oldValue) {
      this.flag = true;
    },
    active(newValue, oldValue) {
      // Cuando la propiedad 'active' cambia, se vuelve a cargar los datos
      if (newValue) {
        this.getData();
      }
    },
  },
  computed: {
    pagesNumber() {
      if (!this.pagination.to) {
        return [];
      }

      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }

      var to = from + this.offset * 2;
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }

      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
  },
  methods: {
    viewProfile(id) {
      router.push({
        path: `/ver/usuario/${id}`,
        replace: true,
      });
    },
    toggleActive() {
      this.active = !this.active;
      this.getData(); // Llama a getData al cambiar entre activo e inactivo
    },
    cambiarPagina() {},
    async getData() {
      console.log("hiii");
      return 0;
    try {
      // Establece la URL según si quieres cargar usuarios activos o inactivos
      const apiUrl = this.active
        ? `${process.env.VUE_APP_API_BOOKAPP}users/active`
        : `${process.env.VUE_APP_API_BOOKAPP}users/inactive`;

      const { status, data } = await axios.get(apiUrl, {
        headers: {
          Authorization: localStorage.getItem("token_bookapp"),
          conexion: localStorage.getItem("conexion"),
        },
      });

      // Asigna los datos recibidos a la propiedad userList
      this.userList = data;

      if (data.pagination) {
      this.pagination = data.pagination;
    }

      this.flag = true;
    } catch (error) {
      console.error("Error al obtener datos:", error);
      // Maneja el error de la manera que prefieras
      // Puedes mostrar un mensaje de error, registrar en consola, etc.
    }
  },

    preguntarCambiarEstado(userId) {
      this.$swal({
        title: "¿Esta seguro de cambiar el estado de este usuario?",
        text: "Al cambiar el estado del usuario ya no se vera en este listado",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cambiar estado!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cambiarEstado(userId);
        } else {
          this.$swal.dismiss;
        }
      });
    },
    async cambiarEstado(userId) {
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/change-status/${userId}`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.$swal({
          title: "Exito!",
          text: "El estado del usuario se cambio.",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        // let user = this.userList.filter(u => userId == u.id );
        // console.log(user);
        // user.status = user.status == 'active' ? 'inactive' : 'active';
        this.$emit("getData");
      } else {
        this.$swal({
          title: "Algo salió mal!",
          text:
            "Hubo un error al cambiar el estado del usuario, por favor intente de nuevo.",
          icon: "danger",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      }
    },
  },
  data() {
    return {
      flag: false,
      imgDefault,
      activeMode: true,
      offset: 3,
      active: true,
    };
  },
  mounted() {
    this.getData();
  },
};
</script>
<style>
.form-check {
  display: inline-block;
  cursor: pointer;
}

.form-check-input {
  margin-right: 8px;
  position: relative;
  flex-shrink: 0;
  width: 1.5em;
  height: 0.75em;
  border-radius: 0.75em;
  transition: 0.2s;
  background-color: #fdffff;
  border: 1px solid #000;
}

.form-check-input:checked {
  background-color: #00a878;
}

.form-check-input:checked:before {
  transform: translateX(0.75em);
}
</style>
