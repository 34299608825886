<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-6 mx-auto">
        <div class="card mb-4">
          <div class="card-body p-3 pt-0 pb-0">
            <div class="row mt-5">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="d-flex">
                  <div>
                    <vsud-avatar :img="[subject.image ? subject.image : imgDefault]" class="me-3" size="xxl"
                      border-radius="lg" alt="product image" />
                  </div>
                  <div>
                    <h6 class="text-lg mb-0 mt-2">{{ subject.name }}</h6>
                    <p class="text-sm mb-3">{{ subject.status == 'active' ? 'activo' : 'inactivo' }}</p>
                    <p class="text-sm mb-3"><b>{{ subject.subject_type == 'regular_class' ? 'clase regular' : 'extra clase'
                    }}</b></p>
                  </div>
                </div>
              </div>
              <div class="col-6 my-auto text-end">
                <!-- <vsud-button @click="editSubject(subject.id)" color="info" variant="gradient" class="mb-0">Editar
                  asignatura</vsud-button> -->
                  <div style="cursor: pointer"  v-show="hasPermission('users.subjects.update')" class="nav-wrapper position-relative end-0">
              <font-awesome-icon :icon="['fass', 'pen-to-square']" />
              <a @click="editSubject(subject.id)"> editar </a>
            </div>
                </div>
            </div>
            <hr class="horizontal dark mt-4 mb-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgDefault from "@/assets/img/book.png";
import VsudButton from "@/components/VsudButton.vue";
import VsudAvatar from "@/components/VsudAvatar.vue";
// import VsudBadge from "@/components/VsudBadge.vue";
import axios from "axios";
import router from "@/router/index.js";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "RoleDetail",
  components: {
    VsudButton,
    VsudAvatar,
  },
  mixins: [permissionsMixin],

  data() {
    return { imgDefault,subject: {}, permissionsArray: [], flag: false };
  },
  methods: {
    editSubject(id) {
      router.push({
        path: `/materias/editar/${id}`,
        replace: true,
      });
    },
    async getSubject(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}subjects/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.subject = data;
      }
    },
  },
  mounted() {
    this.getSubject(this.$route.params.id);  
  },
};
</script>
