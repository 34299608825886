import { createRouter, createWebHashHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import NewMessage from "../views/pages/messages/NewMessage.vue";
import ListMessagesReceive from "../views/pages/messages/ListMessagesReceive.vue";
import ListMessagesSend from "../views/pages/messages/ListMessagesSend.vue";
import ListMessagesGenerals from "../views/pages/messages/ListMessagesGenerals.vue";
import ListMessagesSeen from "../views/pages/messages/ListMessagesSeen.vue";
import ListGroupMessages from "../views/pages/messages/ListGroupMessages.vue";
import ListMessagesApprove from "../views/pages/messages/ListMessagesApprove.vue";
import MessageDetail from "../views/pages/messages/MessageDetail.vue";
import MessageDetailGeneral from "../views/pages/messages/MessageDetailGeneral.vue";
import MessageDetailApprove from "../views/pages/messages/MessageDetailApprove.vue";
import CourseList from "../views/pages/courses/CourseList.vue";
import NewCourse from "../views/pages/courses/NewCourse.vue";
import CourseDetail from "../views/pages/courses/CourseDetail.vue";
import EditCourse from "../views/pages/courses/EditCourse.vue";
import CalendarCourse from "../views/pages/courses/CalendarCourse.vue";
import ScheduleCourse from "../views/pages/courses/ScheduleCourse.vue";
import CallAttentionsSend from "../views/pages/callForAttention/CallAttentionsSend.vue";
import CallAttentionsCreate from "../views/pages/callForAttention/CallAttentionsCreate.vue";
import CallAttentionsApprove from "../views/pages/callForAttention/CallAttentionsApprove.vue";
import CallAttentionDetail from "../views/pages/callForAttention/CallAttentionDetail.vue";
import CallAttentionDetailApprove from "../views/pages/callForAttention/CallAttentionDetailApprove.vue";

import FoodCalendar from "../views/pages/calendars/FoodCalendarForm.vue";
import GeneralCalendar from "../views/pages/calendars/GeneralCalendarForm.vue";

import SchoolRate from "../views/pages/refills/SchoolRate.vue";
import SpecialsRates from "../views/pages/refills/SpecialsRates.vue";
import Refills from "../views/pages/refills/Refills.vue";

import Restaurant from "../views/pages/lunch/Restaurant.vue";
import Transactions from "../views/pages/lunch/Transactions.vue";

import ViewUser from "../views/pages/Users/ViewUser.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails.vue";
import OrderList from "../views/ecommerce/Orders/OrderList.vue";
import Referral from "../views/ecommerce/Referral.vue";
import AdministrativeList from "../views/pages/Users/AdministrativesList.vue";
import RoleList from "../views/pages/RolesPermissions/RoleList.vue";
import RoleDetail from "../views/pages/RolesPermissions/RoleDetail.vue";
import EditPermission from "../views/pages/RolesPermissions/EditPermission.vue";
import EditRole from "../views/pages/RolesPermissions/EditRole.vue";
import EditSubject from "../views/pages/Subjects/EditSubject.vue";
import SubjectDetail from "../views/pages/Subjects/SubjectDetail.vue";
import PermissionDetail from "../views/pages/RolesPermissions/PermissionDetail.vue";
import PermissionList from "../views/pages/RolesPermissions/PermissionList.vue";
import NewRole from "../views/pages/RolesPermissions/NewRole.vue";
import NewSubject from "../views/pages/Subjects/NewSubject.vue";
import SubjectList from "../views/pages/Subjects/SubjectList.vue";
import NewPermission from "../views/pages/RolesPermissions/NewPermission.vue";
import TeachersList from "../views/pages/Users/TeachersList.vue";
import AttendantsList from "../views/pages/Users/AttendantsList.vue";
import StudentsList from "../views/pages/Users/StudentsList.vue";
import NewAdministrative from "../views/pages/Users/NewAdministrative.vue";
import EditUser from "../views/pages/Users/EditUser.vue";
import EditUserTeacher from "../views/pages/Users/EditUserTeacher.vue";
import EditUserAdministrative from "../views/pages/Users/EditUserAdministrative.vue";
import EditUserAttendant from "../views/pages/Users/EditUserAttendant.vue";
import NewTeacher from "../views/pages/Users/NewTeacher.vue";
import NewAttendant from "../views/pages/Users/NewAttendant.vue";
import NewStudent from "../views/pages/Users/NewStudent.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";
import axios from "axios";
// import { mapMutations } from "vuex";
import store from '../store/index'

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/ver/usuario/:id_usuario",
    name: "Ver usuario",
    component: ViewUser,
  },
  {
    path: "/usuarios/administrativos",
    name: "listado administrativos",
    component: AdministrativeList,
  },
  {
    path: "/usuarios/acudientes",
    name: "listado acudientes",
    component: AttendantsList,
  },
  {
    path: "/usuarios/estudiantes",
    name: "listado estudiantes",
    component: StudentsList,
  },
  {
    path: "/usuarios/profesores",
    name: "listado profesores",
    component: TeachersList,
  },
  {
    path: "/usuarios/administrativos/nuevo",
    name: "Nuevo usuario administrativo",
    component: NewAdministrative,
  },
  {
    path: "/usuarios/editar/:id_usuario",
    name: "Editar usuario",
    component: EditUser,
  },
  {
    path: "/cursos/crear",
    name: "Nuevo curso",
    component: NewCourse,
  },
  {
    path: "/cursos/listar",
    name: "Listado cursos",
    component: CourseList,
  },
  {
    path: "/cursos/ver/:id_curso",
    name: "Detalle cursos",
    component: CourseDetail,
  },
  {
    path: "/cursos/editar/:id_curso",
    name: "Editar cursos",
    component: EditCourse,
  },
  {
    path: "/cursos/horario/:id_curso",
    name: "Horario cursos",
    component: ScheduleCourse,
  },
  {
    path: "/cursos/calendario/:id_curso",
    name: "calendario cursos",
    component: CalendarCourse,
  },
  {
    path: "/calendario/general",
    name: "calendario general",
    component: GeneralCalendar,
  },
  {
    path: "/calendario/alimentacion/",
    name: "calendario alimentacion",
    component: FoodCalendar,
  },
  {
    path: "/usuarios/editar-administrativo/:id_usuario",
    name: "Editar usuario administrativo",
    component: EditUserAdministrative,
  },
  {
    path: "/usuarios/editar-acudiente/:id_usuario",
    name: "Editar usuario acudiente",
    component: EditUserAttendant,
  },
  {
    path: "/usuarios/editar-estudiante/:id_usuario",
    name: "Editar usuario estudiante",
    component: EditUser,
  },
  {
    path: "/usuarios/editar-profesor/:id_usuario",
    name: "Editar usuario profesor",
    component: EditUserTeacher,
  },
  {
    path: "/usuarios/acudientes/nuevo",
    name: "Nuevo usuario acudiente",
    component: NewAttendant,
  },
  {
    path: "/usuarios/estudiantes/nuevo",
    name: "Nuevo usuario estudiante",
    component: NewStudent,
  },
  {
    path: "/usuarios/profesores/nuevo",
    name: "Nuevo usuario profesor",
    component: NewTeacher,
  },
  {
    path: "/roles-permisos/roles/",
    name: "listado roles",
    component: RoleList,
  },
  {
    path: "/roles-permisos/permisos/",
    name: "listado permisos",
    component: PermissionList,
  },
  {
    path: "/roles-permisos/roles/crear",
    name: "Nuevo rol",
    component: NewRole,
  },
  {
    path: "/recargas/tarifa",
    name: "Tarifa general",
    component: SchoolRate,
  },
  {
    path: "/recargas/tarifas-especiales",
    name: "Tarifas especiales",
    component: SpecialsRates,
  },
  {
    path: "/recargas/crear",
    name: "Recarga manual",
    component: Refills,
  },
  {
    path: "/descargas/almuerzos",
    name: "Restaurante almuerzos",
    component: Restaurant,
  },
  {
    path: "/descargas/movimientos",
    name: "Restaurante movimientos",
    component: Transactions,
  },
  {
    path: "/llamados-atencion/enviados",
    name: "Llamados atención enviados",
    component: CallAttentionsSend,
  },
  {
    path: "/llamados-atencion/detalle/:id",
    name: "Llamado atención enviados",
    component: CallAttentionDetail,
  },
  {
    path: "/llamados-atencion/aprobar/:id",
    name: "Llamado atención aprobar",
    component: CallAttentionDetailApprove,
  },
  {
    path: "/llamados-atencion/aprobar",
    name: "Llamados atención aprobar",
    component: CallAttentionsApprove,
  },
  {
    path: "/llamados-atencion/crear",
    name: "Llamados atención crear",
    component: CallAttentionsCreate,
  },
  {
    path: "/roles-permisos/permisos/crear",
    name: "Nuevo permiso",
    component: NewPermission,
  },
  {
    path: "/roles-permisos/roles/detalle/:id",
    name: "Ver rol",
    component: RoleDetail,
  },
  {
    path: "/roles-permisos/permisos/detalle/:id",
    name: "Ver permiso",
    component: PermissionDetail,
  },
  {
    path: "/roles-permisos/permisos/editar/:id",
    name: "Editar permiso",
    component: EditPermission,
  },
  {
    path: "/roles-permisos/roles/editar/:id",
    name: "Editar rol",
    component: EditRole,
  },
  {
    path: "/materias",
    name: "Listado Asignaturas",
    component: SubjectList,
  },
  {
    path: "/materias/crear",
    name: "Nueva Asignaturas",
    component: NewSubject,
  },
  {
    path: "/materias/editar/:id",
    name: "Editar Asignaturas",
    component: EditSubject,
  },
  {
    path: "/materias/ver/:id",
    name: "Ver Asignaturas",
    component: SubjectDetail,
  },
  {
    path: "/mensajes/crear",
    name: "Nuevo mensaje",
    component: NewMessage,
  },
  {
    path: "/mensajes/listar/recibidos",
    name: "Listar mensajes recibidos",
    component: ListMessagesReceive,
  },
  {
    path: "/mensajes/listar/enviados",
    name: "Listar mensajes enviados",
    component: ListMessagesSend,
  },
  {
    path: "/mensajes/listar/generales",
    name: "Listar mensajes generales",
    component: ListMessagesGenerals,
  },
  {
    path: "/mensajes/listar/no-leidos",
    name: "Listar mensajes no leidos",
    component: ListMessagesSeen,
  },
  {
    path: "/mensajes/listar/grupos",
    name: "Listar mensajes grupos",
    component: ListGroupMessages,
  },
  {
    path: "/mensajes/listar-aprobar",
    name: "Listar mensajes aprobar",
    component: ListMessagesApprove,
  },
  {
    path: "/mensajes/mensajes/:ideMensaje",
    name: "Ver mensajes",
    component: MessageDetail,
  },
  {
    path: "/mensajes/general/:ideMensaje",
    name: "Ver mensajes generales",
    component: MessageDetailGeneral,
  },
  {
    path: "/mensajes/mensajes-aprobar/:ideMensaje",
    name: "Ver mensajes aprobar",
    component: MessageDetailApprove,
  },
  {
    path: "/panel",
    name: "Default",
    component: Default,
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive,
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome,
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault,
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo,
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM,
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Projects,
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject,
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList,
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails,
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/login",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration,
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic,
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover,
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration,
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic,
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover,
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration,
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration,
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});


router.beforeEach(async (to, from, next) => {
  closeOrOpenSideNav();
  const flag = await verifyUserLoged();
  let web = ["Signin Cover"];
  if (web.includes(to.name)) {
    if (flag) {
      router.push({
        name: 'Default',
      });
    } else {
      next();
    }
  } else {
    if (flag) {
      next();
    } else {
      router.push({
        name: 'Signin Cover',
      });
    }
  }
});


const verifyUserLoged = async () => {
  if (!localStorage.getItem("token_bookapp") && !localStorage.getItem("conexion")) {
    return false;
  } else {
    return true;
  }
}

const verifyScreenWidth = () => {
  const telefonoAnchoMaximo = 1201;
  return window.innerWidth <= telefonoAnchoMaximo;
};

const closeOrOpenSideNav = () => {
  if (verifyScreenWidth()) {
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.add("g-sidenav-hidden");
    sidenav_show.classList.remove("g-sidenav-pinned");
  }
};



export default router;
