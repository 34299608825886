<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="d-flex row flex-row">
            <!-- <div class="d-flex "> -->
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Buscar por asunto, mensaje, fecha, destinatario"
                v-model="valueSearch"
                @keyup.enter="getMessages"
              />
            </div>
            <div class="col-sm-3">
              <button
                type="button"
                class="btn bg-gradient-dark null null"
                @click="getMessages()"
                style="margin-left: 10px"
              >Buscar</button>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <!-- <h1>Enviados</h1> -->
        <Table :messages="messages" :pagination="pagination"></Table>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./components/TableMessagesGenerals.vue";
import axios from "axios";

export default {
  name: "ListMessages",
  components: {
    Table,
  },
  data() {
    return {
      messages: [],
      listType: "RECEIVE",
      isDisabled: false,
      pagination: null,
      valueSearch: null,
    };
  },
  watch: {
    listType(newValue, oldValue) {
      this.getMessages(newValue);
    },
    valueSearch(newVal) {
      if (newVal == "") {
        this.valueSearch = null;
        this.getMessages();
      }
    },
  },
  methods: {
    async getMessages(page = 1) {
      this.isDisabled = true;
      const { status, data: dataA } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}messages/${this.valueSearch}?page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.messages = dataA.data;
        const { data, meta, ...resto } = dataA;
        this.pagination = meta;

        // this.messages = data;
        let rec = "";
        this.messages.map((m) => {
          m.participants = m.participants.slice(0, 40);
          const div = document.createElement("div");
          div.innerHTML = m.message;
          m.message_no_html = div.textContent || div.innerText || "";
          m.message_no_html = m.message_no_html.slice(0, 50);
          if (m.status_message == "published") {
            m.statuscolor = "green-text";
            m.status_message = "Publicado";
          }
          if (m.status_message == "pending") {
            m.statuscolor = "blue-text";
            m.status_message = "Pendiente";
          }
          if (m.status_message == "rejected") {
            m.statuscolor = "orange-text";
            m.status_message = "Rechazado";
          }
          if (m.status_message == "removed") {
            m.statuscolor = "red-text";
          }     
          m.type_message_value = m.type_message.name;
          m.privacity = m.privacity == 'private'?'privado':'publico';
        });
        // this.messages.reverse();
      } else {
        console.log("Hubo un error coonsultando los tipos de mensaje");
      }
      this.isDisabled = false;
    },
    async changePage(page) {
      this.getMessages(page);
    },
  },
  mounted() {
    this.getMessages(1);
  },
};
</script>
