/**
=========================================================
* Vue Soft UI Dashboard PRO - v3.0.0
=========================================================
* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/styles.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import {initializeApp} from 'firebase/app'
import 'firebase/messaging'

// import axios from 'axios'
import Multiselect from "vue-multiselect";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import VueExcelXlsx from "vue-excel-xlsx";
// import Vue from "vue";

// import VueQrcodeReader from "vue-qrcode-reader";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHandHoldingDollar, faFileExcel, faChartLine, faUtensils, faMoneyBill, faList, faQrcode, faKeyboard, faUserSecret, faUsers, faEnvelope, faBook, faLock, faRightToBracket, faUserGroup, faUsersGear, faChild, faUserTie, faPenToSquare, faTrash, faCalendarDays, faGraduationCap, faChalkboardUser, faToggleOn, faToggleOff, faTriangleExclamation, faUserGraduate, faUserGear } from '@fortawesome/free-solid-svg-icons'
library.add(faHandHoldingDollar, faFileExcel, faChartLine, faUtensils, faMoneyBill, faList, faQrcode, faKeyboard, faUserSecret, faUsers, faEnvelope, faBook, faLock, faRightToBracket, faUserGroup, faUsersGear, faChild, faUserTie, faPenToSquare, faTrash, faCalendarDays, faGraduationCap, faChalkboardUser, faToggleOn, faToggleOff, faTriangleExclamation, faUserGraduate, faUserGear)
import './registerServiceWorker'
// import firebaseMessaging from './firebase'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    databaseURL: process.env.VUE_APP_databaseURL,
    projectId: process.env.VUE_APP_projectId,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId,
    measurementId: process.env.VUE_APP_measurementId,
    fcmPublicVapidKey: process.env.VUE_APP_fcmPublicVapidKey,
    urlNotification: process.env.VUE_APP_urlNotification,
    serverkey: process.env.VUE_APP_serverkey,
};


const appInstance = createApp(App);
// appInstance.config.globalProperties.$firebase = firebaseMessaging
const firebase = initializeApp(firebaseConfig)
appInstance.config.globalProperties.$firebase = firebase
appInstance.use(firebase.messaging)
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(VueExcelXlsx);
appInstance.component('multiselect', Multiselect);
appInstance.use(SoftUIDashboard);
appInstance.component('multiselect', Multiselect)
appInstance.component('QuillEditor', QuillEditor)
appInstance.component('font-awesome-icon', FontAwesomeIcon);
// appInstance.component(VueQrcodeReader);

appInstance.mount("#app");
