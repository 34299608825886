import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";


export default createStore({
  state: {
    studentCreate: false,
    studentKinship: false,
    studentInscription: false,
    isPinned: true,
    hideConfigButton: false,    
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    dateCalendar: null,
    validationsErrors: [],
    events: [],
    eventDetail: null,
    calendar: null,
    dataCommentMessage: null,
    statusCommentMessage: null,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    rateUser: null,
    specialsRates: [],
    alertShow: false,
    textAlert: null,
    colorAlert: null,
    school: null,
    permissions: [],
    loading:false
  },
  mutations: {
    SET_STUDENT_CREATE(state, payload) {      
      state.studentCreate = payload
    },
    SET_STUDENT_KINSHIP(state, payload) {      
      state.studentKinship = payload
    },
    SET_STUDENT_INSCRIPTION(state, payload) {      
      state.studentInscription = payload
    },
    SET_SIDEBAR_PINNED(state, payload) {      
      state.isPinned = !!payload
    },
    CLOSE_SIDENAV(state,payload){      
      state.isPinned = false;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");      
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    setCommentMessage(state, payload) {
      state.dataCommentMessage = payload;
    },
    setStatusCommentMessage(state, payload) {
      state.statusCommentMessage = payload;
    },
    emptyValidationErrors(state) {
      state.validationsErrors = [];
    },
    dateCalendar(state, payload) {
      state.dateCalendar = payload;
    },
    validationErrors(state, payload) {
      state.validationsErrors.push(payload);
    },
    addEvent(state, payload) {
      state.events.push(payload);
    },
    resetEvents(state, payload) {
      state.events = []
    },
    setEventDetail(state, payload) {
      state.eventDetail = payload;
    },
    saveCalendar(state, payload) {
      state.calendar = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {      
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setRateUser(state, payload) {
      state.rateUser = payload;
    },
    setSpecialsRates(state, payload) {
      state.specialsRates.push(payload)
    },
    emptySpecialsRates(state) {
      state.specialsRates = [];
    },
    displayAlertMessage(state, payload) {
      state.alertShow = payload.alertShow;
      state.textAlert = payload.textAlert;
      state.colorAlert = payload.colorAlert;
    },
    closeAlertMessage(state) {
      setTimeout(() => {
        state.alertShow = false;
        state.textAlert = null;
        state.colorAlert = null;
      }, 3000);
    },
    setSchoolData(state, payload) {
      state.school = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    }
  },
  actions: {
    setSidebarPinned({ commit }, payload) {
      commit("SET_SIDEBAR_PINNED", !!payload)
    },

    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    setDateCalendar({ commit }, payload) {
      commit("dateCalendar", payload);
    },
    setValidationError({ commit }, payload) {
      commit("validationErrors", payload);
    },
    setEvent({ commit }, payload) {
      commit("addEvent", payload);
    },
    toggleSidebarPinned({ commit, state }) {
      commit("SET_SIDEBAR_PINNED", !state.isPinned)
    },
  },
  getters: {
    sidebarPinned(state) { return state.isPinned },
    loading(state) { return state.loading },
  }
});
