<template>
  <div class="card p-3 border-radius-xl bg-white">
    <div class="row mt-3">
      <div class="col-6">
        <label>Estudiantes*</label>
        <multiselect
          v-model="callAttention.receivers"
          :hide-selected="true"
          :internal-search="false"
          :limit="10"
          :options-limit="300"
          track-by="fullName"
          label="fullName"
          placeholder="Seleccione el estudiante"
          selectLabel="Seleccione una opción"
          deselectLabel="Presione para remover"
          selectedLabel="Seleccionado"
          :multiple="true"
          :options="users"
          @search-change="getUsers"
          :loading="isLoading"
          :close-on-select="false"
          :showNoOptions="false"
        ></multiselect>
      </div>
      <div class="col-6">
        <label for>Fecha de la observación*</label>
        <input v-model="callAttention.date" type="date" class="form-control" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <label>Observación*</label>
        <div class="mx-1">
          <QuillEditor style="min-height:200px;" ref="editor" theme="snow" />
        </div>
      </div>
    </div>
    <div class="button-row mt-4">
      <vsud-button
        type="button"
        color="dark"
        variant="gradient"
        class="ms-auto"
        @click="sendForm"
      >Guardar</vsud-button>
    </div>
    <div>
      <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
        <div class="alert alert-danger col-md-8">
          <div v-for="error in arrayErrors" :key="error">
            <b>{{ error }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VsudButton from "@/components/VsudButton.vue";
import { mapMutations } from "vuex";
import router from "@/router/index.js";

export default {
  name: "Table",
  components: {
    VsudButton,
  },
  methods: {
    ...mapMutations(["displayAlertMessage", "closeAlertMessage"]),
    async getUsers(searchValue) {
      if (searchValue == null || !searchValue) {
        return 0;
      }
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/filter-student/${searchValue}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.users = data;
        this.users.map((result) => {
          result.fullName = `${result.name} ${result.lastname} -- n° documento ${result.document}  / ${result.kinship_student[0].attendant.name}  ${result.kinship_student[0].attendant.lastname}`;
        });
      } else {
        console.log("error", data);
      }
    },
    async sendForm() {
      const user = JSON.parse(localStorage.getItem("user"));
      const editor = this.$refs.editor;
      const mensajeTexto = editor.getText();
      const mensajeContenido = editor.getHTML();
      this.callAttention.message_text = mensajeTexto;
      this.callAttention.message = mensajeContenido;
      this.validate();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      this.callAttention.sender_id = user.id;
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}calls-attentions`,
        this.callAttention,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El llamado de atenión fue creado.",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El llamado de atenión no fue creadp.",
          colorAlert: "danger",
        });
      }
      this.callAttention = {
        message: null,
        receivers: [],
        message_text: null,
        date: null,
        sender_id: null,
      };

      this.closeAlertMessage();
      router.push({
        path: "/llamados-atencion/enviados",
      });
    },
    validate() {
      this.arrayErrors = [];
      if (this.callAttention.message_text === "\n") {
        this.arrayErrors.push("Agregue un contenido al llamado de atención");
      }

      if (this.callAttention.receivers.length === 0) {
        this.arrayErrors.push("Asigne un estudiante al llamado de atención");
      }

      if (!this.callAttention.date) {
        this.arrayErrors.push("Asigne una fecha al llamado de atención");
      }
    },
  },
  data() {
    return {
      modalVisible: false,
      specialRateEdit: null,
      arrayErrors: [],
      users: [],
      callAttention: {
        message: null,
        receivers: [],
        message_text: null,
        date: null,
        sender_id: null,
      },
      isLoading: false,
    };
  },
  // computed: {
  //   ...mapState(["specialsRates"]),
  // },
};
</script>
