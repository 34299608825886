<template>
  <div>
    <div class="card">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <!-- <template v-if="columns"> -->
            <tr>
              <th
                v-for="column in columns"
                :key="column.name"
                class="text-uppercase text-xxs font-weight-bolder"
              >{{column.name}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="messages">
              <tr
                :class="{ 'received': isViewed(item), 'seen':!isViewed(item) }"
                @click="viewMessage(item)"
                v-for="item in messages"
                :key="item.id"
                style="cursor: pointer"
              >
                <td v-for="column in columns" :key="column.id"><span :class="column.field == 'status_message'? item['statuscolor']:''">{{ item[column.field] }}</span></td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="6">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">No hay registros</h6>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-group mt-2" v-if="flag">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" v-if="pagination.current_page > 1">
            <a
              class="page-link"
              @click="$parent.changePage(pagination.current_page - 1)"
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            class="page-item"
            :class="[page == pagination.current_page ? 'active' : '']"
            v-for="page in pagesNumber"
            :key="page"
          >
            <a class="page-link" @click="$parent.changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" v-if="pagination.current_page < pagination.last_page">
            <a
              class="page-link"
              @click="$parent.changePage(pagination.current_page + 1)"
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";
import axios from "axios";
import router from "@/router/index.js";

export default {
  name: "Table",
  props: {
    messages: { type: Object, required: true },
    columns: { type: Object, required: true },
    listType: { type: String, required: true },
    pagination: { type: Object, required: true },
  },
  data() {
    return {
      flag: false,
      imgDefault,
      path: "",
      offset: 3,
    };
  },
  watch: {
    pagination(newValue, oldValue) {
      this.flag = true;
    },
  },
  computed: {
    pagesNumber() {
      if (!this.pagination.to) {
        return [];
      }

      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }

      var to = from + this.offset * 2;
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }

      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
    textoSinEtiquetas() {
      // Utiliza una expresión regular para eliminar las etiquetas HTML
      return this.textoConEtiquetas.replace(/<[^>]+>/g, "");
    },
  },
  methods: {
    viewMessage(m) {      
      this.makeRoute(m);
      this.markAsSeen(m);
      router.push({
        path: this.path,
        replace: true,
      });
    },
    makeRoute(m) {
      switch (this.listType) {
        case "send":
          this.path = `/mensajes/mensajes/${m.id_message}`;
          break;
        case "received":
          this.path = `/mensajes/mensajes/${m.id_message}`;
          break;
        case "seen":
          this.path = `/mensajes/mensajes/${m.id_message}`;
          break;
        case "approve":
          this.path = `/mensajes/mensajes-aprobar/${m.id}`;
          break;
      }
    },
    markAsSeen(m) { 
      console.log(m);     
      if (m.status == "Sin leer") {
        this.seenMessage(m);
      }
    },
    async seenMessage(m) {
      const user = JSON.parse(localStorage.getItem("user"));
      const dataSend = {
        message: m.id_message,
        user: user.id,
        student: m.student_id,
        group: m.group_id,
      };
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}messages/viewed`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        console.log("Se marco como visto el que era");
      } else {
        console.log("Hubo un error coonsultando los tipos de mensaje");
      }
    },
    isViewed(message) {
      if (this.listType == "received") {
        return message.status === "Sin leer" ? true : false;
      } else {
        return false;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.pagination > li > a {
  background-color: white;
  color: #f06c30;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #f06c30 !important;
  border: solid 1px #f06c30 !important;
}

.pagination > .active > a:hover {
  background-color: #f06c30 !important;
  border: solid 1px #f06c30;
}

.seen {
  background-color: #f2f6fc;
}
.received {
  color: black;
  font-weight: bold;
}
</style>
