<template>
  <div
    v-if="course"
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Nombre*</label>
          <input
            v-model="course.name"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="11° B"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Cantidad</label>
          <input
            v-model="course.quota"
            class="multisteps-form__input form-control"
            type="number"
            placeholder="40"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label>Asignaturas</label>
          <multiselect
            v-if="subjects"
            v-model="subjectAsing"
            track-by="name"
            label="name"
            placeholder="Seleccione las materias a asignar"
            :multiple="true"
            :options="subjects"
            :close-on-select="false"
            :hide-selected="true"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :showNoOptions="false"
          ></multiselect>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          @click="sendForm"
        >Guardar</vsud-button>
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import { isProxy, toRaw } from "vue";
import axios from "axios";

export default {
  name: "FormEditCourse",
  components: {
    VsudInput,
    Multiselect,
    VsudButton,
  },
  props: {
    errors: {
      type: Array,
      required: false,
    },
    subjects: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      arrayErrors: [],
      course: null,
      subject_type: ["clase regular", "extra clase"],
      status: ["activo", "inactivo"],
      subjectAsing: [],
      newSubjectsAsing: [],
    };
  },
  watch: {
    errors(newValue, oldValue) {
      this.arrayErrors = toRaw(newValue);
    },
    subjects(newValue, oldValue) {
      this.course.relation_course_subject.forEach((subAsig) => {
        let result = this.subjects.find((sub) => sub.id == subAsig.subject.id);
        this.subjectAsing.push(result);
      });      
    },
  },
  methods: {
    sendForm() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      console.log(this.course);
      console.log(this.subjectAsing);
      const dataSend ={
        id:this.course.id,
        name:this.course.name,
        quota:this.course.quota,
        subjects:this.subjectAsing,
      };
      this.$emit("sendForm", dataSend);
    },
    validateForm() {
      this.arrayErrors = [];
      if (!this.course.name) {
        this.arrayErrors.push("El curso debe tener un nombre.");
      }
      if (!this.course.quota) {
        this.arrayErrors.push("El curso debe tener una cantidad de alumnos.");
      }
      if (this.subjectAsing.length === 0) {
        this.arrayErrors.push("El curso debe tener asignaturas asignadas.");
      }
    },
    async getCourse(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}courses/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.course = data;
      }
    },
  },
  mounted() {
    this.getCourse(this.$route.params.id_curso);
  },
};
</script>
<style scoped>
.textArea-fixed {
  resize: none;
}
</style>
