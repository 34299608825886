<template>
  <div>
    <div class="position-sticky z-index-sticky">
      <vsud-alert
        v-show="alertShow"
        class="mt-6 fixed-top mx-6 px-2 text-center"
        :color="colorAlert"
      >{{ textAlert }}</vsud-alert>
    </div>
    <main class="mt-0 main-content main-content-bg">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="mt-8 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h3 class="font-weight-bolder d-flex">
                      <div>Book</div>
                      <div class="orange-text">App</div>
                    </h3>
                    <p class="mb-0">Digite sus credenciales para acceder</p>
                  </div>
                  <div class="card-body">
                    <div role="form" @submit.prevent="login()" class="text-start">
                      <form>
                        <label for>Seleccione el colegio</label>
                        <input
                          class="form-control"
                          type="text"
                          v-model="input"
                          placeholder="Digite el nombre del colegio al que fue asignado..."
                        />
                        <div
                          v-show="input"
                          v-for="schoolList in filteredSchoolList()"
                          :key="schoolList.id"
                        >
                          <a class="dropdown-item" href="#">
                            <input
                              type="radio"
                              id="dewey"
                              name="school"
                              v-model="conexion"
                              :value="schoolList.code"
                            />
                            {{ schoolList.name }}
                          </a>
                        </div>
                        <div class="item error" v-if="input && !filteredSchoolList().length">
                          <p>No se encontraro resultados</p>
                        </div>

                        <label>Correo</label>
                        <input
                          class="form-control"
                          type="email"
                          placeholder="Email"
                          v-model="userLogin.email"
                          id="email"
                          required
                        />
                        <label>Contraseña</label>
                        <input
                          class="form-control"
                          type="password"
                          placeholder="Password"
                          v-model="userLogin.password"
                          id="password"
                          required
                        />

                        <div class="text-center">
                          <vsud-button
                            class="my-4 mb-2"
                            variant="gradient"
                            color="info"
                            full-width
                          >Ingresar</vsud-button>
                        </div>
                        <div class="form-group mt-2 text-center">
                          <a
                            @click="resetPassword()"
                            class="form-control1"
                            type="button"
                            data-toggle="modal"
                            data-target="#exampleModalLong"
                          >Restablecer contraseña</a>

                          <!-- </div> -->
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="top-0 oblique position-absolute h-100 d-md-block d-none login-img">
                  <div
                    class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    :style="{
                    backgroundImage: `url(${bgImg})`,
                    backgroundSize: `59%`,
                    backgroundRepeat: 'no-repeat',
                  }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
  <!-- <app-footer /> -->
</template>

<script>
import bgImg from "@/assets/img/login_image.png";
import VsudAlert from "@/components/VsudAlert.vue";
import VsudButton from "@/components/VsudButton.vue";
import axios from "axios";
import router from "@/router/index.js";
import { mapMutations } from "vuex";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "SigninCover",
  components: {
    VsudAlert,
    VsudButton,
  },
  data() {
    return {
      showModal: false,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      bgImg,
      schoolList: [],
      userLogin: {
        email: null,
        password: null,
      },
      conexion: -1,
      input: null,
      modal: 0,
      errors: [],
    };
  },
  methods: {
    ...mapMutations(["setPermissions"]),
    async login() {
      localStorage.removeItem("token_bookapp");
      localStorage.removeItem("conexion");
      localStorage.removeItem("user");
      localStorage.removeItem("school");
      localStorage.removeItem("permissions");
      localStorage.removeItem("roles");
      this.$store.commit('setLoading',true);
      this.validateInputs();
      if (this.errors.length > 0) {
        this.alertShow = true;
        this.colorAlert = "danger";
        this.textAlert =
          "Por favor, verifique que todos los datos esten correctamente digitados";
        this.closeAlert();
        this.$store.commit('setLoading',false);
        return 0;
      }

      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}login`, this.userLogin, {headers: {conexion: this.conexion,}})
        .then((response) => {
          const data = response.data;
          //console.log(data);
          /* if (data.roles.includes("Acudiente")) {
            this.alertShow = true;
            this.textAlert = "No puede ingresar por la web, por favor inicie sesion desde la app movil";
            this.colorAlert = "info";
            return 0;
          } */
          this.alertShow = true;
          this.textAlert = "Inicio sesión satisfactoriamente";
          this.colorAlert = "success";
          setTimeout(() => {
            localStorage.setItem("permissions", data.permissions);
            localStorage.setItem("token_bookapp", `Bearer ${data.token}`);
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem("roles", JSON.stringify(data.roles));
            localStorage.setItem("conexion", this.conexion);
            
            this.$store.commit('setLoading',false);

            router.push({
              path: "/panel",
              replace: true,
            });
          }, 200);
        })
        .catch((e) => {
          this.alertShow = true;
          this.textAlert = "Error al iniciar sesion";
          this.colorAlert = "danger";
          this.$store.commit('setLoading',false);
        })
        .finally(() => {
          this.$store.commit('setLoading',false);
          this.closeAlert();
        });
    },
    async loginApiManage() {
      const dataSend = {
        email: process.env.VUE_APP_API_SCHOOL_MANAGE_EMAIL,
        password: process.env.VUE_APP_API_SCHOOL_MANAGE_PASS,
      };

      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_SCHOOL_MANAGE}auth/login`,
        dataSend
      );

      if (status == 200) {
        localStorage.setItem("token_manage", `Bearer ${data.accessToken}`);
      } else {
        console.log(`Error ${status}`);
      }
      this.getSchools();
    },
    async getSchools() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_SCHOOL_MANAGE}v1/schools`
      );
      this.schoolList = data.schools;
    },
    filteredSchoolList() {
      if (!this.input) {
        return 0;
      }
      let returnArr = this.schoolList.filter((school) =>
        school.name.toLowerCase().includes(this.input.toLowerCase())
      );
      return returnArr;
    },
    async resetPassword() {
      this.alertShow = false;
      if (this.conexion == -1) {
        this.alertShow = true;
        this.colorAlert = "danger";
        this.textAlert =
          "Por favor, para restablecer la contraseña debe seleccionar el colegio al que fue asignado.";
        return 0;
      }
      if (!this.userLogin.email) {
        this.alertShow = true;
        this.colorAlert = "danger";
        this.textAlert =
          "Para restablecer la contraseña debe digitar el correo al que llegara el link de restablecimiento.";
        return 0;
      }
      this.modal = 1;

      axios
        .post(
          `${process.env.VUE_APP_API_BOOKAPP}forget-password`,
          { email: this.userLogin.email },
          {
            headers: {
              conexion: this.conexion,
            },
          }
        )
        .then((response) => {
          this.alertShow = true;
          this.colorAlert = "success";
          this.textAlert =
            "El link para restablecer su contraseña fue enviado al correo indicado.";
          return 0;
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.alertShow = true;
            this.colorAlert = "danger";
            this.textAlert =
              "El correo digitado para el restablecimiento de la contraseña no se encuentra registrado.";
          }
        });
    },
    validateInputs() {
      this.errors = [];
      if (this.conexion == -1) {
        this.errors.push("Seleccione su colegio, por favor.");
      }
      if (!this.userLogin.email) {
        this.errors.push("Digite su correo, por favor.");
      }
      if (!this.userLogin.password) {
        this.errors.push("Digite su contraseña, por favor.");
      }
    },
    closeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  watch: {
    input(newValue, oldValue) {
      if (newValue == "" || newValue == null) {
        this.conexion = -1;
      }
    },
    conexion(newValue, oldValue) {
      if (newValue != -1) {
        const school = this.schoolList.find(
          (school) => school.code == newValue
        );
        this.input = school.name;
      }
    },
  },
  beforeMount() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  mounted() {
    this.getSchools();
    this.$store.state.isPinned = false;
  },
};
</script>

<style>
.orange-text {
  color: #f36c17;
}
.form-control1 {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(26, 115, 232);
}
</style>
<style scoped>
.login-img {
  margin-right: -22rem !important;
}
</style>
