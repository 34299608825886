<template>
  <div>
    <div
      v-if="OpenClose"
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display:block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Subida masiva de estudiantes</h5>
          </div>
          <div class="modal-body">
            <div class="col-12">
              <a style="cursor: pointer" target="_blank" :href="urlUpload">
                <label><u> Descargar plantilla para el cargue. </u></label>
              </a>
            </div>
            <div class="col-12">
              <label>Subir archivo*</label>
              <input
                @change="selectImagen()"
                accept=".xlsx"
                ref="file"
                type="file"
                id="files"
                class="multisteps-form__input form-control"
                name="files"
              />
            </div>        
          </div>
          <div class="modal-footer custom-flex">
            <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="mr-auto"
              @click="closeModal()"
            >Cancelar</vsud-button>
            <vsud-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto"
              @click="saveInscription()"
            >Guardar</vsud-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";

export default {
  components: {
    VsudButton,
  },
  name: "UploadAttendants",
  props: {
    visible: Boolean,
  },
  data() {
    return {     
      OpenClose: this.visible,
      file:null   ,
      arrayErrors:[]  
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    selectImagen() {
      this.file =this.$refs.file.files[0];
    },
    validateForm() {
      this.arrayErrors = [];
      if (this.file.length == 0) {
        this.arrayErrors.push("Debe seleccionar un archivo.");
      }   
    },
    saveInscription() {
      this.arrayErrors = [];
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      let data = new FormData();
      data.append("file", this.file);

      this.$emit("saveStundetsMasive", data);
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
    },
  },
  computed: {
    urlUpload() {
      return `${process.env.VUE_APP_API_SYSTEM_FILE}/PlantillasCarguesMasivos/CargueEstudiantes.xlsx`;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>
<style scoped>
.custom-flex {
  display: flex;
  justify-content: space-between;
}
</style>