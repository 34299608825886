<template>
  <div class="mt-4 mb-3 card mt-lg-0">
    <div class="pb-0 card-body">
      <div class="mb-3 row align-items-center">
        <div class="col-8">
          <h5 class="mb-1 text-gradient text-success">
            <a href="javascript:;">{{ title }}</a>
          </h5>
        </div>
        <div class="col-4 text-end">
          <div
            v-show="hasPermission('school_year.update')"
            @click="openModal()"
            style="cursor: pointer"
            class="nav-wrapper position-relative end-0"
          >
            <font-awesome-icon :icon="['fass', 'pen-to-square']" />
            <a>Editar</a>
          </div>
        </div>
      </div>
      <h6>{{ school.name }}</h6>
      <p>
        El estudiante esta matriculado en el curso
        <span @click="viewCourse(course.id)" style="cursor: pointer;color:#1155cc"><b>{{ course.name }}</b></span>
        en la jornada
        <b>{{ study_day }}</b>
      </p>
    </div>
    <InscriptionModal
      @closeModal="cancelModal"
      @saveDataInscription="createInscription"
      :visible="modalVisible"
      :actualCourse="course"
      :courses="courses"
      :studyDay="study_day"
    ></InscriptionModal>
  </div>
</template>

<script>
import InscriptionModal from "./InscriptionModal";
import axios from "axios";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissionsMixin";
import router from "@/router/index.js";

export default {
  name: "InscriptionCard",
  components: {
    InscriptionModal,
  },
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    course: { type: Object, require: true },
    school: { type: Object, require: true },
    study_day: { type: String, require: true },
    idInscription: { type: Number, require: true },
  },
  data() {
    return {
      showMenu: false,
      modalVisible: false,
      courses: [],
    };
  },
  mixins: [permissionsMixin],

  methods: {
    viewCourse(id) {
      router.push({
        path: `/cursos/ver/${id}`,
        replace: true,
      });
    },
    ...mapMutations(["displayAlertMessage", "closeAlertMessage"]),
    cancelModal() {
      this.modalVisible = false;
    },
    openModal() {
      this.modalVisible = true;
    },
    async getCourses() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}courses-all/null`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.courses = data;
      } else {
        console.log("error", data);
      }
    },
    async createInscription(dataSend) {
      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}inscriptions/${this.idInscription}`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        // this.courses = data;
        this.modalVisible = false;
        this.$parent.getUser(this.$route.params.id_usuario);
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "La inscripción se asigno correctamente.",
          colorAlert: "success",
        });
      } else {
        console.log("error", data);
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Error al asignar la inscripción.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
    },
  },
  mounted() {
    this.getCourses();
  },
};
</script>
