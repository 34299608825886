<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Grupo</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Envia</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mensaje</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Fecha</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="groups">
            <tr style="cursor: pointer" v-for="group in groups" :key="group.id">
              <td>
                <p class="text-secondary mb-0 text-sm">{{group.name }} </p>
              </td>
              <td>
                <span class="text-secondary text-sm"></span>
              </td>
              <td>
                <span class="text-secondary text-sm"></span>
              </td>
              <td>
                <span class="text-secondary text-sm">{{ group.created_at}} </span>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";

import router from "@/router/index.js";

export default {
  name: "Table",
  props: {
    groups: { type: Object, required: true },
    type: { type: Object, required: true },
  },
  watch: {
    // pagination(newValue, oldValue) {
    //   this.flag = true;
    // },
  },
  computed: {},
  methods: {
    // viewMessage(m) {
    //   const path = this.type == 'received' ? `/mensajes/mensajes/${m.id}` : `/mensajes/mensajes-aprobar/${m.id}`;
    //   router.push({
    //     path,
    //     replace: true,
    //   });
    // },
  },
  data() {
    return {
      flag: false,
      imgDefault,

      offset: 3,
    };
  },
  mounted() {},
};
</script>
<style scoped>
.pagination > li > a {
  background-color: white;
  color: #5a4181;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #5a4181 !important;
  border: solid 1px #5a4181 !important;
}

.pagination > .active > a:hover {
  background-color: #5a4181 !important;
  border: solid 1px #5a4181;
}
</style>
