<template>
  <div>
    <div class="loader" v-if="loading"></div>
    <sidenav
      v-if="showSidenav"
      :custom-class="color"
      :class="[isTransparent, isRTL ? 'fixed-end' : 'fixed-start']"
    />
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <!-- nav -->
      <navbar
        v-if="showNavbar"
        :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
        :text-white="isAbsolute ? 'text-white opacity-8' : ''"
        :min-nav="navbarMinimize"
      />
      <router-view />
      <app-footer v-show="showFooter" />
      <configurator
        :toggle="toggleConfigurator"
        :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
      />
    </main>
  </div>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "isNavFixed",
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "color",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      'loading'
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
};
</script>
