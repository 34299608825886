<template>
  <div class="container-fluid py-4">
    <div class="position-sticky fixed-top z-index-sticky">
      <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
        {{ textAlert
        }}
      </vsud-alert>
    </div>
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="col-sm-4">
            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Buscar por nombre, correo o documento" v-model="valueSearch"
              @keyup.enter="getUsersByRoleStudent" />
          </div>
          <div class="col-sm-3">
            <button type="button" class="btn bg-gradient-dark btn-md null null ms-auto"
              @click="getUsersByRoleStudent()">Buscar</button>
          </div>
          <div class="col-sm-5 ms-auto">
            <div class="row">
              <div class="col-sm-4">
                <div class="row">
                  <button @click="active = !active" class="btn btn-primary">
                    {{ active ? 'Ver Inactivos' : 'Ver Activos' }}
                  </button>
                </div>
              </div>
              <div class="col-sm-4">
                <button v-show="hasPermission('users.students.store')" type="button" class="btn bg-gradient-dark"
                  @click="modalVisible = !modalVisible" style="margin-right: -80px;">Cargue masivo</button>
              </div>
              <div class="col-sm-2">
                <div class="row">
                  <router-link to="/usuarios/estudiantes/nuevo">
                    <button type="button" v-show="hasPermission('users.students.store')"
                      class="btn bg-gradient-success">Crear</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <Table :typeList="'Estudiante'" :student="true" @getData="getUsersByRoleStudent" :userList="studentList"
            :pagination="pagination" />
        </div>
      </div>
    </div>
    <UploadStudents @saveStundetsMasive="saveStundetsMasive" @closeModal="cancelModal" :visible="modalVisible">
    </UploadStudents>
  </div>
</template>

<script>
import ComplexStatisticsCard from "@/examples/Cards/ComplexStatisticsCard.vue";
import Table from "./components/Table.vue";
import UploadStudents from "./components/UploadStudents.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import permissionsMixin from "@/mixins/permissionsMixin";
import axios from "axios";
import { mapState, mapMutations } from "vuex";

export default {
  name: "StudentsList",
  components: {
    ComplexStatisticsCard,
    Table,
    UploadStudents,
    VsudAlert,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      studentList: [],
      modalVisible: false,
      pagination: null,
      valueSearch: null,
      active: true,
    };
  },
  watch: {
    valueSearch(newVal) {
      if (newVal == "") {
        this.valueSearch = null;
        this.getUsersByRoleStudent();
      }
    },
    active() {
      this.getUsersByRoleStudent();
    },
  },
  methods: {
    ...mapMutations(["displayAlertMessage", "closeAlertMessage"]),
    async getUsersByRoleStudent(page = 1) {
      const request = {
        rolesIn: [3],
        rolesNotIn: [1],
        active: this.active,
      };
      const { status, data: dataA } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/user-filtered-paginate/${this.valueSearch}?page=${page}`,
        request,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        const { data, ...resto } = dataA;
        this.studentList = dataA.data;
        this.pagination = resto;
      }
    },
    async changePage(page) {
      this.getUsersByRoleStudent(page);
    },
    async saveStundetsMasive(data) {
      const { status, data: dataA } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/massive/students`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        console.log("todo ok");
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Se cargaron los estudiantes correctamente.",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Hubo un error al cargar los estudiantes",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
      this.getUsersByRoleStudent();
      this.cancelModal();
    },
    cancelModal() {
      this.modalVisible = false;
    },
  },
  computed: {
    ...mapState(["alertShow", "textAlert", "colorAlert"]),
  },
  mounted() {
    this.getUsersByRoleStudent();
  },
};
</script>
