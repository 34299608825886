<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">{{ textAlert
              }}</vsud-alert>
            </div>
            <div class="col-12 col-lg-8 mx-auto my-5">
              <!-- <h3>Editar permiso.</h3> -->
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <edit-permission-info @sendForm="editPermission" :permission="permission"></edit-permission-info>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditPermissionInfo from "./components/EditPermissionInfo.vue";
import Address from "./components/Address.vue";
import Socials from "./components/Socials.vue";
import Profile from "./components/Profile.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";


export default {
  name: "EditUser",
  components: {
    EditPermissionInfo,
    Address,
    Socials,
    Profile,
    VsudAlert,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      typeDocument: null,
      genders: null,
      bloodTypes: null,
      permission: null,
      roles: null,
      errors: [],
    };
  },
  methods: {
    async getRoles() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}roles`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.roles = data;
      } else {
        console.log("error", data);
      }
    },
    async getPermission(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}permissions/by-id/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.permission = data;
      }
    },
    async editPermission(permission) {
      const { name, module, description, id, ...resto } = permission;
      const permissionSend = { name, module, description, id };
      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}permissions/${permissionSend.id}`, permissionSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.alertShow = true;
        this.textAlert = "El permiso se edito correctamente.";
        this.colorAlert = "success";
        window.scrollTo(0, 0);
        this.removeAlert();
      } else {
        console.log("error", data);
      }
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  mounted() {
    this.getPermission(this.$route.params.id);
    this.getRoles();
  },
};
</script>
