<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Información personal</h5>
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <label>Nombres*</label>
          <input
            v-model="user.name"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="ej. Juan"
          />
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Apellidos*</label>
          <input
            v-model="user.lastname"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="ej. Perez"
          />
        </div>
        <div class="col-12 col-sm-4">
          <label>Codigo*</label>
          <input v-model="user.code" class="multisteps-form__input form-control" type="text" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Correo*</label>
          <input
            v-model="user.email"
            autocomplete="off"
            class="multisteps-form__input form-control"
            type="email"
            placeholder="eg. soft@dashboard.com"
          />
        </div>
        <div class="col-12 col-sm-4">
          <label>Contraseña*</label>
          <input
            v-model="user.password"
            autocomplete="off"
            class="multisteps-form__input form-control"
            type="password"
            placeholder="******"
          />
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Repetir contraseña*</label>
          <input
            v-model="user.passwordConfirm"
            autocomplete="off"
            class="multisteps-form__input form-control"
            type="password"
            placeholder="******"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <label>Tipo documento*</label>
          <select v-model="user.type_document_id" class="multisteps-form__select form-control">
            <option
              v-for="document in typeDocument"
              :key="document.id"
              :value="document.id"
              class="form-control"
            >{{ document.name }}</option>
          </select>
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Número de documento*</label>
          <input v-model="user.document" class="multisteps-form__input form-control" type="number" />
        </div>
        <div class="col-12 col-sm-4">
          <label>Teléfono*</label>
          <input v-model="user.phone" class="multisteps-form__input form-control" type="number" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Dirección*</label>
          <input v-model="user.address" class="multisteps-form__input form-control" type="text" />
        </div>
        <div class="col-12 col-sm-4">
          <label>Género*</label>
          <select v-model="user.gender_id" class="multisteps-form__input form-control">
            <option
              v-for="gender in genders"
              :key="gender.id"
              :value="gender.id"
              class="form-control"
            >{{ gender.name }}</option>
          </select>
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Fecha nacimiento*</label>
          <input v-model="user.birth_date" class="multisteps-form__input form-control" type="date" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <label>Tipo de sangre</label>
          <select v-model="user.blood_type_id" class="multisteps-form__input form-control">
            <option
              v-for="bloodType in bloodTypes"
              :key="bloodType.id"
              :value="bloodType.id"
              class="form-control"
            >{{ bloodType.name }}</option>
          </select>
        </div>
        <div class="col-12 col-sm-4">
          <label>Foto</label>
          <input
            @change="selectImagen()"
            accept=".png, .jpg, .jpeg, .gif"
            ref="file"
            class="multisteps-form__input form-control"
            type="file"
          />
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Eps</label>
          <select v-model="user.eps_id" class="multisteps-form__input form-control">
            <option v-for="e in eps" :key="e.id" :value="e.id" class="form-control">{{ e.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Enfermedades</label>
          <textarea
            v-model="user.diseases_interest"
            class="multisteps-form__input form-control textArea-fixed"
            rows="5"
          ></textarea>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <vsud-button
          v-if="!isFinalTab"
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="saveData"
        >Siguiente</vsud-button>
        <vsud-button
          v-if="isFinalTab"
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          @click="sendForm"
        >Guardar</vsud-button>
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import { isProxy, toRaw } from "vue";

export default {
  name: "UserInfo",
  props: {
    isFinalTab: {
      type: Boolean,
      default: false,
    },
    multiRole: {
      type: Boolean,
      default: false,
    },
    typeDocument: {
      type: Object,
      required: true,
    },
    genders: {
      type: Object,
      required: true,
    },
    bloodTypes: {
      type: Object,
      required: true,
    },
    roles: {
      type: Object,
      required: false,
    },
    eps: {
      type: Object,
      required: false,
    },
    errors: {
      type: Array,
      required: false,
    },
  },
  components: {
    VsudInput,
    VsudButton,
    Multiselect,
  },
  data() {
    return {
      arrayErrors: [],
      user: {
        name: null,
        lastname: null,
        code: null,
        email: null,
        password: null,
        passwordConfirm: null,
        type_document_id: null,
        document: null,
        phone: null,
        address: null,
        gender_id: null,
        eps_id: null,
        birth_date: null,
        blood_type_id: null,
        occupation: null,
        profile: null,
        diseases_interest: null,
        rol: null,
        image: null,
        approvers: [],
        supervisors: [],
      },
    };
  },
  watch: {
    errors(newValue, oldValue) {
      this.arrayErrors = toRaw(newValue);
    },
  },
  methods: {
    sendForm() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      this.$emit("sendForm", this.user);
    },
    saveData() {
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      this.$emit("saveData", this.user);
    },
    validateForm() {
      this.arrayErrors = [];
      if (!this.user.name) {
        this.arrayErrors.push("El nombre del usuario es requerido.");
      }
      if (!this.user.lastname) {
        this.arrayErrors.push("El apellido del usuario es requerido.");
      }
      if (!this.user.code) {
        this.arrayErrors.push("El código es requerido.");
      }
      if (!this.user.email) {
        this.arrayErrors.push("El correo es requerido.");
      }
      if (!this.user.password) {
        this.arrayErrors.push("La contraseña es requerida.");
      }
      if (!this.user.passwordConfirm) {
        this.arrayErrors.push("La contraseña debe ser confirmada.");
      }
      if (this.user.password != this.user.passwordConfirm) {
        this.arrayErrors.push("La contraseña no coincide.");
      }
      if (!this.user.type_document_id) {
        this.arrayErrors.push("El tipo de identificación es obligatorio.");
      }
      if (!this.user.address) {
        this.arrayErrors.push("La dirección es obligatoria.");
      }
      if (!this.user.document) {
        this.arrayErrors.push("El número de identificación es obligatorio.");
      }
      if (!this.user.birth_date) {
        this.arrayErrors.push("La fecha de nacimiento es obligatoria.");
      }
      if (isNaN(this.user.document)) {
        this.arrayErrors.push(
          "El número identificación debe ser un valor numerico."
        );
      } else if (this.user.document != "" && this.user.document != null) {
        this.user.document = this.user.document.toString();
      }

      if (!this.user.phone) {
        this.arrayErrors.push("El teléfono es obligatorio.");
      } else {
        if (isNaN(this.user.phone)) {
          this.arrayErrors.push("El teléfono debe ser un valor numerico.");
        } else if (this.user.phone != "" && this.user.phone != null) {
          this.user.phone = this.user.phone.toString();
        }
      }
      
      if (!this.user.gender_id) {
        this.arrayErrors.push("Debe seleccionar un género.");
      }
      if (this.roles) {
        if (!this.user.rol) {
          this.arrayErrors.push("Debe seleccionar un rol.");
        }
      }
    },
    selectImagen() {
      if (!this.$refs.file.files[0]) {
        return 0;
      }
      let fileSize = this.$refs.file.files[0].size / 1024 / 1024;
      if (fileSize > 2) {
        alert("El archivo debe pesar menos de 2 MB");
        return;
      }
      this.user.image = this.$refs.file.files[0];
    },
    validateAndNext() {
      this.validateForm();
    },
  },
};
</script>
<style scoped>
.textArea-fixed {
  resize: none;
}
</style>
