<template>
  <div>
    <div
      v-if="OpenClose"
      class="modal fade show"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style="display:block"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Agregar evento</h5>
          </div>
          <div class="modal-body">
            <div class="row mt-3">
              <div class="col-12 d-flex">
                <label class="my-auto" for>Titulo del evento</label>
                <input
                  class="form-control mx-2"
                  type="text"
                  min="0"
                  max="10"
                  style="width: full-width;"
                  v-model="event.title"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 d-flex">
                <label class="my-auto" for>Link</label>
                <input
                  class="form-control mx-2"
                  type="text"
                  min="0"
                  max="10"
                  style="width: full-width;"
                  v-model="event.link"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 d-flex">
                <label class="my-auto" for>Tipo de actividad:</label>
                <select v-model="event.type_activity_id" class="form-control mx-2" name id>
                  <option
                    class="form-control"
                    v-for="option in typeActivities"
                    :value="option.id"
                    :key="option.id"
                  >{{ option.name }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <label for>Desde:</label>
                <input v-model="date_start" class="form-control mb-1" type="date" />
                <input v-model="hour_start" class="form-control" type="time" />
              </div>
              <div class="col-6">
                <label for>Hasta:</label>
                <input v-model="date_end" class="form-control mb-1" type="date" />
                <input v-model="hour_end" class="form-control" type="time" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-center">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >Imagen evento</button>
                  <ul class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <div class="row">
                        <div v-for="icon in icons" :key="icon.id" class="col-4 my-2">
                          <img
                            @click="seleccionImage(icon)"
                            class="w-25 d-block mx-auto"
                            :src="icon.url_image"
                            :alt="icon.name"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div v-if="event.image" class="row mt-3">
              <div class="col-12 text-center">
                <img :src="event.image" class="w-25 d-block mx-auto" :alt="event.title" />
              </div>
            </div>
            <!-- <div class="row mt-3">
              <div class="col-12 d-flex">
                <label for>Profesor que crea:</label>
                <input
                  readonly
                  class="form-control"
                  :value="user.name+ ' '+ user.lastname"
                  type="text"
                />
              </div>
            </div>-->
          </div>
          <div v-if="validationsErrors.length > 0" class="row justify-content-center text-center">
            <div class="alert alert-danger col-md-8">
              <div v-for="error in validationsErrors" :key="error">
                <b>{{ error }}</b>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="mr-auto"
              @click="closeModal()"
            >Cancelar</vsud-button>
            <vsud-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto"
              @click="saveItemCalendar()"
            >Guardar</vsud-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <style src="vue-multiselect/dist/vue-multiselect.css"></style> -->

<script>
import "vue-multiselect/dist/vue-multiselect.css";
import VsudButton from "@/components/VsudButton.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
export default {
  components: {
    VsudButton,
    Multiselect,
  },
  name: "CalendarModal",
  props: {
    visible: Boolean,
    typeActivities: Array,
    icons: Array,
    course: String,
  },
  data() {
    return {
      hour_start: null,
      hour_end: null,
      date_start: null,
      date_end: null,
      OpenClose: this.visible,
      event: {
        date_start: null,
        date_end: null,
        title: null,
        created_by: null,
        type_activity_id: null,
        course_id: this.course,
        type_calendar: null,
        image: null,
        link: null,
      },
      arrayErrors: this.errors,
      user: null,
      subjects: [],
    };
  },
  methods: {
    ...mapActions(["setValidationError", "setEvent"]),
    ...mapMutations(["emptyValidationErrors"]),
    closeModal() {
      this.$emit("cancelSave");
    },
    saveItemCalendar() {
      this.validateEvent();
      if (this.validationsErrors.length > 0) {
        return 0;
      }
      this.event.date_start = this.date_start + "T" + this.hour_start;
      this.event.date_end = this.date_end + "T" + this.hour_end;
      // return 0;
      this.$emit("saveItem", this.event);
      this.event = {
        date_start: null,
        date_end: null,
        title: null,
        created_by: this.user.id,
        type_activity_id: null,
        course_id: null,
        type_calendar: null,
        image: null,
        link: null,
      };
      this.hour_end = null;
      this.hour_start = null;
    },
    validateEvent() {
      this.emptyValidationErrors();
      if (!this.event.title) {
        this.setValidationError("El evento requiere un titulo");
      }
      if (!this.event.type_activity_id) {
        this.setValidationError("El evento requiere una actividad");
      }
      if (
        !this.date_end ||
        !this.date_start ||
        !this.hour_end ||
        !this.hour_start
      ) {
        this.setValidationError(
          "El evento requiere las fechas y horas de inicio y de fin"
        );
      } else {
        const start = new Date(this.date_start);
        const end = new Date(this.date_end);
        console.log(start);
        console.log(end);
        if (start > end) {
          this.setValidationError(
            "La fecha de fin no puede ser menor que la de inicio"
          );
        } else if (start < end) {
          console.log("Todo ok");
        } else {
          console.log("las horas son iguales");
          if (!this.validateHours(this.hour_start, this.hour_end)) {
            this.setValidationError("Las horas no cuadran ");
          }
        }
      }
    },
    customLabel({ title, desc }) {
      return `${title} – ${desc}`;
    },
    seleccionImage(image) {
      this.event.image = image.url_image;
      // console.log(image);
    },
    validateHours(hora1, hora2) {
      const [hora1Horas, hora1Minutos] = hora1.split(":");
      const [hora2Horas, hora2Minutos] = hora2.split(":");
      // Convertir las cadenas a números enteros
      const intHora1Horas = parseInt(hora1Horas, 10);
      const intHora1Minutos = parseInt(hora1Minutos, 10);
      const intHora2Horas = parseInt(hora2Horas, 10);
      const intHora2Minutos = parseInt(hora2Minutos, 10);

      // Comparar las horas y minutos
      if (
        intHora1Horas > intHora2Horas ||
        (intHora1Horas === intHora2Horas && intHora1Minutos > intHora2Minutos)
      ) {
        return false;
      } else if (
        intHora1Horas === intHora2Horas &&
        intHora1Minutos === intHora2Minutos
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
      this.date_start = this.dateCalendar.dateStr;
      this.date_end = this.dateCalendar.dateStr;
    },
  },
  computed: {
    ...mapState(["dateCalendar", "validationsErrors", "calendar"]),
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.event.created_by = this.user.id;
    this.event.course_id = this.$route.params.id_curso;
  },
  beforeUnmounted() {
    this.emptyValidationErrors();
  },
  created() {
    //
  },
};
</script>
<style>
.scrollable-menu {
  max-height: 200px;
  max-width: auto;
  overflow-y: auto;
}
</style>