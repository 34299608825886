<template>
  <div  v-show="hasPermission('users.dashboard')">
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-lg-12 position-relative z-index-2">
          <div class="mb-4 card card-plain">
            <div class="p-3 card-body">
              <div class="row">
                <div class="col-lg-8">
                  <div class="d-flex flex-column h-100">
                    <h2
                      class="mb-0 font-weight-bolder"
                      style="color: #f06C30;"
                    >Estadísticas Generales</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="countsUsers" class="row">
            <div class="col-md-3 col-sm-5">
              <mini-statistics-card
                title="Estudiantes"
                :value="countsUsers.students_count"
                :percentage="{
                value: '+55%',
                color: 'text-success',
              }"
                icon="fa-user-graduate"
                direction-reverse
              />
            </div>
            <div class="col-md-3 col-sm-5">
              <mini-statistics-card
                title="Acudientes"
                :value="countsUsers.attendants_count"
                :percentage="{
                value: '+3%',
                color: 'text-success',
              }"
                icon="fa-user-group"
                direction-reverse
              />
            </div>
            <div class="col-md-3 col-sm-5">
              <mini-statistics-card
                title="Profesores"
                :value="countsUsers.teachers_count"
                :percentage="{
                value: '-2%',
                color: 'text-danger',
              }"
                icon="fa-user-tie"
                direction-reverse
              />
            </div>
            <div class="col-md-3 col-sm-5">
              <mini-statistics-card
                title="Administrativos"
                :value="countsUsers.administratives_count"
                :percentage="{
                value: '+5%',
                color: 'text-success',
              }"
                icon="fa-user-gear"
                direction-reverse
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div v-if="cantStundentsByWorkingDay" class="mb-4 col-lg-6 mb-lg-0">
          <div class="card z-index-2">
            <progress-doughnut-chart
              title="Alumnos por Jornada"
              id="chart1"
              icon="fa-user-graduate"
              :count="countsUsers.students_count"
              :chart="{
            labels: cantStundentsByWorkingDay,
            datasets: {
              label: 'Projects',
              data:dataCountWorkingDay,
            },
          }"
            />
          </div>
        </div>
        <div v-if="cantMessagesByStatus" class="mb-4 col-md-6 mb-lg-0">
          <div class="card z-index-2">
            <progress-doughnut-chart
              title="Participacion en mensajes"
              id="chart2"
              icon="fa-envelope"
              :count="cantMessagesByStatus.message_seen + cantMessagesByStatus.message_received"
              :chart="{
            labels: [
              {
                label: 'Leídos',
                isCompleted: true,
              },
              {
                label: 'No leídos',
                isCompleted: false,
              },
            ],
            datasets: {
              label: 'Projects2',
              data: [cantMessagesByStatus.message_seen,cantMessagesByStatus.message_received],
            },
          }"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 row">
       <div v-if="cantMessagesByStatus" class="mb-1 col-md-6 mb-lg-0">
          <div class="card z-index-2">
            <progress-doughnut-chart
              title="Acudientes Conectados"
              id="chart2"
              icon="fa-user-group"
              :count="cantMessagesByStatus.message_seen + cantMessagesByStatus.message_received"
              :chart="{
            labels: [
              {
                label: 'Conectados',
                isCompleted: true,
              },
              {
                label: 'No Conectados',
                isCompleted: false,
              },
            ],
            datasets: {
              label: 'Projects2',
              data: [cantMessagesByStatus.message_seen,cantMessagesByStatus.message_received],
            },
          }"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div v-if="cantStudentsByCourse.course" class="mb-4 col-lg-12 mb-lg-0">
          <div class="card z-index-2">
            <thin-bar-chart
              title="Estudiantes por Curso"
              id="curso"
              :chart="{
                labels: cantStudentsByCourse.course,
                datasets: {
                  label: 'Estudiantes',
                  data: cantStudentsByCourse.courseVal
                }
              }"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div v-if="cantMessagesMonthly.months" class="mb-4 col-md-12 mb-lg-0">
          <div class="card z-index-2">
            <progress-line-chart
              icon="fa-envelope"
              title="Cantidad de Mensajes"
              :count="cantMessagesMonthly.totalMessages"
              :chart="{
            labels: cantMessagesMonthly.months,
            data: cantMessagesMonthly.monthsVal,
          }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <ReportsDoughnutChart v-if="cantUsersByRole" :data="cantUsersByRole" />
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import ReportsBarChart from "../../examples/Charts/ReportsBarChart.vue";
import GradientLineChart from "../../examples/Charts/GradientLineChart.vue";
import SalesTable from "./components/SalesTable.vue";
import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import axios from "axios";
import notificationMixin from "@/mixins/notificationMixin";
import General from "../pages/projects/General.vue";
import ProgressLineChart from "../pages/projects/components/ProgressLineChart.vue";
import ProgressDoughnutChart from "../pages/projects/components/ProgressDoughnutChart.vue";
import Globe from "../../examples/Globe.vue";
import SmartHome from "../dashboards/SmartHome.vue";
import ThinBarChart from "@/examples/Charts/ThinBarChart.vue";
import ReportsDoughnutChart from "@/examples/Charts/ReportsDoughnutChart";
import permissionsMixin from "@/mixins/permissionsMixin";

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { mapMutations } from "vuex";

export default {
  name: "DashboardDefault",
  components: {
    MiniStatisticsCard,
    ReportsBarChart,
    GradientLineChart,
    SalesTable,
    Globe,
    General,
    ProgressLineChart,
    ProgressDoughnutChart,
    SmartHome,
    ThinBarChart,
    ReportsDoughnutChart,
  },
  // mixins:[notificationMixin],
  mixins: [permissionsMixin],

  data() {
    return {
      faHandPointer,
      faUsers,
      faCreditCard,
      faScrewdriverWrench,
      iconBackground: "bg-gradient-success",
      US,
      DE,
      BR,
      GB,
      faEnvelope,
      countsUsers: null,
      cantMessagesByStatus: null,
      cantStundentsByWorkingDay: null,
      labelsStudentesByCourse: [],
      dataCountWorkingDay: [],
      cantMessagesMonthly: {
        months: null,
        monthsVal: null,
        totalMessages: null,
      },
      cantStudentsByCourse: {
        course: null,
        courseVal: null,
        totalStudents: null,
      },
      cantUsersByRole: null,
    };
  },
  cumputed: {},
  methods: {
    ...mapMutations(["setSchoolData"]),
    async getSchool() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}schools`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        // this.setSchoolData(data);
        localStorage.setItem("school",JSON.stringify(data));
      } else {
        console.log("error", data);
      }
    },
    async getCountsUsers() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}reports/dashboard/count-users`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.countsUsers = data;
      } else {
        console.log("error", data);
      }
    },
    async getCantMessagesByStatus() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}reports/dashboard/count-messages-status`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.cantMessagesByStatus = data;
      } else {
        console.log("error", data);
      }
    },
    async getCantMessagesMonthlyCount() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}reports/dashboard/count-messages-monthly`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.cantMessagesMonthly.months = Object.keys(data.data);
        this.cantMessagesMonthly.monthsVal = Object.values(data.data);
        this.cantMessagesMonthly.monthsVal.forEach((val) => {
          this.cantMessagesMonthly.totalMessages += val;
        });
      } else {
        console.log("error", data);
      }
    },
    async getCantStudentsByWorkingDay() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}reports/dashboard/count-students-working-day`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.cantStundentsByWorkingDay = data.data;
        this.cantStundentsByWorkingDay.forEach((g) => {
          this.dataCountWorkingDay.push(g.count);
        });
        this.cantStundentsByWorkingDay.map((g) => {
          (g.label = g.study_day), (g.isCompleted = true);
        });
      } else {
        console.log("error", data);
      }
    },
    async getCantStudentsByCourse() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}reports/dashboard/count-students-course`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.cantStudentsByCourse.course = Object.keys(data.data);
        this.cantStudentsByCourse.courseVal = Object.values(data.data);
        this.cantStudentsByCourse.courseVal.forEach((val) => {
          this.cantStudentsByCourse.totalStudents += val;
        });
      } else {
        console.log("error", data);
      }
    },
    async getCantUsersByRole() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}reports/dashboard/count-users-all-roles`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.cantUsersByRole = data.data;
      } else {
        console.log("error", data);
      }
    },
  },
  mounted() {
    this.getSchool();
    this.getCountsUsers();
    this.getCantMessagesByStatus();
    this.getCantMessagesMonthlyCount();
    this.getCantStudentsByWorkingDay();
    this.getCantStudentsByCourse();
    this.getCantUsersByRole();
  },
};
</script>
<style scoped>
.my-component {
  position: absolute;
  top: 50%; /* Coloca el componente en el centro vertical */
  right: 5%; /* Coloca el componente en el lado derecho */
  transform: translate(
    0,
    -50%
  ); /* Alinea verticalmente el componente en el centro */

  background-size: cover;
  background-position: center;
  width: 50%; /* Ajusta el ancho del componente SchoolShield según tu diseño */
  height: 100%; /* Ajusta la altura del componente SchoolShield según tu diseño */
  opacity: 0.7; /* Ajusta la opacidad según tus necesidades */
  /* Otros estilos personalizados */
}
</style>+