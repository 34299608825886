<template>
  <div class="card widget-calendar">
    <!-- Card header -->
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">{{ title }}</h6>
      <div class="d-flex">
        <div class="mb-0 text-sm p font-weight-bold widget-calendar-day">{{ day }}</div>
        <span>,&nbsp;</span>
        <div class="mb-1 text-sm p font-weight-bold widget-calendar-year">{{ year }}</div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div :id="id" data-toggle="widget-calendar"></div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import "@fullcalendar/core/main.js"; // Agrega esta línea para importar el estilo CSS
import "@fullcalendar/daygrid/main.css"; // También puedes agregar este si estás usando el plugin dayGrid
import { mapState, mapActions, mapMutations } from "vuex";
import { HD, getHolidays } from 'date-holidays';
let calendar;
export default {
  name: "Calendar",
  props: {
    id: {
      type: String,
      default: "widget-calendar",
    },
    title: {
      type: String,
      default: "Calendario",
    },
    day: {
      type: String,
      default: new Date().getDate(),
    },
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    initialView: {
      type: String,
      default: "dayGridMonth",
    },
    initialDate: {
      type: String,
      default: "2022-02-24",
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    calendar = new Calendar(document.getElementById(this.id), {
      contentHeight: "auto",
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: this.initialView,
      selectable: this.selectable,
      editable: this.editable,
      initialDate: this.initialDate,
      events: this.events,
      locale: esLocale,
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth",
        dayCellContent: this.dateClass,
      },
      dateClick: this.handleDateClick,
      eventClick: this.handleEventClick,
      views: {
        month: {
          titleFormat: {
            month: "long",
            year: "numeric",
          },
        },
        agendaWeek: {
          titleFormat: {
            month: "long",
            year: "numeric",
            day: "numeric",
          },
        },
        agendaDay: {
          titleFormat: {
            month: "short",
            year: "numeric",
            day: "numeric",
          },
        },
      },
    });

    calendar.render();
    this.saveCalendar(calendar);
    // console.log(calendar);
    this.objCalendar = calendar;
  },
  data() {
    return {
      objCalendar:null,
    };
  },
  beforeUnmounted() {
    if (calendar) {
      calendar.destroy();
    }
  },
  methods: {
    ...mapActions(["setDateCalendar"]),
    ...mapMutations(["saveCalendar","setEventDetail"]),
    handleDateClick(info) {
      // console.log(info);
      // alert("Fecha seleccionada: " + info.dateStr);
      this.$emit("openModal");
      this.setDateCalendar(info);
    },
    handleEventClick(info) {
      this.setEventDetail(info.event);
      this.$emit("openModalDetail");
      // alert("Evento seleccionado: " + info.event.title);
    },
    isHoliday(date) {
      // Utiliza la librería date-holidays para determinar si una fecha es un día festivo
      const hd = new HD();
      hd.init('CO'); // Código de país para Colombia

      const holidays = getHolidays(date.getFullYear(), 'CO');
      const formattedDate = date.toISOString().split('T')[0];

      return holidays.some((holiday) => holiday.date === formattedDate);
    },
    dateClass(info) {
      const day = info.date.getDate();

      // Aplica una clase diferente a los días festivos, sábados y domingos
      if (this.isHoliday(info.date) || info.dayNumberText.startsWith('S')) {
        return ['table-holiday'];
      }
      return [];
    },
  },
  computed: {
    ...mapState(["events"]),
  }, 
};
</script>
<style scoped>
/* Agrega estilos CSS para el color de los días festivos, sábados y domingos */
.table-holiday {
  background-color: #61c619; /* Cambia esto al color que desees */
}
</style>