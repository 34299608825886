<template>
  <svg
    width="70"
    viewBox="0 0 306 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f)">
      <path
        d="M165.907 35.625C165.907 35.625 156.143 48.861 148.512 48.425C138.946 47.863 137.312 36.325 128.444 35.625C119.166 35.764 118.219 47.725 108.163 48.425C99.1529 48.264 95.3359 35.843 86.7469 35.625C78.1579 35.407 69.0029 48.425 69.0029 48.425"
        stroke="#CED4DA"
        stroke-width="16"
        stroke-linecap="round"
      />
      <path
        d="M154.919 103.625C154.919 103.625 140.219 116.861 128.726 116.425C114.326 115.863 111.855 104.325 98.508 103.625C84.538 103.764 83.108 115.725 67.969 116.425C54.403 116.262 48.655 103.842 35.719 103.625C22.783 103.408 9 116.425 9 116.425"
        stroke="#CED4DA"
        stroke-width="16"
        stroke-linecap="round"
      />
      <path
        d="M238.919 157.625C238.919 157.625 224.219 170.861 212.726 170.425C198.326 169.863 195.855 158.325 182.508 157.625C168.538 157.764 167.108 169.725 151.969 170.425C138.403 170.262 132.655 157.842 119.719 157.625C106.783 157.408 93 170.425 93 170.425"
        stroke="#CED4DA"
        stroke-width="16"
        stroke-linecap="round"
      />
      <path
        d="M220.25 19.4441L257.266 111.061"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M229.482 41.7656L207.122 31.8528"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M229.482 41.7655L239.189 18.897"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M249.223 90.6272L240.025 113.29"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M249.223 90.6271L272.091 100.334"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M190.205 58.6675L288.055 72.4195"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M214.151 61.834L194.387 76.2415"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M214.151 61.834L199.2 41.9931"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M266.338 69.1682L281.365 88.4654"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M266.338 69.1682L286.179 54.217"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M209.151 104.299L269.986 26.4342"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M223.866 85.1443L226.461 109.465"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M223.866 85.1442L199.208 88.1718"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M256.311 43.6169L280.536 40.2513"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <path
        d="M256.311 43.6168L253.283 18.9585"
        stroke="#CED4DA"
        stroke-width="10"
        stroke-linecap="round"
      />
      <circle cx="239.321" cy="66.5" r="8.5" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_f"
        x="0"
        y="10.9402"
        width="294.703"
        height="168.495"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur" />
      </filter>
    </defs>
  </svg>
</template>
<script></script>
