<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
            >Estudiante</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center"
            >Mensaje</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center"
            >Fecha</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center"
            >Estado</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="callAttentions">
            <tr style="cursor: pointer" @click="viewMessage(cA)" v-for="cA in callAttentions" :key="cA.id">
              <td class="text-sm">
                <p
                  class="text-secondary mb-0 text-sm text-center"
                >{{ cA.student.name }} {{ cA.student.lastname }} --- N° documento{{ cA.student.document }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm text-center">{{cA.message }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm text-center">{{ cA.date }}</p>
              </td>
              <td class="text-sm">
                <p class="text-secondary mb-0 text-sm text-center">{{ cA.status_message }}</p>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="4">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router/index.js";

export default {
  name: "Table",
  props: {
    callAttentions: Array,
    typeList: String,
  },
  data() {
    return {
      modalVisible: false,
      specialRateEdit: null,
      path:null,
    };
  },

  methods: {
    viewMessage(m) {
      this.makeRoute(m);
      router.push({
        path: this.path,
        replace: true,
      });
    },
    makeRoute(m) {
      switch (this.typeList) {
        case "SEND":
          this.path = `/llamados-atencion/detalle/${m.id}`;
          break;
        case "APPROVE":
          this.path = `/llamados-atencion/aprobar/${m.id}`;
          break;        
      }
    },
  },
  mounted(){
  }
};
</script>
