<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Información personal</h5>
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <label>Nombres*</label>
          <input v-model="user.name" class="multisteps-form__input form-control" type="text" placeholder="ej. Juan" />
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Apellidos*</label>
          <input v-model="user.lastname" class="multisteps-form__input form-control" type="text"
            placeholder="ej. Perez" />
        </div>
        <div class="col-12 col-sm-4">
          <label>Ocupación</label>
          <input v-model="user.occupation" class="multisteps-form__input form-control" type="text" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Correo*</label>
          <input v-model="user.email" autocomplete="off" class="multisteps-form__input form-control" type="email"
            placeholder="eg. soft@dashboard.com" />
        </div>
        <div class="col-12 col-sm-4">
          <label>Contraseña*</label>
          <input v-model="user.password" autocomplete="off" class="multisteps-form__input form-control" type="password"
            placeholder="******" />
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Repetir contraseña*</label>
          <input v-model="user.passwordConfirm" autocomplete="off" class="multisteps-form__input form-control"
            type="password" placeholder="******" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <label>Tipo documento*</label>
          <select v-model="user.type_document_id" class="multisteps-form__select form-control">
            <option v-for="document in typeDocument" :key="document.id" :value="document.id" class="form-control">
              {{ document.name }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Número de documento*</label>
          <input v-model="user.document" class="multisteps-form__input form-control" type="number" />
        </div>
        <div class="col-12 col-sm-4">
          <label>Teléfono*</label>
          <input v-model="user.phone" class="multisteps-form__input form-control" type="number" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <label>Género*</label>
          <select v-model="user.gender_id" class="multisteps-form__input form-control">
            <option v-for="gender in genders" :key="gender.id" :value="gender.id" class="form-control">
              {{ gender.name }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Fecha nacimiento*</label>
          <input v-model="user.birth_date" class="multisteps-form__input form-control" type="date" />
        </div>
        <div class="col-12 col-sm-4">
          <label>Foto</label>
          <input @change="selectImagen()" accept=".png,.jpg,.jpeg,.gif" ref="file"
            class="multisteps-form__input form-control" type="file" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <label>Dirección*</label>
          <input v-model="user.address" class="multisteps-form__input form-control" type="text" />          
        </div>

        <div v-if="roles" class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Roles extra*</label>
          <template v-if="multiRole">
            <multiselect v-model="selectedRoles" track-by="name" label="name" placeholder="Seleccione los roles a asignar" selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover" selectedLabel="Seleccionado" :hide-selected="true" :multiple="true" :options="rolesFilters"
            :showNoOptions="false" ></multiselect>
          </template>
        </div>
      </div>      
      <div class="button-row d-flex mt-4">
        <vsud-button type="button" color="dark" variant="gradient" class="ms-auto" @click="sendForm">Guardar</vsud-button>
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import { isProxy, toRaw } from "vue";

export default {
  name: "UserInfoAttendant",
  props: {
    multiRole: {
      type: Boolean,
      default: false,
    },
    typeDocument: {
      type: Object,
      required: true,
    },
    genders: {
      type: Object,
      required: true,
    },
    roles: {
      type: Object,
      required: false,
    },
    errors: {
      type: Array,
      required: false,
    },    
  },
  components: {
    VsudInput,
    VsudButton,
    Multiselect,
  },
  data() {
    return {
      arrayErrors: [],
      rolesFilters: [],
      fixedRole: null,
      selectedRoles: [],
      user: {
        name: null,
        lastname: null,
        code: null,
        email: null,
        password: null,
        passwordConfirm: null,
        type_document_id: null,
        document: null,
        phone: null,
        gender_id: null,
        birth_date: null,
        profile: null,
        image: null,
        address:null,
        rol: [],
        approvers: [],
        supervisors: [],
        occupation:null
      },
    };
  },
  watch: {
    errors(newValue, oldValue) {
      this.arrayErrors = toRaw(newValue);
    },
    roles(newValue, oldValue) {
      this.fixedRole = newValue.find(rol => rol.id ==4);
      this.rolesFilters = newValue.filter(rol => rol.id != 4);
    }
  },
  methods: {
    async sendForm() {
      this.user.rol = [...this.selectedRoles];
      this.user.rol.push(this.fixedRole);
      this.validateForm();
      if (this.arrayErrors.length > 0) {
        return 0;
      }
      await this.$emit("sendForm", this.user);
      this.selectedRoles = [];
    },
    validateForm() {
      this.arrayErrors = [];
      if (!this.user.name) {
        this.arrayErrors.push("El nombre del usuario es requerido.");
      }
      if (!this.user.lastname) {
        this.arrayErrors.push("El apellido del usuario es requerido.");
      }
      if (!this.user.email) {
        this.arrayErrors.push("El correo es requerido.");
      }
      if (!this.user.password) {
        this.arrayErrors.push("La contraseña es requerida.");
      }
      if (!this.user.passwordConfirm) {
        this.arrayErrors.push("La contraseña debe ser confirmada.");
      }
      if (!this.user.address) {
        this.arrayErrors.push("La dirección es obligatoria.");
      }
      if (this.user.password != this.user.passwordConfirm) {
        this.arrayErrors.push("La contraseña no coincide.");
      }
      if (!this.user.type_document_id) {
        this.arrayErrors.push("El tipo de identificación es obligatorio.");
      }
      if (!this.user.document) {
        this.arrayErrors.push("El número de identificación es obligatorio.");
      }
      if (!this.user.phone) {
        this.arrayErrors.push("El número de teléfono es obligatorio.");
      }
      if (isNaN(this.user.document)) {
        this.arrayErrors.push("El número identificación debe ser un valor numerico.");
      } else if (this.user.document != "" && this.user.document != null) {
        this.user.document = this.user.document.toString();
      }

      if (!this.user.birth_date) {
        this.arrayErrors.push("La fecha de nacimiento es obligatoria.");
      }
      if (isNaN(this.user.phone)) {
        this.arrayErrors.push("El teléfono debe ser un valor numerico.");
      } else if (this.user.phone != "" && this.user.phone != null) {
        this.user.phone = this.user.phone.toString();
      }
      if (!this.user.gender_id) {
        this.arrayErrors.push("Debe seleccionar un género.");
      }
    },
    selectImagen() {
      if (!this.$refs.file.files[0]) {
        return 0;
      }
      let fileSize = this.$refs.file.files[0].size / 1024 / 1024;
      if (fileSize > 2) {
        alert("El archivo debe pesar menos de 2 MB");
        return;
      }
      this.user.image = this.$refs.file.files[0];
    },
    validateAndNext() {
      this.validateForm();
    },
  },
};
</script>
<style scoped>
.textArea-fixed {
  resize: none;
}
</style>
