<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="col-sm-5">
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Buscar por nombre de asignatura"
              v-model="valueSearch"
              @keyup.enter="getSubjects"
            />
          </div>
          <div class="col-sm-3">
            <button
              type="button"
              class="btn mb-2 bg-gradient-dark btn-md null null ms-auto"
              @click="getSubjects()"
            >Buscar</button>
          </div>
          <div v-show="hasPermission('users.subjects.store')" class="col-4 text-end ms-auto">
            <router-link to="/materias/crear">
              <button type="button" class="btn bg-gradient-success">Crear</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <Table :subjects="subjects" />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./components/TableSubjects.vue";
import permissionsMixin from "@/mixins/permissionsMixin";
import axios from "axios";

export default {
  name: "SubjectList",
  components: {
    Table,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      subjects: [],
      valueSearch: null,
    };
  },
  watch: {
    valueSearch(newVal) {
      if (newVal == "") {
        this.valueSearch = null;
        this.getSubjects();
      }
    },
  },
  methods: {
    async getSubjects() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}subjects/${this.valueSearch}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.subjects = data;
      }
    },
  },
  mounted() {
    this.getSubjects();
  },
};
</script>
