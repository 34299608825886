<template>
  <div class="mt-4 mb-3 card mt-lg-0">
    <div class="pb-0 card-body">
      <div class="mb-3 row align-items-center">
        <div class="col-9">
          <h5 class="mb-1 text-gradient text-success">
            <a href="javascript:;">{{ title }}</a>
          </h5>
        </div>
      </div>
      <!-- <p>{{ description }}</p> -->
      <ul class="mx-auto list-unstyled" v-if="subjects">
        <li v-for="s in sortedSubjects" :key="s.id" class="d-flex">
          <div class="d-flex px-2 py-1">
            <vsud-avatar :img="[
              s.image ? s.image : imgDefault]" class="me-2"
                      alt="avatar image"
                      border-radius="lg" />
          </div>
          <p class="mb-0 ml-3">
            {{ s.name }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/book.png";
export default {
  name: "SubjectCard",
  components: { VsudAvatar },
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    subjects: { type: Object, require: true },
  },
  data() {
    return {
      showMenu: false,
      imgDefault
    };
  },
  computed: {
    sortedSubjects() {
      // Utiliza Array.sort() para ordenar las asignaturas alfabéticamente
      return this.subjects.slice().sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convertir a mayúsculas para una comparación sin distinción entre mayúsculas y minúsculas
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0; // Son iguales
      });
    }
  },
};
</script>
