<template>
  <div class="card p-3 border-radius-xl bg-white">
    <!-- <h5 class="font-weight-bolder mb-0">Crear mensaje</h5> -->
    <p class="mb-0 text-sm">Los campos marcados con un asterisco (*) son obligatorios.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12">
          <label for="type_message">Tipo de mensaje*</label>
          <!-- <multiselect v-model="message.type_message_id" trackBy="id" :options="listTypeMessage"
            label="name" :multiple="false" placeholder="Selecione un tipo de mensaje">
          </multiselect> -->
          <select v-model="message.type_message_id" class="multisteps-form__input form-control">
            <option  v-for="tm in listTypeMessage" :key="tm.id" :value="tm.id" class="form-control">{{ tm.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label>Asunto*</label>
          <input type="text" class="form-control" v-model="message.subject" />
        </div>
      </div>
      <div v-if="message.type_message_id != 1 && message.type_message_id != null" class="row mt-3">
        <div class="col-12">
          <label>Destinatario:*</label>
          <multiselect
            v-if="message.type_message_id == 3"
            v-model="message.receiver"
            :hide-selected="true"
            :internal-search="false"
            :limit="10"
            :options-limit="300"
            track-by="fullName"
            label="fullName"
            placeholder="Seleccione el estudiante"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="true"
            :options="usersSend"
            @search-change="getUsers"
            :loading="isLoading"
            :close-on-select="false"
            :showNoOptions="false"
          ></multiselect>
          <multiselect
            v-if="message.type_message_id == 2"
            v-model="message.receiver"
            track-by="name"
            label="name"
            placeholder="Seleccione el grupo"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="true"
            :hide-selected="true"
            :options="groups"
            :close-on-select="false"
            :showNoOptions="false"
          ></multiselect>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div>
            <label>Privacidad*</label>
          </div>
          <!-- <div class="form-check form-check-inline">
            <input
              v-model="message.privacity"
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="public"
            />
            <label class="form-check-label" for="flexRadioDefault1">Publico</label>
          </div> -->
          <div class="form-check form-check-inline">
            <input
              v-model="message.privacity"
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked
              value="private"
            />
            <label class="form-check-label" for="flexRadioDefault2">Privado</label>
          </div>
        </div>
      </div>
      <div class="row mt-3 mx-1">
        <!-- <div class="col-12"> -->
        <label>Contenido del mensaje*</label>
        <QuillEditor style="min-height:200px;" ref="editor" theme="snow" />
        <!-- </div> -->
      </div>
      <div class="row mt-3 mx-1">
        <div class="col-5">
          <div class="button-row">
            <vsud-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto"
              @click="preguntarEnvioMensaje"
            >Enviar</vsud-button>
          </div>
        </div>
        <div class="col-7">
          <div class="row">
            <div class="col-3 my-auto">
              <div @click="filesModal" v-if="message.files.length > 0">
                <div class="icon me-2">
                  <svg
                    class="text-dark mb-1"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>Archivos</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-1870.000000, -591.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path
                              class="color-background"
                              d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                              opacity="0.603585379"
                            />
                            <path
                              class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span class="text-sm">Ver archivos</span>
              </div>
            </div>
            <div class="col-9">
              <input
                @change="selectImagen()"
                accept=".png, .jpg, .jpeg, .gif, .pdf, .docx"
                ref="file"
                type="file"
                id="files"
                class="multisteps-form__input form-control"
                name="files"
                multiple
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-if="arrayErrors.length > 0" class="row mt-2 justify-content-center text-center">
          <div class="alert alert-danger col-md-8">
            <div v-for="error in arrayErrors" :key="error">
              <b>{{ error }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import { isProxy, toRaw } from "vue";

export default {
  name: "MessageCreateForm",
  props: {
    errors: {
      type: Array,
      required: false,
    },
    groups: {
      type: Array,
      required: false,
    },
    usersSend: {
      type: Array,
      required: false,
    },
    typesMessage: {
      required: true,
      type: Array,
    },
  },
  components: {
    VsudInput,
    VsudButton,
    Multiselect,
  },
  data() {
    return {
      arrayErrors: [],
      isLoading: false,
      message: {
        type_message_id: null,
        school_id: 1,
        sender_id: null,
        status: "active",
        receiver: [],
        message: null,
        message_text: null,
        privacity: "2",
        subject: null,
        stundent_id: null,
        files: [],
      },
      user: JSON.parse(localStorage.getItem("user")),
      roles: JSON.parse(localStorage.getItem("roles")),
      arrSendSubjects: [],
    };
  },
  watch: {
    errors(newValue, oldValue) {
      this.arrayErrors = toRaw(newValue);
    },
    "message.type_message_id": function (newValue, oldValue) {
      this.message.receiver = [];
      if (newValue == 2) {
        this.$parent.getGruops();
      }
      if (newValue == 3) {
        this.$parent.getUsers("a");
      }
    },
  },
  methods: {
    sendForm() {console.log("datos enviados: ", this.message)
      const editor = this.$refs.editor;
      const mensajeTexto = editor.getText();
      const mensajeContenido = editor.getHTML();
      this.message.message_text = mensajeTexto;
      this.message.message = mensajeContenido;
      this.validateForm();

      if (this.arrayErrors.length > 0) {
        return 0;
      }
      if (this.message.type_message_id == 1) {
        this.arrSendSubjects.push(1);
      } else {
        this.message.receiver.forEach((s) => {
          this.arrSendSubjects.push(s.id);
        });
      }
      // const user = JSON.parse(localStorage.getItem("user"));
      const dataSend = {
        type_message_id: this.message.type_message_id,
        school_id: 1,
        sender_id: this.user.id,
        status: "active",
        receiver: this.arrSendSubjects,
        message: this.message.message,
        subject: this.message.subject,
        privacity: this.message.privacity,
        files: this.message.files,
        student_id: null,
      };
      this.$emit("saveMessage", dataSend);
    },
    validateForm() {
      this.arrayErrors = [];
      if (!this.message.type_message_id) {
        this.arrayErrors.push("El tipo de mensaje es requerido.");
      }

      if (!this.message.subject) {
        this.arrayErrors.push("El mensaje require un asunto.");
      }

      if (
        this.message.type_message_id != 1 &&
        this.message.receiver.length == 0
      ) {
        this.arrayErrors.push("El mensaje debe tener un destinatario");
      }

      if (this.message.message_text === "\n") {
        this.arrayErrors.push("Agregue un contenido al mensaje");
      }
      if (this.message.files.length > 10) {
        this.arrayErrors.push("El limite de los archivos a enviar son 10");
      } else {
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          let f = this.$refs.file.files.item(i);
          let fileSize = f.size / 1024 / 1024;
          if (fileSize > 5) {
            this.arrayErrors.push(`El archivo ${f.name} pesa mas de 5 mb`);
          }
        }
      }
    },
    async getUsers(value) {
      this.isLoading = true;
      await this.$parent.getUsers(value);
      this.isLoading = false;
    },
    preguntarEnvioMensaje() {
      this.$swal({
        title: "¿Esta seguro de enviar el mensaje?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, enviar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendForm();
        } else {
          this.$swal.dismiss;
        }
      });
    },
    selectImagen() {
      this.message.files = this.$refs.file.files;
    },
    filesModal() {
      let listHtml = "<ol>";
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        let f = this.$refs.file.files.item(i);
        listHtml += `<li> ${f.name} </li>`;
      }
      listHtml += "</ol>";
      this.$swal({
        icon: "info",
        title: "<strong>Archivos adjuntos</strong>",
        html: listHtml,
        type: "custom-html",
        focusConfirm: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
    },
  },
  computed: {
    listTypeMessage() {
      if (this.roles.includes("Profesor")) {
        return this.typesMessage.filter((tm) => tm.id != 1);
      } else {
        return this.typesMessage;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.textArea-fixed {
  resize: none;
}
</style>
