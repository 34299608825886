<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    :class="[isRTL ? 'top-1 position-sticky z-index-sticky' : '']"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :text-white="textWhite" />
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
        :class="isRTL ? 'me-3' : ''"
      >
        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
            <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
            <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
          </div>
        </a>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="$store.state.isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <!-- <div class="input-group z-index-1">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              :placeholder="
                $store.state.isRTL ? 'أكتب هنا...' : 'Type here...'
              "
            />
          </div>-->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item dropdown d-flex align-items-center">
            <vsud-avatar
              :img="[user.photo ? user.photo : imgDefault]"
              class="text-success text-gradient"
              alt="avatar image"
              border-radius="lg"
              v-if="user"
            />
          </li>
          <li class="px-3 nav-link d-flex align-items-center">
            <div class="position-relative ml-2 span">
              <a
                id="drownoutlogOut"
                href="#"
                class="cursor-pointer p-0 nav-link"
                :class="[
                textWhite ? textWhite : 'text-body',
                logOut ? 'show' : '',
              ]"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="logOut = !logOut"
              >
                <span v-if="user" class="nav-link" :class="[
                textWhite ? textWhite : 'text-body']">
                  {{ " " + user.name + " " +
                  user.lastname }}
                </span>
              </a>

              <ul
                v-if="logOut"
                class="px-2 py-1 dropdown-menu dropdown-menu-end me-sm-n4 show"
                aria-labelledby="drownoutlogOut"
              >
                <li class="mb-1">
                  <a class="dropdown-item border-radius-md">
                    <div class="py-1 d-flex">
                      <div class="d-flex flex-column">
                        <h6 class="mb-1 text-sm font-weight-normal">
                          <div class="row">
                            <div class="col-2 ml-auto">
                              <font-awesome-icon :icon="['fass', 'right-to-bracket']" />
                            </div>
                            <div class="col-10 ml-auto">
                              <span class="font-weight-bold d-flex" @click="borrarToken">
                                Cerrar
                                sesión
                              </span>
                            </div>
                          </div>
                        </h6>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a id="iconNavbarSidenav" class="p-0 nav-link text-body" @click="toggleSidebar">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <!-- <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link" :class="textWhite ? textWhite : 'text-body'"
              @click="$store.dispatch('showConfigurator')">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>-->
          <li
            class="px-3 nav-item d-flex align-items-center"
            :class="$store.state.isRTL ? 'ps-2' : 'pe-2'" id="navbar-notification"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              class="p-0 nav-link"
              :class="[
              textWhite ? textWhite : 'text-body',
              showMenu ? 'show' : '',
            ]"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
            <ul
              v-if="showMenu"
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4 show dropdownNotification"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New message</span> from
                        Laur
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/small-logos/logo-spotify.svg"
                        class="avatar avatar-sm bg-gradient-dark me-3"
                        alt="logo spotify"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New album</span> by
                        Travis Scott
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto avatar avatar-sm bg-gradient-secondary me-3">
                      <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 43 36"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>credit-card</title>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g
                            transform="translate(-2169.000000, -745.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path
                                  class="color-background"
                                  d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                  opacity="0.593633743"
                                />
                                <path
                                  class="color-background"
                                  d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">Payment successfully completed</h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import VsudAvatar from "@/components/VsudAvatar.vue";
//import imgDefault from "../../assets/img/user-default.png";
import { mapMutations, mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  name: "Navbar",

  components: {
    Breadcrumbs,
    VsudAvatar,
    //imgDefault,
  },
  props: {
    textWhite: {
      type: String,
      default: "",
    },
    minNav: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showMenu: false,
      user: null,
      logOut: false,
      isHovered: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  created() {
    this.minNav;
  },
  methods: {
    async borrarToken() {
      this.$store.commit('setLoading',true);
      try {
        const { status, data } = await axios.get(
          `${process.env.VUE_APP_API_BOOKAPP}logout`,
          {
            headers: {
              Authorization: localStorage.getItem("token_bookapp"),
              conexion: localStorage.getItem("conexion"),
            },
          }
        );
        if (status == 200) {
          localStorage.removeItem("token_bookapp");
          localStorage.removeItem("conexion");
          localStorage.removeItem("token_manage");
          localStorage.removeItem("user");
          localStorage.removeItem("school");
          localStorage.removeItem("permissions");
          localStorage.removeItem("roles");
          location.reload();
        } else {
          console.log("error", data);
        }
      } catch (error) {
        console.log("error al cerrar session: ", error);
      }finally{
        this.$store.commit('setLoading',false);
      }
    },
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      // console.log("desde la funcion toggle");
      this.navbarMinimize();
    },
  },
};
</script>
<style scoped>
span.nav-link:hover {
  color: #f06c30;
}
</style>