<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="card mb-4">
          <div class="card-body p-3 pt-0 pb-0">
            <div class="row mt-5">
              <div v-if="course" class="col-lg-8 col-md-8 col-12">
                <div class="d-flex">
                  <div>
                    <vsud-avatar
                      :img=" imgDefault"
                      class="me-3"
                      size="xxl"
                      border-radius="lg"
                      alt="product image"
                    />
                  </div>
                  <div>
                    <h6 class="text-lg mb-0 mt-2">{{ course.name }}</h6>
                    <p class="text-sm mb-3 d-flex">
                      <label for>Cantidad de estudiantes maxima{{ ': '+course.quota }}</label>
                    </p>
                    <div
                      v-if="hasPermission('users.courses.update')"
                      style="cursor: pointer"
                      class="nav-wrapper position-relative end-0"
                    >
                      <font-awesome-icon :icon="['fass', 'pen-to-square']" />
                      <a @click="editCourse(course.id)">editar</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12 my-auto text-end">
                <vsud-button
                  color="dark"
                  @click="viewSchedule(course.id)"
                  variant="gradient"
                  class="mb-0"
                >Ver horario</vsud-button>
              </div>
            </div>
            <hr class="horizontal dark mt-4 mb-4" />
            <div class="row mt-5">
              <label>
                <h5>Asignaturas asignadas</h5>
              </label>
              <div v-if="course" class="col-lg-8 col-md-8 col-12">
                <ul class="list-group">
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-6">ID</div>
                      <div class="col-6">Asignatura</div>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    v-for="subject in course.relation_course_subject"
                    :key="subject.id"
                  >
                    {{ }}
                    <div class="row">
                      <div class="col-6">{{subject.id}}</div>
                      <div
                        class="col-6"
                      >{{ subject.subject.name}}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgDefault from "@/assets/img/courses.png";
import VsudButton from "@/components/VsudButton.vue";
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";
import router from "@/router/index.js";

export default {
  name: "CourseDetail",
  components: {
    VsudButton,
    VsudAvatar,
    VsudBadge,
  },
  data() {
    return { imgDefault, course: {}, permissionsArray: [], flag: false };
  },
  mixins: [permissionsMixin],
  methods: {
    viewSchedule(id) {
      console.log(id);
      router.push({
        path: `/cursos/horario/${id}`,
        replace: true,
      });
    },
    editCourse(id) {
      console.log(id);
      router.push({
        path: `/cursos/editar/${id}`,
        replace: true,
      });
    },
    async getCourse(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}courses/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.course = data;
      }
    },
  },
  mounted() {
    this.getCourse(this.$route.params.id_curso);
  },
};
</script>
