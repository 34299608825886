<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert
                v-show="alertShow"
                class="mt-1 mx-6 text-center"
                :color="colorAlert"
              >{{ textAlert }}</vsud-alert>
            </div>
            <div class="multisteps-form__progress mb-6">
              <button
                class="multisteps-form__progress-btn js-active"
                type="button"
                :class="activeStep >= 0 ? activeClass : ''"
              >
                <span>Información de usuario</span>
              </button>
              <button
                class="multisteps-form__progress-btn"
                type="button"
                :class="activeStep >= 1 ? activeClass : ''"
              >Parentescos y acudientes</button>
              <button
                class="multisteps-form__progress-btn"
                type="button"
                :class="activeStep >= 2 ? activeClass : ''"
              >Inscripción</button>
            </div>
          </div>
          <!--form panels-->
          <div class="text-end">
            <div class="Listar estudiantes">
              <router-link to="/usuarios/estudiantes">
                <button type="button" class="btn bg-gradient-dark">Listado</button>
              </router-link>
            </div>
            <router-view />
          </div>
          <div class="row">
            <div class="col-12 col-lg-12 m-auto">
              <user-info
                :typeDocument="typeDocument"
                :genders="genders"
                @saveData="asingUserInfo"
                :roles="roles"
                :errors="errors"
                :bloodTypes="bloodTypes"
                :eps="eps"
                :isFinalTab="false"
                :multiRole="false"
                :validate="validateUserTab"
                :class="activeStep === 0 ? activeClass : ''"
              />
              <kinship
                @saveDataKinship="asingUserKinship"
                :class="activeStep === 1 ? activeClass : ''"
                :typeKinship="typeKinship"
              />
              <inscription
                @saveDataInscription="asingUserInscription"
                :isFinalTab="true"
                :courses="courses"
                :class="activeStep === 2 ? activeClass : ''"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfo from "./components/UserInfo.vue";
import Kinship from "./components/Kinship.vue";
import Inscription from "./components/Inscription.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";

export default {
  name: "NewUser",
  components: {
    UserInfo,
    Kinship,
    Inscription,
    VsudAlert,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      typeDocument: null,
      typeKinship: null,
      genders: null,
      courses: null,
      bloodTypes: null,
      newUser: null,
      userKinship: null,
      userInscription: null,
      errors: [],
      validateUserTab: false,
      validateInscriptionTab: false,
      validateKinshipTab: false,
      nextTab: null,
      eps: null,
    };
  },
  methods: {
    async saveUser() {
      this.errors = [];
      const userForm = { ...this.newUser };
      this.activeStep = 0;
      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}users`, this.newUser, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          this.newUser = response.data;
          if (userForm.image != null) {
            this.saveUserImage(userForm.image);
          }
          this.alertShow = true;
          this.textAlert = "El usuario se creo correctamente.";
          this.colorAlert = "success";
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.alertShow = false;
            this.textAlert = null;
            this.colorAlert = null;
          }, 3000);

          this.activeStep = 1;
        })
        .catch((e) => {
          if (e.response.status == 422) {
            let errors = e.response.data.errors;
            errors = Object.values(errors);
            errors.forEach((error) => {
              error.forEach((e) => {
                this.errors.push(e);
              });
            });
          } else {
            this.alertShow = true;
            this.textAlert =
              "Hubo un error al crear el usuario, intente nuevamente.";
            this.colorAlert = "danger";
            setTimeout(() => {
              this.alertShow = false;
              this.textAlert = null;
              this.colorAlert = null;
            }, 3000);
          }
        });
    },
    async createKinship() {
      this.userKinship.student_id = this.newUser.id;
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}kinships/attendant`,
        this.userKinship,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.alertShow = true;
        this.textAlert = "Acudientes aignado correctamente.";
        this.colorAlert = "success";
        this.activeStep = 2;
      } else {
        this.alertShow = true;
        this.textAlert = "No se le pudo asignar acudiente al estudiante.";
        this.colorAlert = "danger";
      }
    },
    async createInscription() {
      this.userInscription.school_id = 1;
      this.userInscription.user_id = this.newUser.id;
      this.userInscription.course_id = this.userInscription.course_id.id;
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}inscriptions`,
        this.userInscription,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.alertShow = true;
        this.textAlert = "Se logro inscribir correctamente al curso.";
        this.colorAlert = "success";
        this.activeStep = 0;
      } else {
        this.alertShow = true;
        this.textAlert = "No se logro inscribir al estudiante al curso.";
        this.colorAlert = "danger";
      }
    },
    async saveUserImage(image) {
      let data = new FormData();
      data.append("image", image);
      data.append("_method", "PUT");
      axios
        .post(
          `${process.env.VUE_APP_API_BOOKAPP}users/save-image/${this.newUser.id}`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("token_bookapp"),
              conexion: localStorage.getItem("conexion"),
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {})
        .catch();
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    async getTypeDocument() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}type-documents`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.typeDocument = data;
      } else {
        console.log("error", data);
      }
    },
    async getTypeKinship() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}type-kinships`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.typeKinship = data;
      } else {
        console.log("error", data);
      }
    },
    async getGenders() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}genders`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.genders = data;
      } else {
        console.log("error", data);
      }
    },
    async getBloodTypes() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}blood-types`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.bloodTypes = data;
      } else {
        console.log("error", data);
      }
    },
    async getCourses() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}courses-all/null`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.courses = data;
      } else {
        console.log("error", data);
      }
    },
    asingUserInfo(user) {
      user.rol = 3;
      this.newUser = user;
      this.saveUser();
    },
    asingUserKinship(userKinship) {
      this.userKinship = userKinship;
      this.createKinship();
    },
    asingUserInscription(userInscription) {
      this.userInscription = userInscription;
      this.createInscription();
    },
    validateAndPass(nextTab) {
      this.nextTab = nextTab;
    },
    async getEps() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}eps`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.eps = data;
      } else {
        console.log("error", data);
      }
    },
  },

  mounted() {
    this.getTypeDocument();
    this.getTypeKinship();
    this.getBloodTypes();
    this.getGenders();
    this.getCourses();
    this.getEps();
  },
};
</script>
