<template>
  <div class="card send-to-back" :class="darkMode ? 'bg-gradient-dark' : ''">
    <div class="pb-0 card-header" :class="darkMode ? 'bg-transparent' : ''">
      <h6 v-if="messages[0]" :class="darkMode ? 'text-white' : ''">Asunto: {{ messages[0].subject }}</h6>
      <h7
        style="cursor: pointer;color:#1155cc"
        @click="showParticipants"
        v-if="messages[0]"
        :class="darkMode ? 'text-white' : ''"
      >Participantes</h7>
    </div>
    <div class="p-3 card-body">
      <div class>
        <div class="mx-3 row timeline timeline-one-side">
          <div v-for="message in messages" :key="message.id" class="mb-3 timeline-block">          
            <div class="row">
              <div class="col-1">
                <vsud-avatar
                  :img="[message.sender.photo ? message.sender.photo : imgDefault]"
                  class="text-success text-gradient"
                  alt="avatar image"
                  border-radius="lg"
                  :badge="message.sender.name"
                />
              </div>              
              <div class="timeline-container w-90 row d-flex">
                <h6
                  class="mb-0 text-sm text-dark font-weight-bold"
                >{{ message.sender.name }} {{ message.sender.lastname }}</h6>
                <p
                  class="mt-1 mb-0 text-xs text-secondary font-weight-bold"
                >{{ message.message_updated_at }}</p>
                <p v-html="message.message" class="mt-3 mb-2 text-sm"></p>
              </div>
              <div @click="filesModal(message.files)" class="mb-3" v-if="message.files.length > 0">
                <div class="icon me-2">
                  <svg
                    class="text-dark mb-1"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>Archivos</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-1870.000000, -591.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path
                              class="color-background"
                              d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                              opacity="0.603585379"
                            />
                            <path
                              class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span class="text-sm">Ver archivos</span>
              </div>
            </div>
          </div>
        </div>
        <QuillEditor style="min-height:200px;" ref="editor" theme="snow" />
        <div class="row">
          <div class="col-6 mt-3">
            <vsud-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto"
              @click="preguntarEnvioMensaje"
            >Enviar</vsud-button>
          </div>
          <div class="col-6 mt-3">
            <input
              @change="selectImagen()"
              accept=".png, .jpg, .jpeg, .gif, .pdf, .docx"
              ref="file"
              type="file"
              id="files"
              class="multisteps-form__input form-control"
              name="files"
              multiple
            />
          </div>
        </div>
      </div>
    </div>
    <ParticipantsModal
      v-if="messages[0]"
      :visible="modalVisible"
      @cancelSave="closeModal"
      :participants="messages[0].participantsGenerals"
    ></ParticipantsModal>
  </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";
import VsudButton from "@/components/VsudButton.vue";
import axios from "axios";
import router from "@/router/index.js";
import ParticipantsModal from "./ParticipantsModal.vue";

export default {
  name: "Chat",
  components: {
    VsudAvatar,
    VsudButton,
    ParticipantsModal,
  },
  props: {
    darkMode: Boolean,
    title: { type: String, default: "" },
    messages: { type: Object, require: true },
  },
  data() {
    return {
      imgDefault,
      message: {
        type_message_id: null,
        school_id: 1,
        sender_id: null,
        status: "active",
        receiver: [],
        message: null,
        message_text: null,
        privacity: "2",
        subject: null,
        stundent_id: null,
      },
      modalVisible: false,
      files: [],
      user: JSON.parse(localStorage.getItem("user")),
      // editor : this.$refs.editor
    };
  },

  methods: {
    closeModal() {
      // this.estado = null;
      this.modalVisible = false;
    },
    showParticipants() {
      // alert("participantes");
      this.modalVisible = true;
    },
    preguntarEnvioMensaje() {
      this.$swal({
        title: "¿Esta seguro de enviar el mensaje?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, enviar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.enviarMensaje();
        } else {
          this.$swal.dismiss;
        }
      });
    },
    enviarMensaje() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (this.messages[this.messages.length - 1].type_message_id == 3) {
        this.formatMesssageType3();
      }
      if (this.messages[this.messages.length - 1].type_message_id == 2) {
        this.formatMesssageType2();
      }

      if (this.messages[this.messages.length - 1].type_message_id == 1) {
        this.formatMesssageType1();
      }

      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}messages/save`, this.message, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          const messageNew = response.data;
          if (this.files.length > 0) {
            this.saveFiles(this.files, messageNew);
          }
          console.log("desde el then", response);
          if (response.status == 200) {
            this.$swal({
              title: "Exito!",
              text: "El mensaje se ha enviado correctamente.",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
            router.push({
              name: "Listar mensajes enviados",
              replace: true,
            });
            // vm.$forceUpdate();
            // request = {
            //   type_message_id: null,
            //   school_id: 1,
            //   sender_id: null,
            //   status: "active",
            //   receiver: [],
            //   message: null,
            //   message_text: null,
            //   privacity: "2",
            //   subject: null,
            //   stundent_id: null,
            // };
          }
        })
        .catch((e) => {
          console.log("desde el catch", e);
          this.$swal({
            title: "Algo salió mal!",
            text:
              "Hubo un error al enviar el mensaje, por favor intente de nuevo.",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
        });
    },
    filesModal(files) {
      let listHtml = "<ol>";
      files.forEach((f) => {
        listHtml += `<li> <a target="_blank" href="${f.file_url}"> ${f.name} </a></li>`;
      });
      // this.$refs.file.files.forEach((f) => {
      //   listHtml += `<li> ${f.name} </li>`;
      // });
      listHtml += "</ol>";
      this.$swal({
        icon: "info",
        title: "<strong>Archivos adjuntos</strong>",
        html: listHtml,
        type: "custom-html",
        focusConfirm: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
    },
    async saveFiles(files, messageNew) {
      const formDataSend = new FormData();
      for (let i = 0; i < files.length; i++) {
        formDataSend.append("files[]", files[i]);
      }
      formDataSend.append("message", JSON.stringify(messageNew));
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}attachment`,
        formDataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        console.log("error", data);
      }
    },
    selectImagen() {
      this.files = this.$refs.file.files;
    },
    formatMesssageType1() {
      const editor = this.$refs.editor;
      let ids = [];
      let student;
      this.messages.forEach((m) => {
        if (this.user.id != m.sender_id) {
          ids.push(m.sender_id);
        }
      });

      if (this.messages[this.messages.length - 1].privacity == "private") {
        this.messages.forEach((m) => {
          if (m.student_id) {
            student = m.student_id;
            return 0;
          }
        });
      }

      this.message = {
        type_message_id: this.messages[this.messages.length - 1]
          .type_message_id,
        school_id: 1,
        sender_id: this.user.id,
        receiver: ids,
        message: editor.getHTML(),
        privacity: this.messages[this.messages.length - 1].privacity,
        subject: this.messages[this.messages.length - 1].subject,
        student_id: student,
        channel: this.messages[0].message_id,
      };
    },
    formatMesssageType2() {
      const editor = this.$refs.editor;
      let ids = [];
      let student = null;
      this.messages.forEach((m) => {
        if (this.user.id != m.sender_id) {
          ids.push(m.sender_id);
        }
      });

      if (this.messages[this.messages.length - 1].privacity == "private") {
        this.messages.forEach((m) => {
          if (m.student_id) {
            student = m.student_id;
            return 0;
          }
        });
      }

      this.message = {
        type_message_id: this.messages[this.messages.length - 1]
          .type_message_id,
        school_id: 1,
        sender_id: this.user.id,
        receiver: ids,
        message: editor.getHTML(),
        privacity: this.messages[this.messages.length - 1].privacity,
        subject: this.messages[this.messages.length - 1].subject,
        student_id: student,
        channel: this.messages[0].message_id,
      };
    },
    formatMesssageType3() {
      const editor = this.$refs.editor;
      let ids = [];
      let student;
      this.messages.forEach((m) => {
        if (this.user.id != m.sender_id) {
          ids.push(m.sender_id);
        }
      });

      if (this.messages[this.messages.length - 1].privacity == "private") {
        this.messages.forEach((m) => {
          if (m.student_id) {
            student = m.student_id;
            return 0;
          }
        });
      }

      this.message = {
        type_message_id: this.messages[this.messages.length - 1]
          .type_message_id,
        school_id: 1,
        sender_id: this.user.id,
        receiver: ids,
        message: editor.getHTML(),
        privacity: this.messages[this.messages.length - 1].privacity,
        subject: this.messages[this.messages.length - 1].subject,
        student_id: student,
        channel: this.messages[0].message_id,
      };
    },
  },
  mounted() {},
};
</script>
<style>
.timeline-container {
  margin-left: 7%;
}

.timeline-item {
  width: 100%;
}
</style>
