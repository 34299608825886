<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li>
        <h6
          v-if="school"
          class="text-center text-uppercase font-weight-bolder opacity-6"
          :class="$store.state.isRTL ? 'me-4' : 'ms-2'"
        >{{ school.name}}</h6>
        <div class="text-center mb-4">
          <SchoolShield />
        </div>
      </li>
      <li
        v-if="hasPermission('users.administratives.index') || hasPermission('users.teachers.index') || hasPermission('users.attendant.index') || hasPermission('users.students.index')"
        class="nav-item"
      >
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Usuarios"
          :class="getRoute() === 'usuarios' ? 'active' : ''"
        >
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-users" />
            <!--  <icon name="pages" /> -->
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                refer="administrativos"
                v-show="hasPermission('users.administratives.index')"
                mini-icon="A"
                text="Administrativos"
                :to="{ name: 'listado administrativos' }"
              ></sidenav-item>
              <sidenav-item
                v-show="hasPermission('users.teachers.index')"
                :to="{ name: 'listado profesores' }"
                refer="profesores"
                mini-icon="P"
                text="Profesores"
              ></sidenav-item>
              <sidenav-item
                v-show="hasPermission('users.attendant.index')"
                :to="{ name: 'listado acudientes' }"
                refer="usersExampleA"
                mini-icon="A"
                text="Acudientes"
              ></sidenav-item>
              <sidenav-item
                v-show="hasPermission('users.students.index')"
                :to="{ name: 'listado estudiantes' }"
                refer="usersExample"
                mini-icon="E"
                text="Estudiantes"
              ></sidenav-item>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li
        v-if="hasPermission('roles.index') || hasPermission('roles.create') || hasPermission('roles.update') || hasPermission('roles.show') || hasPermission('permissions.index') || hasPermission('permissions.create') || hasPermission('permissions.update') || hasPermission('permissions.show')"
        class="nav-item"
      >
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Roles y permisos"
          :class="getRoute() === 'roles-permisos' ? 'active' : ''"
        >
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-lock" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'listado roles' }"
                refer="roles"
                mini-icon="R"
                text="Roles"
              >
                <!-- <template #nav-child-item>
                  <sidenav-item :to="{ name: 'listado roles' }" mini-icon="R" text="Listar roles" />
                  <sidenav-item :to="{ name: 'Nuevo rol' }" mini-icon="N" text="Crear rol" />
                </template>-->
              </sidenav-item>
              <sidenav-item
                :to="{ name: 'listado permisos' }"
                refer="permisos"
                mini-icon="P"
                v-show="hasPermission('permissions.index')"
                text="Permisos"
              >
                <!-- <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'listado permisos' }"
                    mini-icon="R"
                    text="Listar permisos"
                  />
                  <sidenav-item :to="{ name: 'Nuevo permiso' }" mini-icon="N" text="Crear permiso" />
                </template>-->
              </sidenav-item>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="hasPermission('users.subjects.index')" class="nav-item">
        <router-link to="/materias">
          <sidenav-collapse
            collapse-ref="/materias/materias"
            nav-text="Asignaturas"
            :class="getRoute() === 'materias' ? 'active' : ''"
            :collapse="false"
            url="#"
            to="/materias"
            router-link="/materias"
            :aria-controls="''"
          >
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-book" />
            </template>
            <!-- <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                :to="{ name: 'Listado materias' }"
                mini-icon="R"
                text="Listar asignaturas"
              />
              <sidenav-item :to="{ name: 'Nueva materia' }" mini-icon="N" text="Crear asignatura" />
            </ul>
            </template>-->
          </sidenav-collapse>
        </router-link>
      </li>
      <li
        v-if="hasPermission('users.messages.store') || hasPermission('users.messages.index') || hasPermission('users.messages.approve')"
        class="nav-item"
      >
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Mensajes"
          :class="getRoute() === 'mensajes' ? 'active' : ''"
        >
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-envelope" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                v-show="hasPermission('users.messages.store')"
                :to="{ name: 'Nuevo mensaje' }"
                mini-icon="C"
                text="Crear"
              />
              <sidenav-item
                v-show="hasPermission('users.messages.index')"
                :to="{ name: 'Listar mensajes no leidos' }"
                mini-icon="N"
                text="No Leídos"
              />
              <sidenav-item
                v-show="hasPermission('users.messages.index')"
                :to="{ name: 'Listar mensajes recibidos' }"
                mini-icon="R"
                text="Recibidos"
              />
              <sidenav-item
                v-show="hasPermission('users.messages.index')"
                :to="{ name: 'Listar mensajes enviados' }"
                mini-icon="E"
                text="Enviados"
              />
              <sidenav-item
                v-show="hasPermission('users.messages.approve')"
                :to="{ name: 'Listar mensajes aprobar' }"
                mini-icon="P/A"
                text="Por Aprobar"
              />
               <sidenav-item
                v-show="hasPermission('users.messages.approve')"
                :to="{ name: 'Listar mensajes generales' }"
                mini-icon="A"
                text="Mensajes generales"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="hasPermission('users.courses.index')" class="nav-item">
        <router-link to="/cursos/listar">
          <sidenav-collapse
            collapse-ref="pagesExamples"
            nav-text="Cursos"
            :class="getRoute() === 'cursos' ? 'active' : ''"
            :collapse="false"
            url="#"
          >
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-graduation-cap" />
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
      <li
        v-if="hasPermission('calendar.index') || hasPermission('calendar.feeding.index')"
        class="nav-item"
      >
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Calendarios"
          :class="getRoute() === 'calendarios' ? 'active' : ''"
        >
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-calendar-days" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                v-show="hasPermission('calendar.index')"
                :to="{ name: 'calendario general' }"
                mini-icon="G"
                text="General"
              />
              <sidenav-item
                v-show="hasPermission('calendar.feeding.index')"
                :to="{ name: 'calendario alimentacion' }"
                mini-icon="A"
                text="Alimentación"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="hasPermission('school.refill.index')" class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Recargas"
          :class="getRoute() === 'recargas' ? 'active' : ''"
        >
          <template #icon>
            <font-awesome-icon icon="fa-sharp fa-solid fa-hand-holding-dollar" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                v-show="hasPermission('school.restaurant_rate.index')"
                :to="{ name: 'Tarifa general' }"
                mini-icon="T"
                text="Tarifas"
              />
              <sidenav-item
                v-show="hasPermission('school.special_restaurant_rate.index')"
                :to="{ name: 'Tarifas especiales' }"
                mini-icon="T/E"
                text="Tarifas especiales"
              />
              <sidenav-item
                v-show="hasPermission('school.refill.store')"
                :to="{ name: 'Recarga manual' }"
                mini-icon="R"
                text="Recargar"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li
        v-if="hasPermission('school.transactions.store') || hasPermission('school.transactions.index')"
        class="nav-item"
      >
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Almuerzos"
          :class="getRoute() === 'descargas' ? 'active' : ''"
        >
          <template #icon>
            <font-awesome-icon icon="fa-sharp fa-solid fa-utensils" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                v-show="hasPermission('school.transactions.store')"
                :to="{ name: 'Restaurante almuerzos' }"
                mini-icon="A"
                text="Almuerzos"
              />
              <sidenav-item
                v-show="hasPermission('school.transactions.index')"
                :to="{ name: 'Restaurante movimientos' }"
                mini-icon="M"
                text="Movimientos"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li
        v-if="hasPermission('call_attentions.index') || hasPermission('call_attentions.create')  || hasPermission('call_attentions.approve') || hasPermission('call_attentions.show')"
        class="nav-item"
      >
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Observaciones"
          :class="getRoute() === 'llamados-atencion' ? 'active' : ''"
        >
          <template #icon>
            <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                v-show="hasPermission('call_attentions.create')"
                :to="{ name: 'Llamados atención crear' }"
                mini-icon="R"
                text="Crear"
              />
              <sidenav-item
                :to="{ name: 'Llamados atención enviados' }"
                mini-icon="E"
                text="Enviados"
              />
              <sidenav-item
                :to="{ name: 'Llamados atención aprobar' }"
                mini-icon="P/A"
                text="Por Aprobar"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import SchoolShield from "@/views/pages/Users/SchoolShield";
import { mapState } from "vuex";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,

    SidenavCollapseItem,
    SchoolShield,
  },
  mixins: [permissionsMixin],
  props: {
    cardBg: { type: String, default: "" },
  },
  computed: {
    ...mapState(["school"]),
  },
  data() {
    return {
      title: "Vite Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      userRoles: [],
      userPermissions: [],
      school: null,
    };
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  mounted() {
    this.school = JSON.parse(localStorage.getItem("school"));
  },
};
</script>
