<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
                {{ textAlert
                }}
              </vsud-alert>
            </div>
            <div class="col-12 col-lg-8 mx-auto my-5">
              <!-- <h3>Formulario de registro para un curso.</h3> -->
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 m-auto">
              <form class="multisteps-form__form mb-5">
                <form-edit-course
                  @sendForm="saveCourseData"
                  :errors="errors"
                  :subjects="subjects"
                  class="js-active position-relative"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormEditCourse from "./components/FormEditCourse.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";
import router from "@/router/index.js";

export default {
  name: "EditCourse",
  components: {
    FormEditCourse,
    VsudAlert,
  },
  data() {
    return {
      arrayErrors: [],
      errors: [],
      course: null,
      subjects: null,
      alertShow: false,
      colorAlert: null,
      textAlert: null,
    };
  },

  methods: {
    async getCourse(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}courses/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.course = data;
      }
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
    async getSubjects() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}subjects`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.subjects = data;
      } else {
        console.log("error", data);
      }
    },
    async saveCourseData(dataSend) {
      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}courses/${dataSend.id}`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.alertShow = true;
        this.textAlert = "El curso se guardo correctamente.";
        this.colorAlert = "success";
        window.scrollTo(0, 0);
        this.removeAlert();
        router.push({
          path: `/cursos/ver/${dataSend.id}`,
          replace: true,
        });
      } else {
        console.log("error", data);
        this.alertShow = true;
        this.textAlert = "El curso no se edito correctamente.";
        this.colorAlert = "danger";
        window.scrollTo(0, 0);
        this.removeAlert();
      }
    },
  },
  mounted() {
    this.getCourse(this.$route.params.id_curso);
    this.getSubjects();
  },
};
</script>
