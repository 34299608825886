<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="card mb-4">
          <div class="card-body p-3 pt-0 pb-0">
            <div class="row mt-5">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="d-flex">
                  <div>
                    <vsud-avatar
                      :img="engranes"
                      class="me-3"
                      size="xxl"
                      border-radius="lg"
                      alt="product image"
                    />
                  </div>
                  <div>
                    <h6 class="text-lg mb-0 mt-2">
                      {{ permission.description }}
                    </h6>
                    <p class="text-sm mb-1">
                      {{ permission.name + " - " + permission.guard_name }}
                    </p>
                    <!-- <label class="text-sm mb-3">{{ permission.module }}</label> -->
                    <p class="text-sm mb-3">
                      <b>{{ permission.module }}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12 my-auto text-end">
                <!-- <vsud-button @click="editPermission(permission.id)" color="info" variant="gradient" class="mb-0"
                  >Editar permiso</vsud-button
                > -->
                <div style="cursor: pointer" class="nav-wrapper position-relative end-0">
              <font-awesome-icon :icon="['fass', 'pen-to-square']" />
              <a @click="editPermission(permission.id)"> editar </a>
            </div>
              </div>
            </div>
            <hr class="horizontal dark mt-4 mb-4" />
            <div class="row">
              <div
                class="
                  col-12
                  align-middle
                  text-center text-sm text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                  ps-2
                "
              >
                <h6 class="mb-3">Roles</h6>
              </div>
            </div>
            <div v-if="flag" class="row align-middle text-center">
              <Table :roles="permission.roles" />
            </div>
            <div v-else class="row align-middle text-center">
              <h5>No hay permisos asignados</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import engranes from "@/assets/img/engranes.png";
import axios from "axios";
import Table from "./components/TableRolesDetail.vue";
import router from "@/router/index.js";

export default {
  name: "RoleDatail",
  components: {
    Table,
    VsudButton,
    VsudAvatar,
    VsudBadge,
  },
  data() {
    return { engranes, permission: {}, flag: false };
  },
  methods: {
    editPermission(id) {
      router.push({
        path: `/roles-permisos/permisos/editar/${id}`,
        replace: true,
      });
    },
    async getPermission(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}permissions/by-id/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.permission = data;
        if (this.permission.roles.length > 0) {
          this.flag = true;
        } else {
          this.flag = false;
        }
      }
    },
  },
  mounted() {
    this.getPermission(this.$route.params.id);
   
  },
};
</script>
