<template>
  <div class="card" :class="darkMode ? 'bg-gradient-dark' : ''">
    <div class="pb-0 card-header" :class="darkMode ? 'bg-transparent' : ''">
      <h6 :class="darkMode ? 'text-white' : ''">Mensajes</h6>
      <h7
        style="cursor: pointer"
        @click="showParticipants"
        v-if="messages[0]"
        :class="darkMode ? 'text-white' : ''"
      >Participantes</h7>
    </div>
    <div class="p-3 card-body">
      <div class="mx-3 row timeline timeline-one-side">
        <div v-for="(message ) in messages" :key="message.id" class="mb-3 timeline-block">
          <!-- <span class="timeline-step " :class="darkMode ? 'bg-dark' : ''">
          </span>-->
          <div class="row">
            <div class="col-1">
              <vsud-avatar
                :img="[message.sender.photo ? message.sender.photo : imgDefault]"
                class="text-success text-gradient"
                alt="avatar image"
                border-radius="lg"
              />
            </div>
            <div class="col-4">
              <h5 class="mb-0 text-sm text-dark font-weight-bold">{{ message.subject }}</h5>
            </div>
            <div v-if="message.message_status !='published'" class="col-7">
              <button
                @click="estado = 'published'"
                type="button"
                class="btn btn-success btn-separados"
                style="background-color: #7EED30; border-color: green; color: white;"
              >Publicar</button>
              <button @click="estado = 'rejected'" type="button" class="btn btn-danger">Rechazado</button>
            </div>
          </div>

          <div class="timeline-content">
            <div class="timeline-container w-90">
              <h6 class="mb-0 text-sm text-dark font-weight-bold">{{ message.sender.name }}</h6>
              <p class="mt-1 mb-0 text-xs text-secondary font-weight-bold">{{ message.message_updated_at }}</p>
              <p v-html="message.message" class="mt-3 mb-2 text-sm"></p>
              <div @click="filesModal(message.files)" class="mb-3" v-if="message.files.length > 0">
                <div class="icon me-2">
                  <svg
                    class="text-dark mb-1"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>Archivos</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-1870.000000, -591.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path
                              class="color-background"
                              d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                              opacity="0.603585379"
                            />
                            <path
                              class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span class="text-sm">Ver archivos</span>
              </div>
              <span class="badge badge-sm bg-gradient-success">Design</span>
            </div>
            <!-- <p class="mt-1 mb-0 text-xs text-secondary font-weight-bold">{{ message.created_at }}</p>
            <p class="mt-3 mb-2 text-sm">{{ message.message }}</p>-->
            <!-- <span class="badge badge-sm bg-gradient-success">Design</span> -->
          </div>
        </div>
      </div>
    </div>
    <ParticipantsModal
      v-if="messages[0]"
      :visible="modalVisibleParticipants"
      @cancelSave="closeModalParticipants"
      :participants="messages[0].participantsGenerals"
    ></ParticipantsModal>
    <CommentStatusModal :visible="modalVisible" @cancelSave="closeModal" @saveComment="saveComment"></CommentStatusModal>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";
import CommentStatusModal from "./CommentStatusModal.vue";
import VsudButton from "@/components/VsudButton.vue";
import Multiselect from "vue-multiselect";
import { mapState, mapActions, mapMutations } from "vuex";
import ParticipantsModal from "./ParticipantsModal.vue";
export default {
  name: "Chat",
  components: {
    VsudAvatar,
    VsudButton,
    Multiselect,
    CommentStatusModal,
    ParticipantsModal,
  },
  props: {
    darkMode: Boolean,
    title: { type: String, default: "" },
    messages: { type: Object, require: true },
  },
  data() {
    return {
      imgDefault,
      modalVisible: false,
      modalVisibleParticipants: false,
      status: [
        {
          name: "Publicar",
          value: "published",
        },
        {
          name: "Rechazado",
          value: "rejected",
        },
      ],
      estado: null,
    };
  },
  watch: {
    estado(oldValue) {
      this.modalVisible = true;
    },
  },
  methods: {
    ...mapMutations(["setStatusCommentMessage"]),
    preguntarCambiarEstado(userId) {
      this.$swal({
        title: "¿Esta seguro de cambiar el estado de este mensaje?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cambiar estado!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cambiarEstado(userId);
        } else {
          this.$swal.dismiss;
        }
      });
    },
     showParticipants() {
      // alert("participantes");
      this.modalVisibleParticipants = true;
    },
    async cambiarEstado(userId) {
      // const { status } = await axios.post(`${process.env.VUE_APP_API_BOOKAPP}users/change-status/${userId}`, {
      //   headers: {
      //     Authorization: localStorage.getItem("token_bookapp"),
      //     conexion: localStorage.getItem("conexion"),
      //   },
      // });
      // if (status == 200) {
      //   this.$swal({
      //     title: "Exito!",
      //     text: "El estado del mensaje se cambio.",
      //     icon: "success",
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //     },
      //     buttonsStyling: false,
      //   });
      //   vm.$forceUpdate();
      // } else {
      //   this.$swal({
      //     title: "Algo salió mal!",
      //     text: "Hubo un error al cambiar el estado del mensaje, por favor intente de nuevo.",
      //     icon: "danger",
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //     },
      //     buttonsStyling: false,
      //   });
      // }
    },
    closeModal() {
      // this.estado = null;
      this.modalVisible = false;
    },
    closeModalParticipants() {
      // this.estado = null;
      this.modalVisibleParticipants = false;
    },
    saveComment() {
      this.setStatusCommentMessage(this.estado);
      this.$emit("saveComment");
      this.modalVisible = false;
    },
  },
  mounted() {
    // if (document.getElementById("editor")) {
    //   new Quill("#editor", {
    //     theme: "snow", // Specify theme in configuration
    //   });
    // }
    // this.getMessages(this.$route.params.id_usuario);
  },
};
</script>
<style>
.btn-separados {
  margin-right: 10px; /* Ajusta el valor según tu preferencia */
}
</style>
