<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert
                v-show="alertShow"
                class="mt-1 mx-6 text-center"
                :color="colorAlert"
              >{{ textAlert }}</vsud-alert>
            </div>
            <!-- <div class="col-12 col-lg-8 mx-auto my-5">
              <h3>Formulario de registro un rol.</h3>
            </div>-->
          </div>
          <!--form panels-->
          <div class="text-end">
                <div class="Listar roles">
                  <router-link to="/roles-permisos/roles"><button type="button" class="btn bg-gradient-dark">Listado</button></router-link>
            </div>
            <router-view/>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form v-on:submit="sendForm($event)" class="multisteps-form__form mb-5">
                <form-role
                  @sendForm="saveRole"
                  :permissions="permissions"
                  :empty="empty"
                  :errors="errors"
                  :class="activeStep === 0 ? activeClass : ''"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormRole from "./components/FormRole.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";

export default {
  name: "NewUser",
  components: {
    FormRole,
    VsudAlert,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      permissions: null,
      errors: [],
      empty: false,
    };
  },
  methods: {
    async saveRole(role) {
      this.errors = [];
      const permissions = role.permissions;
      // delete role.permissions;
      console.log(role);
      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}roles`, role, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          // this.asingPermissions(response.data.id, permissions);
          role.name = null;
          role.guard_name = null;
          this.empty = true;
          this.alertShow = true;
          this.textAlert = "El rol se creo correctamente.";
          this.colorAlert = "success";
          window.scrollTo(0, 0);
          this.removeAlert();
        })
        .catch((e) => {
          if (e.response.status == 422) {
            let errors = e.response.data.errors;
            errors = Object.values(errors);
            errors.forEach((error) => {
              error.forEach((e) => {
                this.errors.push(e);
              });
            });
          } else {
            this.alertShow = true;
            this.textAlert =
              "Hubo un error al crear el rol, intente nuevamente.";
            this.colorAlert = "danger";
            this.removeAlert();
          }
        });
    },
    async asingPermissions(idRole, permissions) {
      let dataSend = { permissions: [], role: idRole };

      permissions.forEach((permission) => {
        dataSend.permissions.push(permission.id);
      });

      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}roles/assign-permissions`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (!status == 200) {
        console.log("error", data);
      }
    },
    async getPermissions() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}permissions`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.permissions = data;
        this.permissions.map((permission) => {
          permission.fullName =
            permission.name + " - " + permission.description;
        });
      } else {
        console.log("error", data);
      }
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  mounted() {
    this.getPermissions();
  },
};
</script>
