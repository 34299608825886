<template>
  <div class="container py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
                {{ textAlert
                }}
              </vsud-alert>
            </div>
            <div class="col-12 col-lg-8 my-3">
              <h3>
                <div>Horario de clases</div>
                <div class="orange-text">{{course.name}}</div>
              </h3>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-2">
              <vsud-button
                v-show="hasPermission('users.courses.subjects.create')"
                type="button"
                color="success"
                variant="gradient"
                class="ms-auto"
                @click="openModal()"
              >Agregar asignatura</vsud-button>
            </div>
            <div class="col-2">
              <vsud-button
                v-show="hasPermission('users.courses.subjects.create')"
                type="button"
                color="success"
                variant="gradient"
                class="btn bg-gradient-dark"            
                @click="modalMasiveVisible = !modalMasiveVisible"
              >Cargar horario</vsud-button>
            </div>
          </div>
          <div class="row mt-2">
            <table class="table table-sm column-table">
              <thead>
                <tr>
                  <th
                    style="background-color: #f06C30; color: white; border-radius: 12px; text-align: center;width: 20%"
                  >Lunes</th>
                  <th
                    style="background-color: #f06C30; color: white; border-radius: 12px; text-align: center;width: 20%"
                  >Martes</th>
                  <th
                    style="background-color: #f06C30; color: white; border-radius: 12px; text-align: center;width: 20%"
                  >Miércoles</th>
                  <th
                    style="background-color: #f06C30; color: white; border-radius: 12px; text-align: center;width: 20%"
                  >Jueves</th>
                  <th
                    style="background-color: #f06C30; color: white; border-radius: 12px; text-align: center;width: 20%"
                  >Viernes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="background-color: #D4D4D4;border-radius: 10px;width: 20%">
                    <schedule-list :schedule="dayOne" @removeItem="removeItem" @editItem="editItem"></schedule-list>
                  </td>
                  <td style="background-color: #D4D4D4;border-radius: 10px;width: 20%">
                    <schedule-list :schedule="dayTwo" @removeItem="removeItem" @editItem="editItem"></schedule-list>
                  </td>
                  <td style="background-color: #D4D4D4;border-radius: 10px;width: 20%">
                    <schedule-list
                      :schedule="dayThree"
                      @removeItem="removeItem"
                      @editItem="editItem"
                    ></schedule-list>
                  </td>
                  <td style="background-color: #D4D4D4;border-radius: 10px;width: 20%">
                    <schedule-list
                      :schedule="dayFour"
                      @removeItem="removeItem"
                      @editItem="editItem"
                    ></schedule-list>
                  </td>
                  <td style="background-color: #D4D4D4;border-radius: 10px;width: 20%">
                    <schedule-list
                      :schedule="dayFive"
                      @removeItem="removeItem"
                      @editItem="editItem"
                    ></schedule-list>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <schedule-modal
      @cancelSaveSubject="cancelSaveSubject"
      :visible="modalVisible"
      :subjects="subjects"
      :teachers="teachers"
      @saveItemSchedule="saveItemSchedule"
      :errors="errors"
      ref="modalComponentChild"
    ></schedule-modal>
    <edit-schedule-modal
      @cancelEditSubject="cancelEditSubject()"
      :visible="modalVisibleEdit"
      :subjects="subjects"
      :teachers="teachers"
      @editItemSchedule="editItemSchedule"
      :errors="errorsEdit"
      :eventEdit="eventEdit"
      ref="modalComponentChild"
    ></edit-schedule-modal>
    <UploadSchedules
      @saveAttendantsMasive="saveAttendantsMasive"
      @closeModal="cancelModalMasive"
      :visible="modalMasiveVisible"
    ></UploadSchedules>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import ScheduleModal from "./components/ScheduleModal.vue";
import UploadSchedules from "./components/UploadSchedules.vue";
import EditScheduleModal from "./components/EditScheduleModal.vue";
import ScheduleList from "./components/ScheduleList.vue";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "Schedule",
  components: {
    VsudAlert,
    ScheduleModal,
    VsudButton,
    ScheduleList,
    EditScheduleModal,
    UploadSchedules,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      modalMasiveVisible:false,
      modalVisible: false,
      modalVisibleEdit: false,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      errors: [],
      errorsEdit: [],
      empty: false,
      course: [],
      subjects: [],
      teachers: [],
      subjectsAsing: [],
      dayOne: [],
      dayTwo: [],
      dayThree: [],
      dayFour: [],
      dayFive: [],
      errors: [],
      scheduleItemsDb: [],
      eventEdit: null,
    };
  },
  watch: {},
  methods: {
    cancelModalMasive() {
      this.modalMasiveVisible = false;
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
    async getSubjects(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}courses-subjects/course/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.subjects = data;
      } else {
        console.log("error", data);
      }
    },
    async getCourse(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}courses/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.course = data;
      } else {
        console.log("error", data);
      }
    },
    async getScheduleCourse(id) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}schedule/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.scheduleItemsDb = data;
        this.scheduleItemsDb.forEach((iS) => {
          switch (iS.day) {
            case "1":
              this.dayOne.push({
                id: iS.id,
                subject: iS.course_subject.subject,
                teacher: iS.teacher,
                start: iS.start_time,
                end: iS.end_time,
                day: iS.day,
              });
              break;
            case "2":
              this.dayTwo.push({
                id: iS.id,
                subject: iS.course_subject.subject,
                teacher: iS.teacher,
                start: iS.start_time,
                end: iS.end_time,
                day: iS.day,
              });
              break;
            case "3":
              this.dayThree.push({
                id: iS.id,
                subject: iS.course_subject.subject,
                teacher: iS.teacher,
                start: iS.start_time,
                end: iS.end_time,
                day: iS.day,
              });
              break;
            case "4":
              this.dayFour.push({
                id: iS.id,
                subject: iS.course_subject.subject,
                teacher: iS.teacher,
                start: iS.start_time,
                end: iS.end_time,
                day: iS.day,
              });
              break;
            case "5":
              this.dayFive.push({
                id: iS.id,
                subject: iS.course_subject.subject,
                teacher: iS.teacher,
                start: iS.start_time,
                end: iS.end_time,
                day: iS.day,
              });
              break;
          }
        });
      } else {
        console.log("error", data);
      }
    },
    async getTeachers() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/rol/2`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.teachers = data;
        this.teachers.map((result) => {
          result.fullName = `${result.name} ${result.lastname}`;
        });
      } else {
        console.log("error", data);
      }
    },
    openModal() {
      this.modalVisible = true;
    },
    cancelSaveSubject() {
      this.modalVisible = false;
    },
    cancelEditSubject() {
      this.modalVisibleEdit = false;
    },
    findExtraObject(array1, array2) {
      // Creamos un mapa para almacenar los objetos y sus cantidades en array1
      const objectCountMap = {};

      // Recorremos array1 y llenamos el mapa
      for (const obj of array1) {
        const objString = JSON.stringify(obj);
        objectCountMap[objString] = (objectCountMap[objString] || 0) + 1;
      }

      // Recorremos array2 y disminuimos las cantidades de ocurrencias en el mapa
      for (const obj of array2) {
        const objString = JSON.stringify(obj);
        if (objectCountMap[objString]) {
          objectCountMap[objString] -= 1;
          // Si llegamos a 0, eliminamos la propiedad del mapa para que no quede negativo
          if (objectCountMap[objString] === 0) {
            delete objectCountMap[objString];
          }
        } else {
          // Si el objeto no está en el mapa, significa que es el objeto extra
          return obj;
        }
      }

      // Si todos los objetos de array2 están presentes en array1, entonces array1 tiene un objeto extra
      return Object.keys(objectCountMap).map((objString) =>
        JSON.parse(objString)
      )[0];
    },
    saveItemSchedule(item) {
      this.emptyValidationErrors();
      this.errors = [];
      item.days.forEach((i) => {
        if (i.day.id == 1) {
          if (this.addUnTruncateObject(this.dayOne, i)) {
            this.cancelSaveSubject();
            this.sendToSave(i);
          } else {
            this.errors.push(
              "No se pudo agregar el objeto debido a la superposición de intervalos en el dia 1."
            );
          }
        }
        if (i.day.id == 2) {
          if (this.addUnTruncateObject(this.dayTwo, i)) {
            this.cancelSaveSubject();
            this.sendToSave(i);
          } else {
            this.errors.push(
              "No se pudo agregar el objeto debido a la superposición de intervalos en el dia 2."
            );
          }
        }
        if (i.day.id == 3) {
          if (this.addUnTruncateObject(this.dayThree, i)) {
            this.cancelSaveSubject();
            this.sendToSave(i);
          } else {
            this.errors.push(
              "No se pudo agregar el objeto debido a la superposición de intervalos en el dia 3."
            );
          }
        }
        if (i.day.id == 4) {
          if (this.addUnTruncateObject(this.dayFour, i)) {
            this.cancelSaveSubject();
            this.sendToSave(i);
          } else {
            this.errors.push(
              "No se pudo agregar el objeto debido a la superposición de intervalos en el dia 4."
            );
          }
        }
        if (i.day.id == 5) {
          if (this.addUnTruncateObject(this.dayFive, i)) {
            this.cancelSaveSubject();
            this.sendToSave(i);
          } else {
            this.errors.push(
              "No se pudo agregar el objeto debido a la superposición de intervalos en el dia 5."
            );
          }
        }
      });
    },
    editItemSchedule(item) {
      this.sendToSave(item);
    },
    intervalsOverlap(intervalo1, intervalo2) {
      return (
        (intervalo1.start <= intervalo2.start &&
          intervalo2.start < intervalo1.end) ||
        (intervalo2.start <= intervalo1.start &&
          intervalo1.start < intervalo2.end)
      );
    },
    addUnTruncateObject(arrayObjetos, nuevoObjeto) {
      const seSuperpone = arrayObjetos.some((objeto) =>
        this.intervalsOverlap(objeto, nuevoObjeto)
      );

      console.log(seSuperpone);
      if (!seSuperpone) {
        console.log("se superponen jeje");
        arrayObjetos.push(nuevoObjeto);
        // aca se inserta un nuevo
        return true;
      }
      this.validationErrors(
        "Por favor, valide que ninguna hora en ningun día se este sobre escribiendo"
      );
      return false;
    },
    async sendToSave(item) {
      const dataSend = {
        teacher_id: item.teacher ? item.teacher.id : null,
        subject_id: item.subject.id,
        start: item.start,
        end: item.end,
        day: item.day.id,
        id: item.id,
        course_id: this.course.id,
      };

      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}schedule/${dataSend.id}`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        if (data.code == 200) {
          this.alertShow = true;
          this.textAlert = "La asignatura se edito correctamente.";
          this.colorAlert = "success";
          window.scrollTo(0, 0);
          this.$refs.modalComponentChild.resetForm();
          this.cancelEditSubject();
          if ((dataSend.day = 1)) {
            this.dayOne = this.dayOne.filter((i) => i.id != dataSend.id);
            this.dayOne.push(item);
          }
          if ((dataSend.day = 2)) {
            this.dayTwo = this.dayTwo.filter((i) => i.id != dataSend.id);
            this.dayTwo.push(item);
          }
          if ((dataSend.day = 3)) {
            this.dayThree = this.dayThree.filter((i) => i.id != dataSend.id);
            this.dayThree.push(item);
          }
          if ((dataSend.day = 4)) {
            this.dayFour = this.dayFour.filter((i) => i.id != dataSend.id);
            this.dayFour.push(item);
          }
          if ((dataSend.day = 5)) {
            this.dayFive = this.dayFive.filter((i) => i.id != dataSend.id);
            this.dayFive.push(item);
          }
        } else {
          this.alertShow = true;
          this.textAlert =
            "No se pudo editar la asignatura, hay truncamiento en las horas.";
          this.colorAlert = "danger";
        }
      } else {
        this.alertShow = true;
        this.textAlert =
          "Hubo un error al asignar la asignatura, intente nuevamente.";
        this.colorAlert = "danger";
        window.scrollTo(0, 0);
      }
      this.closeAlert();
    },
    closeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
    removeItem(item) {
      if (item.id) {
        this.removeItemArray(item);
      } else {
        this.removeItemArray(item, 2);
      }
      this.deleteItem(item);
    },
    editItem(item) {
      this.eventEdit = item;
      this.modalVisibleEdit = true;
    },
    removeItemArray(item, flag) {
      const value = flag != 2 ? item.day : item.day.id;
      switch (parseInt(value)) {
        case 1:
          this.dayOne = this.dayOne.filter((d) => {
            return d.end != item.end && d.start != item.start;
          });
          break;
        case 2:
          this.dayTwo = this.dayTwo.filter((d) => {
            return d.end != item.end && d.start != item.start;
          });
          break;
        case 3:
          this.dayThree = this.dayThree.filter((d) => {
            return d.end != item.end && d.start != item.start;
          });
          break;
        case 4:
          this.dayFour = this.dayFour.filter((d) => {
            return d.end != item.end && d.start != item.start;
          });
          break;
        case 5:
          this.dayFive = this.dayFive.filter((d) => {
            return d.end != item.end && d.start != item.start;
          });
          break;
      }
    },
    async deleteItem(item) {
      item.course_id = this.$route.params.id_curso;
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}schedule/remove`,
        item,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        console.log("se envio");
      } else {
        console.log("error", data);
      }
    },
    ...mapMutations(["validationErrors", "emptyValidationErrors"]),
  },
  mounted() {
    this.getSubjects(this.$route.params.id_curso);
    this.getCourse(this.$route.params.id_curso);
    this.getScheduleCourse(this.$route.params.id_curso);
    this.getTeachers();
    this.emptyValidationErrors();
  },
};
</script>

