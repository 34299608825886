<template>
  <div v-if="callAttention" class="card mt-4 mx-4" :class="darkMode ? 'bg-gradient-dark' : ''">
    <div class="p-3 card-body">
      <div class="position-sticky fixed-top z-index-sticky">
        <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
          {{ textAlert
          }}
        </vsud-alert>
      </div>
      <div class="mx-3 row">
        <div class="mb-3 timeline-block">
          <div class="row">
            <div class="col-6">
              <h6>Envia: {{ callAttention.sender.name +" "+ callAttention.sender.lastname}}</h6>
              <span :class="darkMode ? 'bg-dark' : ''">
                <vsud-avatar
                  :img="[callAttention.sender.photo ? callAttention.sender.photo : imgDefault]"
                  class="text-success text-gradient"
                  alt="avatar image"
                  border-radius="lg"
                />
              </span>
            </div>
            <div class="col-6">
              <h6>Para: {{ callAttention.student.name +" "+ callAttention.student.lastname}}</h6>
              <span :class="darkMode ? 'bg-dark' : ''">
                <vsud-avatar
                  :img="[callAttention.student.photo ? callAttention.student.photo : imgDefault]"
                  class="text-success text-gradient"
                  alt="avatar image"
                  border-radius="lg"
                />
              </span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <!-- <h6 class="mb-0 text-sm text-dark font-weight-bold">xdxd</h6> -->
              <b class="mb-0">
                Fecha:
                <br />
                <b>{{ callAttention.date }}</b>
              </b>
            </div>
            <div class="col-6">
              <multiselect
                track-by="name"
                v-model="estado"
                label="name"
                placeholder="Cambiar de estado"
                :multiple="false"
                :options="status"
                selectLabel="Seleccione una opción"
                deselectLabel="Presione para remover"
                selectedLabel="Seleccionado"
                :showNoOptions="false"
              ></multiselect>
            </div>
          </div>
          <div class="row mt-4">
            <p v-html="callAttention.message" class="mt-3 mb-2 text-sm"></p>
          </div>
        </div>
      </div>
    </div>
    <CommentStatusModal :visible="modalVisible" @cancelSave="closeModal" @saveComment="saveComment"></CommentStatusModal>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import CommentStatusModal from "./components/CommentStatusModal.vue";
import { mapMutations, mapState } from "vuex";
import router from "@/router/index.js";

export default {
  name: "CallAttentionDetail",
  components: {
    VsudAvatar,
    VsudButton,
    Multiselect,
    CommentStatusModal,
    VsudAlert,
  },
  props: {
    darkMode: Boolean,
    title: { type: String, default: "" },
    messages: { type: Object, require: true },
  },
  data() {
    return {
      modalVisible: false,
      imgDefault: require("@/assets/img/user-default.png"),
      modalVisible: false,
      status: [
        {
          name: "Publicar",
          value: "published",
        },
        {
          name: "Rechazado",
          value: "rejected",
        },
      ],
      estado: null,
      callAttention: null,
    };
  },
  watch: {
    estado(oldValue) {
      this.modalVisible = true;
    },
  },
  methods: {
    ...mapMutations(["displayAlertMessage", "closeAlertMessage"]),
    preguntarCambiarEstado(userId) {
      this.$swal({
        title: "¿Esta seguro de cambiar el estado de este mensaje?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cambiar estado!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cambiarEstado(userId);
        } else {
          this.$swal.dismiss;
        }
      });
    },
    async cambiarEstado(userId) {
      // const { status } = await axios.post(`${process.env.VUE_APP_API_BOOKAPP}users/change-status/${userId}`, {
      //   headers: {
      //     Authorization: localStorage.getItem("token_bookapp"),
      //     conexion: localStorage.getItem("conexion"),
      //   },
      // });
      // if (status == 200) {
      //   this.$swal({
      //     title: "Exito!",
      //     text: "El estado del mensaje se cambio.",
      //     icon: "success",
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //     },
      //     buttonsStyling: false,
      //   });
      //   vm.$forceUpdate();
      // } else {
      //   this.$swal({
      //     title: "Algo salió mal!",
      //     text: "Hubo un error al cambiar el estado del mensaje, por favor intente de nuevo.",
      //     icon: "danger",
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //     },
      //     buttonsStyling: false,
      //   });
      // }
    },
    closeModal() {
      // this.estado = null;
      this.modalVisible = false;
    },
    async saveComment(comment) {
      let dataSend = { comment, status: this.estado.value };
      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}calls-attentions/${this.$route.params.id}`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      this.modalVisible = false;
      if (status == 200) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El llamado de atenión fue editado correctamente.",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El llamado de atenión no pudo ser editado.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
      router.push({
        path: "/llamados-atencion/aprobar",
        replace: true,
      });
    },
    async getCallAttention(idCallAttention) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}calls-attentions/${idCallAttention}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.callAttention = data;
        console.log(data);
        // console.log('Se envio el servicio');
      } else {
        console.log("Hubo un error coonsultando los tipos de mensaje");
      }
    },
  },
  computed: {
    ...mapState(["alertShow", "textAlert", "colorAlert"]),
  },
  mounted() {
    this.getCallAttention(this.$route.params.id);
  },
};
</script>
