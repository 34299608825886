<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="position-sticky fixed-top z-index-sticky">
        <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
          {{ textAlert
          }}
        </vsud-alert>
      </div>
      <div class="col-12">        
        <SearchStudentForm origin="REFILLS" class="mb-4" @searchStudent="getStudent"></SearchStudentForm>        
      </div>     
    </div>    
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import SearchStudentForm from "./components/SearchStudentForm.vue";
import VsudAlert from "@/components/VsudAlert.vue";

export default {
  name: "Refills",
  components: {
    SearchStudentForm,
    VsudAlert,
  },
  data() {
    return {
     
    };
  },
  methods: {
    ...mapMutations([
      "setRateUser",            
      "displayAlertMessage",
      "closeAlertMessage"
    ]),
    async getStudent(searchParam) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/orders-refills?key=code&value=${searchParam}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      this.setRateUser(data);
      if (data.status == 404) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El estudiante no fue encontrado.",
          colorAlert: "danger",
        });
      } else if (data.status == 200) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El estudiante fue encontrado",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Hubo un error al buscar el usuario, intente nuevamente.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
    }, 
  },
  mounted() {
    this.setRateUser(null);
    // this.getUsersByRoleAttendant();
  },
  computed:{
    ...mapState(["alertShow", "textAlert", "colorAlert"]),
  }
};
</script>
