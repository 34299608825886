<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="
                text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
                align-middle
                text-center text-sm
              "
            >
              Permiso
            </th>
            <th
              class="
                align-middle
                text-center text-sm text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
                ps-2
              "
            >
              Descripción
            </th>
            <th
              class="
                align-middle
                text-center text-sm text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
                ps-2
              "
            >
              N° roles asignados
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="permissions">
            <tr
            @click="viewPermission(permission.id)"
              style="cursor: pointer"
              v-for="permission in permissions"
              :key="permission.id"
            >
              <td>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm align-middle text-center text-sm">
                    {{ permission.name }}
                  </h6>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-secondary mb-0 text-sm">
                  {{ permission.description }}
                </p>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-sm">{{
                  permission.roles.length
                }}</span>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">No hay registros</h6>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VsudAvatar from "@/components/VsudAvatar.vue";
import imgDefault from "../../../../assets/img/user-default.png";

import router from "@/router/index.js";

export default {
  name: "Table",
  components: {
    VsudAvatar,
  },
  props: {
    permissions: { type: Object, required: true },
  },
  methods: {
    viewPermission(id) {
      router.push({
        path: `/roles-permisos/permisos/detalle/${id}`,
        replace: true,
      });
    },
  },
  data() {
    return {
      imgDefault,
      
    };
  },
  mounted() {
    
  },
};
</script>
