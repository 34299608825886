<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <!-- <complex-statistics-card
              :class-icon="reports.UsersActive.classIcon"
              :percentage="reports.UsersActive.percentage"
              :caption="reports.UsersActive.caption"
              :active-users="'Espacio para filtros'"
            /> -->
            <!-- <h1>Espacio para filtros</h1> -->
          </div>
        </div>
      </div>
    </div>
    <div class="text-end">
                <div class="Nuevo usuario rol">
                  <router-link to="/roles-permisos/roles/crear"><button type="button" class="btn bg-gradient-success">Crear</button></router-link>
            </div>
            <router-view/>
          </div>
    <div class="row mt-4">
      <div class="col-12">
        <Table :roles="roles" />
      </div>
    </div>
  </div>
</template>

<script>
import ComplexStatisticsCard from "@/examples/Cards/ComplexStatisticsCard.vue";
import ReviewCard from "./components/ReviewCard.vue";
import Table from "./components/TableRoles.vue";

import axios from "axios";

export default {
  name: "AdministrativeList",
  components: {
    ComplexStatisticsCard,
    ReviewCard,
    Table,
  },
  data() {
    return {
      roles: [],
      
    };
  },
  methods: {
    async getRoles() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}roles`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.roles = data;
      }
    },
  },
  mounted() {
    this.getRoles();
  },
};
</script>
