<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">        
        <chat :messages="messagesList"></chat>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Chat from "./components/Chat.vue";

export default {
  name: "MessageDetail",
  components: {
    Chat,
  },
  data() {
    return {
      messagesList: [],
    };
  },
  methods: {
    async getMessages(idMessage) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}messages/chat/${idMessage}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.messagesList = data;
      
        this.messagesList.map((m) => {
          m = { ...m.message };
        });        
      } else {
        console.log("Hubo un error coonsultando los tipos de mensaje");
      }
    },
  },
  mounted() {
    this.getMessages(this.$route.params.ideMensaje);
  },
};
</script>
