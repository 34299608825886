<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="position-sticky fixed-top z-index-sticky">
        <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
          {{ textAlert
          }}
        </vsud-alert>
      </div>
      <div class="col-12">
        <!-- <h3>Buscar estudiante por codigo</h3> -->
        <SearchStudentForm origin="SPECIALS_RATES" class="mb-4" @searchStudent="getStudent"></SearchStudentForm>
        <Table @getSpecialsRates="getSpecialsRates"></Table>
      </div>     
    </div>    
  </div>
</template>

<script>
import Table from "./components/Table.vue";
import SearchStudentForm from "./components/SearchStudentForm.vue";
import axios from "axios";
import VsudAlert from "@/components/VsudAlert.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "SpecialsRates",
  components: {
    SearchStudentForm,
    Table,
    VsudAlert,    
  },
  data() {
    return {
      modalVisible:false,
    };
  },
  methods: {
    ...mapMutations([
      "setRateUser",
      "setSpecialsRates",
      "emptySpecialsRates",
      "displayAlertMessage",
    ]),
    async getStudent(searchParam) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}users/orders-refills?key=code&value=${searchParam}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      this.setRateUser(data);
      if (data.status == 404) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El estudiante no fue encontrado.",
          colorAlert: "danger",
        });
      } else if (data.status == 200) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El estudiante no fue encontrado",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Hubo un error al buscar el usuario, intente nuevamente.",
          colorAlert: "danger",
        });
      }
      this.closeAlert();
    },
    closeAlert() {
      setTimeout(() => {
        this.displayAlertMessage({
          alertShow: false,
          textAlert: null,
          colorAlert: null,
        });
      }, 3000);
    },
    async getSpecialsRates() {
      this.emptySpecialsRates();
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}special-rates`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        data.forEach((sr) => {
          this.setSpecialsRates({
            id: sr.id,
            user: sr.user,
            rate: sr.rate,
            course: sr.user.inscription.course,
            status: sr.status,
          });
        });
      } else {
        console.log("Error");
      }
      this.closeAlert();
    },
  },
  mounted() {
    this.displayAlertMessage({
      alertShow: false,
      textAlert: null,
      colorAlert: null,
    });
    this.emptySpecialsRates();
    this.getSpecialsRates();
    this.setRateUser(null);
  },
  computed: {
    ...mapState(["alertShow", "textAlert", "colorAlert"]),
  },
};
</script>
