<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="position-sticky fixed-top z-index-sticky">
        <vsud-alert
          v-show="alertShow"
          class="mt-1 mx-6 text-center"
          :color="colorAlert"
        >{{ textAlert }}</vsud-alert>
      </div>
      <div class="col-12">
        <chat :messages="messagesList" @saveComment="saveCommentMessage"></chat>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VsudAlert from "@/components/VsudAlert.vue";
import Chat from "./components/ChatMessageGeneral.vue";
import { mapState } from "vuex";
import router from "@/router/index.js";

export default {
  name: "MessageDetail",
  components: {
    VsudAlert,
    Chat,
  },
  data() {
    return {
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      messagesList: [],
    };
  },
  methods: {
    async getMessages(idMessage) {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}messages/chat/approve/${idMessage}`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.messagesList = data;
      } else {
        console.log("Hubo un error coonsultando los tipos de mensaje");
      }
    },
    async saveCommentMessage() {
      const dataSend = {
        comment: this.dataCommentMessage,
        status: this.statusCommentMessage,
      };
      const { status, data } = await axios.put(
        `${process.env.VUE_APP_API_BOOKAPP}messages/${this.$route.params.ideMensaje}`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );

      if (status == 200) {
        this.alertShow = true;
        this.textAlert = "El estado del mensaje fue cambiado con exito.";
        this.colorAlert = "success";
        window.scrollTo(0, 0);
        this.removeAlerts();
      } else {
        this.alertShow = true;
        this.textAlert = "Hubo un error al cambiar el estado del mensaje.";
        this.colorAlert = "danger";
        window.scrollTo(0, 0);
        this.removeAlerts();
      }
    },
    removeAlerts() {
      setTimeout(() => {
        router.push({
          path: "/mensajes/listar/enviados",
          replace: true,
        });
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
  },
  computed: {
    ...mapState(["dataCommentMessage", "statusCommentMessage"]),
  },
  mounted() {
    // console.log('esta en el chat');
    this.getMessages(this.$route.params.ideMensaje);
  },
};
</script>
