<template>
  <div>
    <div
      v-if="OpenClose"
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display:block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5>{{eventDetail.title }}</h5>
            <div style="cursor: pointer" @click="closeModal()">
              <h5>
                <span aria-hidden="true">&times;</span>
              </h5>
            </div>
          </div>
          <div class="modal-body">
            <div class="row mt-3">
              <!-- <div class="col-6 d-flex"> -->
              <div class="col-12 text-center">
                <img
                  :src="eventDetail.extendedProps.image"
                  class="w-25 d-block mx-auto"
                  :alt="eventDetail.extendedProps.title"
                />
              </div>
              <!-- </div> -->
            </div>
            <div v-if="eventDetail.extendedProps.link" class="row mt-3">
              <!-- <div class="col-6 d-flex"> -->
              <div class="col-12 text-center">
                <label class="my-auto" for>Link:</label>
                <label class="my-auto" for>{{ eventDetail.extendedProps.link }} </label>
              </div>
              <!-- </div> -->
            </div>
            <div class="row mt-3">
              <div class="col-6 d-flex">
                <label class="my-auto" for>Inicia:</label>
                <label class="my-auto" for>{{ formatStart }}</label>
              </div>
              <div class="col-6 d-flex">
                <label class="my-auto" for>Termina:</label>
                <label class="my-auto" for>{{ eventDetail.end? formatEnd : formatStart }}</label>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 d-flex">
                <label class="my-auto" for>Tipo de evento:</label>
                <label class="my-auto" for>{{ eventDetail.extendedProps.type_activity.name }}</label>
              </div>
              <div class="col-6 d-flex">
                <label class="my-auto" for>Creador del evento:</label>
                <label class="my-auto" for>{{ formatCreater }}</label>
              </div>
            </div>
          </div>
          <!-- <div v-if="validationsErrors.length > 0" class="row justify-content-center text-center">
            <div class="alert alert-danger col-md-8">
              <div v-for="error in validationsErrors" :key="error">
                <b>{{ error }}</b>
              </div>
            </div>
          </div>-->
          <div v-show="hasPermission('users.event.delete')" class="modal-footer">
            <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="ml-auto"
              @click="deleteEvent()"
            >Borrar evento</vsud-button>
            <!-- <vsud-button
              type="button"
              color="danger"
              variant="contained"
              class="mr-auto"
              @click="closeModal()"
            >Cancelar</vsud-button>-->
            <!-- <vsud-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto"
              @click="saveItemCalendar()"
            >Guardar</vsud-button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  components: {
    VsudButton,
  },
  mixins: [permissionsMixin],
  name: "EventModal",
  props: {
    visible: Boolean,
    event: Object,
  },
  data() {
    return {
      OpenClose: this.visible,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    deleteEvent() {
      this.$emit("deleteEvent", this.eventDetail.id);
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.OpenClose = newVal;
    },
  },
  computed: {
    ...mapState(["eventDetail"]),
    formatStart() {
      const año = this.eventDetail.start.getFullYear().toString();
      const mes = (this.eventDetail.start.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const día = this.eventDetail.start.getDate().toString().padStart(2, "0");
      const horas = this.eventDetail.start
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutos = this.eventDetail.start
        .getMinutes()
        .toString()
        .padStart(2, "0");

      const fechaFormateada = `${año}-${mes}-${día} ${horas}:${minutos}`;
      return fechaFormateada;
    },
    formatEnd() {
      const año = this.eventDetail.end.getFullYear().toString();
      const mes = (this.eventDetail.end.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const día = this.eventDetail.end.getDate().toString().padStart(2, "0");

      const horas = this.eventDetail.end.getHours().toString().padStart(2, "0");
      const minutos = this.eventDetail.end
        .getMinutes()
        .toString()
        .padStart(2, "0");

      const fechaFormateada = `${año}-${mes}-${día} ${horas}:${minutos}`;
      return fechaFormateada;
    },
    formatCreater() {
      return (
        this.eventDetail.extendedProps.created_by.name +
        " " +
        this.eventDetail.extendedProps.created_by.lastname
      );
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    // this.event.created_by = this.user.id;
    // this.event.course_id = this.$route.params.id_curso;
  },
};
</script>
<style scoped>
.custom-flex {
  display: flex;
  justify-content: space-between;
}
</style>