<template>
  <div v-if="rateUser" class="card" style="width: 18rem;">
    <div class="d-flex justify-content-center mt-4">
      <vsud-avatar
        :img="[rateUser.user.photo?rateUser.user.photo:imgDefault]"
        alt="avatar image"
        class="text-center borde image-container avatar-xl position-relative"
      />
    </div>
    <div class="card-body">
      <h5 class="card-title text-center">{{ rateUser.user.name}} {{ rateUser.user.lastname}}</h5>
      <p class="card-text">
        <b>Identificación:</b>
        {{ rateUser.user.document }}
        <br />
        <b>Curso:</b>
        {{ rateUser.course }}
        <br />
        <b>Saldo:</b>
        $ {{ totalBalance }}
      </p>
      <div class="text-center">
        <input
          v-model="refill"
          placeholder="Recargas"
          type="number"
          max="50000"
          min="0"
          class="form-control"
        />
        <a @click="confirmRefill" class="btn btn-primary mt-2">Recargar</a>
      </div>
    </div>
  </div>
</template>
  
<script>
import imgDefault from "../../../../assets/img/user-default.png";
import VsudAvatar from "@/components/VsudAvatar.vue";
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "StudentCardRefill",
  components: { VsudAvatar },
  data() {
    return {
      searchParam: null,
      imgDefault,
      refill: null,
    };
  },
  methods: {
    ...mapMutations(["setSpecialsRates", "displayAlertMessage", "setRateUser"]),
    async saveRefill() {
      const user = JSON.parse(localStorage.getItem("user"));
      const dataSend = {
        price: this.refill,
        type: "Manual",
        description: `Recarga manual a cargo de ${user.name} ${user.lastname} para ${this.rateUser.user.name} ${this.rateUser.user.lastname}`,
        seller_id: user.id,
        user_id: this.rateUser.user.id,
      };
      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}refills`, dataSend, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.displayAlertMessage({
              alertShow: true,
              textAlert: "La recarga fue agregada con exito.",
              colorAlert: "success",
            });
            this.setRateUser(null);
          }
        })
        .catch((response) => {
          this.displayAlertMessage({
            alertShow: true,
            textAlert: "La recarga no pudo ser agregada.",
            colorAlert: "danger",
          });        
        });

      this.closeAlert();
    },
    closeAlert() {
      setTimeout(() => {
        this.displayAlertMessage({
          alertShow: false,
          textAlert: null,
          colorAlert: null,
        });
      }, 3000);
    },
    confirmRefill(){
      if (this.refill < 0 || !this.refill) {
        alert("La recarga debe ser mayor a 0");
        return 0;
      }
      this.$swal({
        title: "¿Esta seguro de enviar esta recarga?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, enviar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveRefill();
          return true;
        } else {
          this.$swal.dismiss;
          return false;
        }
      });
    }
  },
  computed: {
    ...mapState(["rateUser"]),
    totalBalance() {
      return this.rateUser.refillsTotal - this.rateUser.ordersTotal;
    },
  },
};
</script>
  <style scoped>
.option-search {
  border-style: solid;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.option-search:hover {
  background-color: #f36c17;
  cursor: pointer;
}
</style>
  