<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="position-sticky fixed-top z-index-sticky">
              <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
                {{ textAlert
                }}
              </vsud-alert>
            </div>
            <div class="col-12 col-lg-8 mx-auto my-5">
              <!-- <h3>Formulario de registro para un curso.</h3> -->
            </div>
          </div>
          <!--form panels-->
          <div class="text-end">
                <div class="Listar Cursos">
                  <router-link to="/cursos/listar"><button type="button" class="btn bg-gradient-dark">Listado</button></router-link>
            </div>
            <router-view/>
          </div>
          <div class="row">
            <div class="col-12 m-auto">
              <form class="multisteps-form__form mb-5">
                <form-course
                  @sendForm="saveCourse"
                  :errors="errors"
                  :subjects="subjects"
                  class="js-active position-relative"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormCourse from "./components/FormCourse.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import axios from "axios";

export default {
  name: "NewSubject",
  components: {
    FormCourse,
    VsudAlert,
  },
  data() {
    return {
      showMenu: false,
      alertShow: false,
      textAlert: null,
      colorAlert: null,
      permissions: null,
      errors: [],
      empty: false,
      subjects: [],
    };
  },
  methods: {
    async saveCourse(course) {
      this.errors = [];
      axios
        .post(`${process.env.VUE_APP_API_BOOKAPP}courses`, course, {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        })
        .then((response) => {
          this.alertShow = true;
          this.textAlert = "El curso se creo correctamente.";
          this.colorAlert = "success";
          this.empty = true;
          window.scrollTo(0, 0);
          this.removeAlert();
        })
        .catch((e) => {
          if (e.response.status == 422) {
            let errors = e.response.data.errors;
            errors = Object.values(errors);
            errors.forEach((error) => {
              error.forEach((e) => {
                this.errors.push(e);
              });
            });
          } else {
            this.alertShow = true;
            this.textAlert =
              "Hubo un error al crear la materia, intente nuevamente.";
            this.colorAlert = "danger";
            this.removeAlert();
          }
        });
    },
    removeAlert() {
      setTimeout(() => {
        this.alertShow = false;
        this.textAlert = null;
        this.colorAlert = null;
      }, 3000);
    },
    async getSubjects() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}subjects`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.subjects = data;
      } else {
        console.log("error", data);
      }
    },
  },
  mounted() {
    this.getSubjects();
  },
};
</script>
