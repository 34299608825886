<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="row justify-content-center align-items-center">
        <div class="col-6 text-center">
          <multiselect
            v-model="searchParam"
            :internal-search="true"
            track-by="name"
            label="name"
            placeholder="Escriba el nombre del curso a buscar"
            selectLabel="Seleccione una opción"
            deselectLabel="Presione para remover"
            selectedLabel="Seleccionado"
            :multiple="false"
            :options="courseList"
          ></multiselect>
        </div>
        <div class="col-3 text-center">
          <input type="date" v-model="date" class="form-control" />
        </div>
        <div class="col-3 text-center">
          <vsud-button
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto mb-0 js-btn-next mt-2"
            @click="getCourseStudents"
          >Buscar</vsud-button>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-10 d-flex justify-content-center">
          <StudentTable :listItems="items" :date="date"></StudentTable>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import VsudButton from "@/components/VsudButton.vue";
import StudentTable from "@/views/pages/lunch/components/StudentTable.vue";
import Multiselect from "vue-multiselect";
import { mapMutations } from "vuex";

export default {
  name: "StudentListCourse",
  components: { VsudButton, StudentTable, Multiselect },
  props: {
    typeSearch: String,
  },
  data() {
    return {
      valueSearch: null,
      arrayResult: [],
      courseList: [],
      searchParam: null,
      date: null,
      items: [],
    };
  },
  methods: {
    ...mapMutations([
      "displayAlertMessage",
      "closeAlertMessage",
      "setRateUser",
    ]),
    async getCoursesList() {
      const { status, data } = await axios.get(
        `${process.env.VUE_APP_API_BOOKAPP}courses-all/null`,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.courseList = data;
      }
    },
    async saveOrder(order) {
      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}orders`,
        order,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El almuerzo fue descontado con exito.",
          colorAlert: "success",
        });
        this.setRateUser(null);
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "El almuerzo no pudo ser descontado.",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
    },
    confirmOrder(order) {
      this.$swal({
        title: "¿Esta seguro de descontar este almuerzo?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, descontar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveOrder(order);
          return true;
        } else {
          this.$swal.dismiss;
          return false;
        }
      });
    },
    dateNow() {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      return `${dd}-${mm}-${yyyy}`;

      // return new Date(`${dd}-${mm}-${yyyy}`);
    },
    async getCourseStudents() {
      let dataSend = {
        date: this.date,
        course: this.searchParam.id,
      };

      const { status, data } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}courses-student`,
        dataSend,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        this.items = data;
        console.log(data);
        // this.displayAlertMessage({
        //   alertShow: true,
        //   textAlert: "Estudiantes encontrados con exito.",
        //   colorAlert: "success",
        // });
        // this.setRateUser(null);
      } else {
        // this.displayAlertMessage({
        //   alertShow: true,
        //   textAlert: "No se encontraron estudiantes para el curso.",
        //   colorAlert: "danger",
        // });
      }
      this.closeAlertMessage();
    },
  },
  watch: {
    searchParam(newVal, oldValue) {
      if (this.typeSearch == "COURSE") {
        console.log(newVal);
        return 0;
        if (newVal) {
          this.$emit("searchCourseStundents", newVal);
        }
      }
    },
  },
  mounted() {
    this.getCoursesList();
    console.log(new Date().toISOString('es-CO'));
    console.log(new Date().toLocaleDateString('es-CO', { timeZone: 'America/Bogota' }));
    let dateTemp =  new Date().toLocaleDateString('es-CO', { timeZone: 'America/Bogota' });
    // this.date = new Date().toISOString('es-CO').split("T")[0];
    this.date = dateTemp.split("/")[2] +'-'+dateTemp.split("/")[1]+'-'+dateTemp.split("/")[0];
  },
  computed: {},
};
</script>
  <style scoped>
.option-search {
  border-style: solid;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.option-search:hover {
  background-color: #f36c17;
  cursor: pointer;
}
</style>
  