<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="$store.state.isRTL ? '' : ' me-sm-6'"
    >
      <li class="text-sm breadcrumb-item" :class="textWhite">
        <a
          v-if="$store.state.isRTL"
          :class="textWhite"
          class="opacity-5 ps-2"
          href="#"
        >لوحات القيادة</a>
        <a v-else :class="textWhite" class="opacity-8"  href="#">Inicio</a>
      </li>
      <li
        class="text-sm breadcrumb-item active"
        :class="textWhite ? 'text-white' : 'text-dark'"
        aria-current="page"
      >{{ currentPage }}</li>
    </ol>
    <h6 v-if="currentPage" class="mb-0 font-weight-bolder row">
      <div class="col-12 d-flex" :class="textWhite ? 'text-white' : '' ">
        <div class="text-capitalize">{{firstPartRoute}}</div>
        <div style="color: #f06C30;" class="text-uppercase" >&nbsp;{{secondPartRoute}}</div>
      </div>
    </h6>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    currentPage: {
      type: String,
      default: undefined,
    },
    textWhite: {
      type: String,
      default: "",
    },
  },
  computed: {
    firstPartRoute() {
      return this.currentPage.split(" ")[0];
    },
    secondPartRoute() {
      return this.currentPage.split(" ")[1];
    },
  },
};
</script>
