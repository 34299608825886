<template>
  <div class="container-fluid py-4">
    <div class="position-sticky fixed-top z-index-sticky">
      <vsud-alert v-show="alertShow" class="mt-1 mx-6 text-center" :color="colorAlert">
        {{ textAlert
        }}
      </vsud-alert>
    </div>
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="col-sm-4">
            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Buscar por nombre, correo o documento" v-model="valueSearch"
              @keyup.enter="getUsersByRoleAttendant" />
          </div>
          <div class="col-sm-3">
            <button type="button" class="btn bg-gradient-dark btn-md null null ms-auto"
              @click="getUsersByRoleAttendant()">Buscar</button>
          </div>
          <div class="col-sm-5 ms-auto">
            <div class="row">
              <div class="col-sm-4">
                <div class="row">
                  <button @click="active = !active" class="btn btn-primary">
                    {{ active ? 'Ver Inactivos' : 'Ver Activos' }}
                  </button>
                </div>
              </div>
              <div class="col-sm-4">
                <button v-show="hasPermission('users.attendant.store')" type="button" class="btn bg-gradient-dark"
                  @click="modalVisible = !modalVisible" style="margin-right: -90px;">Cargue masivo</button>
              </div>
              <div class="col-sm-2">
                <div class="row">
                  <router-link to="/usuarios/acudientes/nuevo">
                    <button type="button" v-show="hasPermission('users.attendant.store')"
                      class="btn bg-gradient-success">Crear</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <Table @getData="getUsersByRoleAttendant" :userList="attendantList" :pagination="pagination" />
      </div>
      <UploadAttendants @saveAttendantsMasive="saveAttendantsMasive" @closeModal="cancelModal" :visible="modalVisible">
      </UploadAttendants>
    </div>
  </div>
</template>

<script>
import ComplexStatisticsCard from "@/examples/Cards/ComplexStatisticsCard.vue";
import Table from "./components/Table.vue";
import UploadAttendants from "./components/UploadAttendants.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import permissionsMixin from "@/mixins/permissionsMixin";
import axios from "axios";
import { mapState, mapMutations } from "vuex";

export default {
  name: "AttendantsList",
  components: {
    ComplexStatisticsCard,
    Table,
    UploadAttendants,
    VsudAlert,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      attendantList: [],
      pagination: null,
      valueSearch: null,
      modalVisible: false,
    };
  },
  computed: {
    ...mapState(["alertShow", "textAlert", "colorAlert"]),
  },
  watch: {
    valueSearch(newVal) {
      if (newVal == "") {
        this.valueSearch = null;
        this.getUsersByRoleAttendant();
      }
    },
  },
  methods: {
    ...mapMutations(["displayAlertMessage", "closeAlertMessage"]),
    cancelModal() {
      this.modalVisible = false;
    },
    async getUsersByRoleAttendant(page = 1) {
      const request = {
        rolesIn: [4],
        rolesNotIn: [1],
        active: this.active,

      };
      const { status, data: dataA } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/user-filtered-paginate/${this.valueSearch}?page=${page}`,
        request,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        const { data, ...resto } = dataA;
        this.attendantList = dataA.data;
        this.pagination = resto;
      }
    },
    async changePage(page) {
      this.getUsersByRoleAttendant(page);
    },
    async saveAttendantsMasive(data) {
      const { status, data: dataA } = await axios.post(
        `${process.env.VUE_APP_API_BOOKAPP}users/massive/attendants`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token_bookapp"),
            conexion: localStorage.getItem("conexion"),
          },
        }
      );
      if (status == 200) {
        console.log("todo ok");
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Se cargaron los acudientes correctamente.",
          colorAlert: "success",
        });
      } else {
        this.displayAlertMessage({
          alertShow: true,
          textAlert: "Hubo un error al cargar los acudientes",
          colorAlert: "danger",
        });
      }
      this.closeAlertMessage();
      this.getUsersByRoleAttendant();
      this.cancelModal();
    },
  },
  mounted() {
    this.getUsersByRoleAttendant();
  },
};
</script>
